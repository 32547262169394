import FlowireAPI from "../../../flowireapi/FlowireAPI";

export class SimulatorService {
    constructor(){
        this.solved_Dictionary = {};
        this.mbOn = true;
        this.globalObjectsList = {};
    }

    updateFeedstockData(graph,stream, val, isAuthenticated, getIdTokenClaims) {
        this.isAuthenticated = isAuthenticated
        this.getIdTokenClaims = getIdTokenClaims
        console.log(stream == 1)
    
        let all_Links = graph.getLinks()
        for (let i = 0; i < all_Links.length; i++) {
            console.log(all_Links[i].prop('CustomName'))
            if ((all_Links[i].prop('CustomName') == 1) && ((stream == 1))) {
                let celluloseDict = {
                    "Jute": 0.735,
                    "Cow Manure": 0.255,
                    "Chicken Manure": 0.1425,
                    "Pig Manure": 0.225,
                    "Brewery": 0.22,
                    "Horse Manure": 0.325,
                    "Cotton": 0.95,
                    "Grocery Waste": 0.225,
                    "Paunch": 0.75,
                    "Food Waste": 0.23
                }
                all_Links[i].prop({ 'specified_Stream_Properties': { ['massFrac']: { ["H2O"]: null } } })
                all_Links[i].prop({ 'specified_Stream_Properties': { ['massFrac']: { ["TKN"]: 0.02 } } })
                all_Links[i].prop({ 'specified_Stream_Properties': { ['massFrac']: { ["OrthoP"]: 0.0006 } } })
                all_Links[i].prop({ 'specified_Stream_Properties': { ['massFrac']: { ["Cellulose"]: celluloseDict[document.getElementById("492fc0f6-81fd-4280-9363-9e717ec1050f_FeedSelect").value] } } })
            }
            else if ((all_Links[i].prop('CustomName') == 15) && (stream == 15)) {
                let celluloseDict = {
                    "Coffee Grounds": 0.135,
                    "Sorghum": 0.60,
                    "Tomato": 0.465
                }
                all_Links[i].prop({ 'specified_Stream_Properties': { ['massFrac']: { ["H2O"]: null } } })
                all_Links[i].prop({ 'specified_Stream_Properties': { ['massFrac']: { ["TKN"]: 0.02 } } })
                all_Links[i].prop({ 'specified_Stream_Properties': { ['massFrac']: { ["OrthoP"]: 0.0006 } } })
                all_Links[i].prop({ 'specified_Stream_Properties': { ['massFrac']: { ["Cellulose"]: celluloseDict[document.getElementById("c1efd72f-d13d-4c05-ab25-020406657feb_FeedSelect").value] } } })
            }
    
        }
        if (this.mbOn != false) {
            this.simulate(graph,graph.getLinks(), graph.getElements(), isAuthenticated, getIdTokenClaims)
        }
    }

    simulate(graph,joint_Streams, joint_Units, isAuthenticated, getIdTokenClaims) {
        this.isAuthenticated = isAuthenticated
        this.getIdTokenClaims = getIdTokenClaims
        return new Promise((resolve, reject) => {
        // First create stream obj
        let input_Dictionary = { 'streams': {}, 'units': {} }
        let complete_Dictionary = { 'streams': {}, 'units': {} }
        for (let i = 0; i < joint_Streams.length; i++) {
            if (joint_Streams[i].prop('stream_Number')) {
                let num = joint_Streams[i].prop('stream_Number')
                input_Dictionary['streams'][num] = { 'massFlow': {}, 'massFrac': {} }
                complete_Dictionary['streams'][num] = { 'massFlow': {}, 'massFrac': {} }
                // first do component properties flows/fractions
                // first get components
                let specified_Stream_Massflows = joint_Streams[i].attributes['specified_Stream_Properties']['massFlow']
                let specified_Stream_Massfracs = joint_Streams[i].attributes['specified_Stream_Properties']['massFrac']
                let complete_Stream_Massflows = joint_Streams[i].attributes['complete_Stream_Properties']['massFlow']
                let complete_Stream_Massfracs = joint_Streams[i].attributes['complete_Stream_Properties']['massFrac']
                let stream_Components = Object.keys(specified_Stream_Massflows)
                for (let x = 0; x < stream_Components.length; x++) {
                    input_Dictionary['streams'][num]['massFlow'][stream_Components[x]] = specified_Stream_Massflows[stream_Components[x]]
                    input_Dictionary['streams'][num]['massFrac'][stream_Components[x]] = specified_Stream_Massfracs[stream_Components[x]]
                    complete_Dictionary['streams'][num]['massFlow'][stream_Components[x]] = complete_Stream_Massflows[stream_Components[x]]
                    complete_Dictionary['streams'][num]['massFrac'][stream_Components[x]] = complete_Stream_Massfracs[stream_Components[x]]
                }
                input_Dictionary['streams'][num]['totalFlow'] = joint_Streams[i].attributes['specified_Stream_Properties']['totalFlow']
                complete_Dictionary['streams'][num]['totalFlow'] = joint_Streams[i].attributes['complete_Stream_Properties']['totalFlow']
            }
        }
    
        for (let i = 0; i < joint_Units.length; i++) {
            if (joint_Units[i].prop('unit_Label')) {
                let label = joint_Units[i].prop('unit_Label')
                //input_Dictionary['units'][label] = {}
                input_Dictionary['units'][label] = joint_Units[i].attributes['equipment_Properties']
                complete_Dictionary['units'][label] = joint_Units[i].attributes['equipment_Properties']
                if(joint_Units[i].attributes['components']){
                    const top_Level_Components = joint_Units[i].attributes['components']
                    const top_Level_Components_Object = Object.keys(top_Level_Components)
                    input_Dictionary['units'][label]['components'] = top_Level_Components_Object
                    complete_Dictionary['units'][label]['components'] = top_Level_Components_Object
                }
                //const top_Level_Components = joint_Units[i].attributes['components']
                //console.log(Object.keys(top_Level_Components))
            }
        }
        document.getElementById("loading-page").style.display = "flex"
        this.simul9(graph,input_Dictionary, input_Dictionary).then(result => {
            resolve(result);
            document.getElementById("loading-page").style.display = "none"
        })
        .catch(error => {
            document.getElementById("loading-page").style.display = "none"
            reject(error);
        });
        })
    }

    async simul9(graph,inputDict, completeDict) {
        // Hide all open divs (these will be destroyed and recreated maintaining current locations/tabs open)
        // Inside a function marked 'async' we can use the 'await' keyword.

        //let ans = await eel.simul9(inputDict, completeDict)(); // Prefix with 'await' as Js doesn't allow us to block while we wait for a callback,
        let data = {
            univerDict: inputDict,
            flowSheetDict: inputDict
        };

        let ans; // declare ans here

        console.log(JSON.stringify(data))
        
        await FlowireAPI.authPost('/api/simul9', data, this.getIdTokenClaims, this.isAuthenticated, (error) => {
            throw new Error(`HTTP error! status: ${error}`);
        }).then(data => {
            ans = data
        })
        // console.log(ans)

        if (ans.result[0] == false) {
            alert("No Solutions: Please revise input data.");
            throw new Error("No Solutions: Please revise input data.")
        }
        else {
            //sets the global variable
            this.solved_Dictionary = ans.result[1]
            //console.log(this.solved_Dictionary)
            //global_Dictionary = ans.result[1]
            // First update complete properties dictionary
            let joint_Streams = graph.getLinks()
            for (let i = 0; i < joint_Streams.length; i++) {
                if (joint_Streams[i].prop('stream_Number')) {
                    let num = joint_Streams[i].prop('stream_Number')
                    let specified_Stream_Massflows = joint_Streams[i].attributes['specified_Stream_Properties']['massFlow']
                    let stream_Components = Object.keys(specified_Stream_Massflows)
                    for (let x = 0; x < stream_Components.length; x++) {
                        joint_Streams[i].prop({ 'complete_Stream_Properties': { 'massFlow': { [stream_Components[x]]: this.solved_Dictionary['streams'][num]['massFlow'][stream_Components[x]] } } })
                        joint_Streams[i].prop({ 'complete_Stream_Properties': { 'massFrac': { [stream_Components[x]]: this.solved_Dictionary['streams'][num]['massFrac'][stream_Components[x]] } } })
                        joint_Streams[i].prop({ 'complete_Stream_Properties': { 'pH': this.solved_Dictionary['streams'][num]['pH']  } })
                        joint_Streams[i].prop({ 'complete_Stream_Properties': { 'LSI': this.solved_Dictionary['streams'][num]['LSI']  } })
                    }
                    joint_Streams[i].prop({ 'complete_Stream_Properties': { 'totalFlow': this.solved_Dictionary['streams'][num]['totalFlow'] } })
                }
            }
        }
    }
}