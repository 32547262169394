import {
    Box,
    Button,
    Input,
    Select,
    Flex,
    useForceUpdate, InputGroup, InputRightElement, Heading,
} from '@chakra-ui/react';
import './model.css'
import FlowireAPI from '../../../../../flowireapi/FlowireAPI';
import React, {useEffect, useRef, useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {PopupButton, PopupLayout} from "../../../../PopupLayout/PopupLayout";
import AsyncSelect from 'react-select/async';
import totalOutputMB from "../../../services/unitBalance";

let extraDivsDictionary = {
    'Filters - Plate and frame': ['Mass Flow'],
    'Tanks - cone roof': ['Mass Flow', 'Residence Time', 'Operating Level', 'Height'],
    'Tanks + Agitator': ['Mass Flow', 'Residence Time', 'Operating Level', 'Height', 'Agitation Application'],
    'Reactors - Jacketed, agitated': ['Mass Flow', 'Residence Time', 'Operating Level', 'Height', 'Agitation Application', 'Reaction Temperature'],
    'Extruder - Screw': ['Mass Flow'],
    'Agitators & mixers - Static mixer': ['Mass Flow'],
    'Custom': ['Mass Flow'],
    'Crushers - Jaw crusher': ['Mass Flow'],
    'Crushers - Gyratory crusher': ['Mass Flow'],
    'Crushers - Reversible hammer mill': ['Mass Flow'],
    'Crushers - Ball mill': ['Mass Flow'],
    'Haulage - Electric Fleet': ['Mass Flow'],
    'Haulage - Diesel Fleet': ['Mass Flow'],
    'Filters - Ultrafiltration (>0.8 L/s)':['Flow','Number'],
    'Filters - Ultrafiltration (<0.8 L/s)':['Flow','Number'],
    'Electrodialysis':['Flow','Number'],
    'Aeration Basin (incl. aeration)':['Flow','Residence Time', 'Number'],
    'Membrane Spiral':['Flow', 'Number'],
    'Thickener':['Area', 'Number'],
    'RO (<1600 m2)':['Flow', 'Number'],
    'RO (>1600 m2)':['Flow', 'Number']
    // 'Pressure vessels - Vertical': ['S (shell mass)'],
};

let extraDivsDictionarySectors = {
    'Filters - Plate and frame': ['Default'],
    'Tanks - cone roof': ['Default'],
    'Tanks + Agitator': ['Default'],
    'Reactors - Jacketed, agitated': ['Default'],
    'Extruder - Screw': ['Manufacturing'],
    'Agitators & mixers - Static mixer': ['Default'],
    'Custom': ['Default'],
    'Crushers - Jaw crusher': ['Mining'],
    'Crushers - Gyratory crusher': ['Mining'],
    'Crushers - Reversible hammer mill': ['Mining'],
    'Crushers - Ball mill': ['Mining'],
    'Haulage - Electric Fleet': ['Mining', "Transport"],
    'Haulage - Diesel Fleet': ['Mining', "Transport"],
    'Filters - Ultrafiltration (>0.8 L/s)':['Default'],
    'Filters - Ultrafiltration (<0.8 L/s)':['Default'],
    'Electrodialysis':['Default'],
    'Aeration Basin (incl. aeration)':['Default'],
    'Membrane Spiral':['Default'],
    'Thickener': ['Default'],
    'RO (<1600 m2)': ['Default'],
    'RO (>1600 m2)': ['Default']

    // 'Pressure vessels - Vertical': ['S (shell mass)'],
};

// TODO: give these field names in the back end so we don't have to use the labels to match values when posting data
let extraDivsDictionaryPost = {
    'Mass Flow': 'Mass Flow', //removed the units because some mass flow is in tonnes/hr instead of m3/day
    'Flow':'Flow',
    'Density': 'Density (kg/m3)',
    'S (volume)': 'S (volume - m3)',
    'Residence Time': 'Residence Time (hrs)',
    'Operating Level': 'Operating Level (%)',
    'Height': 'Height (m)',
    'Agitation Application': 'Agitation Application',
    'Reaction Temperature': 'Reaction Temperature (°C)',
    'S (shell mass)': 'S (shell mass- m3)',
    'S': 'S (tonnes/hr)',
    'Number':'Number',
    'Area': 'Area (m2)'
};

let extraDivsDictionaryUnits = {
    'Custom': ['m3/day'],
    'Filters - Plate and frame': ['m3/day'],
    'Tanks - cone roof': ['m3/day', 'hrs', '%', 'm'],
    'Tanks + Agitator': ['m3/day', 'hrs', '%', 'm', ' '],
    'Reactors - Jacketed, agitated': ['m3/day', 'hrs', '%', 'm', ' ', '°C'],
    'Extruder - Screw': ['m3/day'],
    'Agitators & mixers - Static mixer': ['m3/day'],
    // 'Pressure vessels - Vertical': ['m3'],
    'Crushers - Jaw crusher': ['tonnes/h'],
    'Crushers - Gyratory crusher': ['tonnes/h'],
    'Crushers - Reversible hammer mill': ['tonnes/h'],
    'Crushers - Ball mill': ['tonnes/h'],
    'Haulage - Electric Fleet': ['tonnes/h'],
    'Haulage - Diesel Fleet': ['tonnes/h'],
    'Filters - Ultrafiltration (>0.8 L/s)':['m3/day', ' '],
    'Filters - Ultrafiltration (<0.8 L/s)':['m3/day', ' '],
    'Electrodialysis':['m3/day', ' '],
    'Aeration Basin (incl. aeration)':['m3/day', 'hrs',' '],
    'Membrane Spiral':['m3/day', ' '],
    'Thickener':['m2', ' '],
    'RO (<1600 m2)':['m3/day', ' '],
    'RO (>1600 m2)':['m3/day', ' ']
};

const PropertyPopup = ({getMADropdownData, graph, pageX, pageY, modelId, econVars, isOpen, onClose, reCalculate, updateGraphs, orgSectors}) => {
    const [position, setPosition] = useState({x: pageX, y: pageY});
    const [selectedFeedDropDownValue, setSelectedFeedDropDownValue] = useState("");
    const [showCostAndSize, setShowCostAndSize] = useState(false);
    const [showCostAndSizeButton, setShowCostAndSizeButton] = useState(false);
    const {isAuthenticated, getIdTokenClaims} = useAuth0();
    const [isCostAndSizePressed, setIsCostAndSizePressed] = useState(false);
    const componentRef = useRef(null);
    const [displayChemicalCostingLookup, setDisplayChemicalCostingLookup] = useState(false);
    const [chemicalCostingOptions, setChemicalCostingOptions] = useState([]);
    const [chemicalCostingPrice, setChemicalCostingPrice] = useState(0);
    const [selectedChemical, setSelectedChemical] = useState("");
    const [dictionaryUnits, setDictionaryUnits] = useState([]);
    const [selectedCostAndSizeDropdown, setSelectedCostAndSizeDropdown] = useState(null);

    const handleChemicalCostingChange = (selectedOption) => {
        if (selectedOption == null) {
          setChemicalCostingPrice(0);
          return;
        }

        setSelectedChemical(selectedOption.value);
      
        const selectedValue = selectedOption.value.toLowerCase();
      
        for (let i = 0; i < chemicalCostingOptions.length; i++) {
          if (
            chemicalCostingOptions[i].name.toLowerCase() === selectedValue ||
            chemicalCostingOptions[i].CASRN.toLowerCase() === selectedValue
          ) {
            setChemicalCostingPrice(chemicalCostingOptions[i].price);
            break;
          }
        }
      };
      

    const promiseOptions = inputValue => new Promise(resolve => {
        fetchChemicalCostingData(inputValue).then(data => {
            resolve(data);
        }).catch(error => {
            console.error('Error fetching data:', error);
            resolve([]);
        })
    });

    const fetchChemicalCostingData = async (inputValue) => {
        try {
          const response = await FlowireAPI.authGet('/api/getchemicals', getIdTokenClaims, isAuthenticated);
      
          if (response['chemicals']) {
            setChemicalCostingOptions(response['chemicals']);
      
            // Create options with name, CASRN, and price
            const chemicalOptions = response['chemicals'].flatMap((chemical) => [
              { value: chemical.name.toLowerCase(), label: chemical.name, price: chemical.price },
              { value: chemical.CASRN.toLowerCase(), label: chemical.CASRN, price: chemical.price },
            ]);
      
            // Filter options based on the inputValue
            const filteredData = inputValue
              ? chemicalOptions.filter((item) => 
                  item.value.includes(inputValue.toLowerCase()) || item.label.toLowerCase().includes(inputValue.toLowerCase())
                )
              : chemicalOptions;
      
            return filteredData;
          }
        } catch (error) {
          console.error('Error fetching chemical data:', error);
          return [];
        }
      };

    const forceUpdate = useForceUpdate();

    const [isFeedUnit, setIsFeedUnit] = useState(false);
    const [components, setComponents] = useState([])
    const unit = graph.getCell(modelId);

    const getCostAndSizeOptions = inputValue => new Promise(resolve => {
        const getOptions = async (inputValue) => {
            let options;
            if (inputValue.trim() === "") {
                options = dictionaryUnits.map(unit => ({value: unit, label: unit}));
            } else {
                options = dictionaryUnits.filter(unit => unit.toLowerCase().includes(inputValue.toLowerCase()))
                                         .map(unit => ({value: unit, label: unit}));
            }
            
            return options;
        }

        getOptions(inputValue).then(data => {
            resolve(data);
        }).catch(error => {
            console.error('Error fetching data:', error);
            resolve([]);
        })
    })

    const dutyFunction = () => {
        var element = modelId
        var globalObjectsList = econVars
        let unit = graph.getCell(element)
        let totalFlow = 0
        //check if the unit is a feed or product, then it doesn't need a duty
        try {
            if (unit.attributes['equipment_Properties']['unitType'] != 'feed' || unit.attributes['equipment_Properties']['unitType'] != 'Feed' || unit.attributes['equipment_Properties']['unitType'] != 'product' || unit.attributes['equipment_Properties']['unitType'] != 'Product') {
                //utputStreams = graph.getConnectedLinks(element)
                //let totalFlow = parseFloat(graph.getConnectedLinks(unit)[0].attributes['complete_Stream_Properties']['totalFlow'])
                let streamList = graph.getLinks(element)
                // if(!unit.attributes['equipment_Economics']['Duty']) unit.attributes['equipment_Economics']['Duty'] = {}
                let duty = unit.attributes['equipment_Economics']['Duty']['Value']
                let outputStreams = unit.attributes['equipment_Properties']['outputStreams']
                //write a for loop for calculting the total flow based on the flow of each outputStream
                for (let i = 0; i < outputStreams.length; i++) {
                    let result = streamList.find(item => item.attributes && item.attributes.CustomName === parseInt(outputStreams[i]));
                    let specificFlow = result.attributes['complete_Stream_Properties']['totalFlow']
                    //add the value of total flow to a variable
                    totalFlow += specificFlow
                }
                let totalDuty = duty //removing scaling
                //reads the duty from the elements duty
                //retreive the electricity cost from the global objects list

                let electricityCost = globalObjectsList.elecCost
                let opexUnit = totalDuty * (electricityCost / 1000) * 8000
                //save the opexUnit into the opex div
                unit.attributes['equipment_Economics']['Opex']['Value'] = opexUnit
                // console.log(unit.attributes['equipment_Economics']['Opex']['Value'])
            }
        } catch (e) {
        }
    }

    useEffect(() => {

        if (unit.attributes['equipment_Properties']['selectedFeedDropDownValue']) {
            setSelectedFeedDropDownValue(unit.attributes['equipment_Properties']['selectedFeedDropDownValue']);
        } else {
            setSelectedFeedDropDownValue("Total Feed");
            unit.attributes['equipment_Properties']['selectedFeedDropDownValue'] = "Total Feed";
        }

        if (unit.attributes['selectedDropdownValue']) {
            setSelectedCostAndSizeDropdown({value: unit.attributes['selectedDropdownValue'], label: unit.attributes['selectedDropdownValue']});
        } else {
            unit.attributes['selectedDropdownValue'] = "Custom"; //this is where the default is set
            setSelectedCostAndSizeDropdown({value: unit.attributes['selectedDropdownValue'], label: unit.attributes['selectedDropdownValue']});
        }

        if (!unit.attributes['equipment_Properties'][unit.attributes['selectedDropdownValue']]) {
            unit.attributes['equipment_Properties'][unit.attributes['selectedDropdownValue']] = {};
        }

        if (!unit.attributes['equipment_Properties'][unit.attributes['selectedDropdownValue']][unit.attributes['selectedDropdownValue'] + 'Input2Value']) {
            unit.attributes['equipment_Properties'][unit.attributes['selectedDropdownValue']][unit.attributes['selectedDropdownValue'] + 'Input2Value'] = "";
        } else {
            unit.attributes['equipment_Properties'][unit.attributes['selectedDropdownValue']][unit.attributes['selectedDropdownValue'] + 'Input2Value'] = unit.attributes['equipment_Properties'][unit.attributes['selectedDropdownValue']][unit.attributes['selectedDropdownValue'] + 'Input2Value'];
        }

        let economicsData;

        if (['product', 'Product', 'feed', 'Feed'].includes(unit.attributes['equipment_Properties']['unitType'])) {
            economicsData = {'Cost': {'Value': 0, 'Units': 'USD/tonne'}};
        } else if (unit.attributes['equipment_Properties']['unitType'] !== 'Pipework') {
            economicsData = {
                'Opex': {'Value': 0, 'Units': 'USD/yr'},
                'Capex': {'Value': 0, 'Units': 'USD'},
                'Duty': {'Value': 0, 'Units': 'kW'}
            };
        }

        if (economicsData && !unit.prop('equipment_Economics')) {
            unit.prop('equipment_Economics', economicsData);
            getMADropdownData(graph)
        }

        unit.attributes['equipment_Properties'][unit.attributes['selectedDropdownValue']][unit.attributes['selectedDropdownValue'] + 'Input2Value'] = extraDivsDictionaryUnits[unit.attributes['selectedDropdownValue']];

        setShowCostAndSizeButton(!(unit.attributes['equipment_Properties']['unitType'] === "Feed" || unit.attributes['equipment_Properties']['unitType'] === "Product"));
        setIsFeedUnit(unit.attributes['equipment_Properties']['unitType'] === 'feed' || unit.attributes['equipment_Properties']['unitType'] === 'Feed');
        setComponents(getOutletComponents(graph, unit));

        setDictionaryUnits(Object.keys(extraDivsDictionarySectors).filter((key) => {
            if(extraDivsDictionarySectors[key] && extraDivsDictionarySectors[key].includes('Default')) {
                return true;
            }
            for (let sector of orgSectors) {
                if (extraDivsDictionarySectors[key] && (extraDivsDictionarySectors[key].includes(sector))) {
                    return true;
                }
            }
            return false;
        }));
    }, []);

    const handleDropdownChange = (e) => {
        setSelectedFeedDropDownValue(e.target.value);
        unit.attributes['equipment_Properties']['selectedFeedDropDownValue'] = e.target.value;
    }

    const handleDropDownContainerChange = (e) => {
        let value = e.value;
        setSelectedCostAndSizeDropdown(e);
        unit.attributes['selectedDropdownValue'] = value;

        if (!unit.attributes['equipment_Properties'][unit.attributes['selectedDropdownValue']]) {
            unit.attributes['equipment_Properties'][unit.attributes['selectedDropdownValue']] = {};
        }

        if (!unit.attributes['equipment_Properties'][unit.attributes['selectedDropdownValue']][unit.attributes['selectedDropdownValue'] + 'Input2Value']) {
            unit.attributes['equipment_Properties'][unit.attributes['selectedDropdownValue']][unit.attributes['selectedDropdownValue'] + 'Input2Value'] = extraDivsDictionaryUnits[unit.attributes['selectedDropdownValue']];
        } else {
            unit.attributes['equipment_Properties'][unit.attributes['selectedDropdownValue']][unit.attributes['selectedDropdownValue'] + 'Input2Value'] = unit.attributes['equipment_Properties'][unit.attributes['selectedDropdownValue']][unit.attributes['selectedDropdownValue'] + 'Input2Value'];
        }

        // Set default values based on the selected dropdown
        const defaultValues = getDefaultValuesForDropdown(unit.attributes['selectedDropdownValue'],unit,modelId,graph);
        for (const field in defaultValues) {
            unit.attributes['equipment_Properties'][unit.attributes['selectedDropdownValue']][field] = defaultValues[field];
        }

        forceUpdate();
    };

    const getDefaultValuesForDropdown = (dropdownValue,unit,modelId,graph) => {
        let defaultValues = {};
        let totalMassOut = (totalOutputMB(unit,modelId,graph));
        let densitySolid = 2265; //for copper ore
        let massFlowSolid = (totalMassOut*densitySolid)/24;
        let numberOfUnits = 1;
        // Add default values for each dropdown option as needed
        switch (dropdownValue) {
            case 'Tanks - cone roof':
                defaultValues = {
                    'Mass Flow': totalMassOut,
                    'Operating Level (%)': 90,
                    'Height (m)': 7,
                };
                break;

            case 'Tanks + Agitator':
                defaultValues = {
                    'Mass Flow': totalMassOut,
                    'Operating Level (%)': 90,
                    'Height (m)': 7,
                    'Agitation Application': 'blending/mixing',
                };
                break;

            case 'Reactors - Jacketed, agitated':
                defaultValues = {
                    'Mass Flow': totalMassOut,
                    'Operating Level (%)': 90,
                    'Height (m)': 7,
                    'Agitation Application': 'blending/mixing',
                };
                break;

            case 'Agitators & mixers - Static mixer':
                defaultValues = {
                    'Mass Flow': totalMassOut,
                };
                break;

            case 'Extruder - Screw':
                    defaultValues = {
                        'Mass Flow': totalMassOut,
                    };
                    break;

            case 'Custom':
                defaultValues = {
                    'Mass Flow': totalMassOut,
                };
                break;

            case 'Filters - Plate and frame':
                defaultValues = {
                    'Mass Flow': totalMassOut,
                };
                break;

            case 'Crushers - Jaw crusher':
                defaultValues = {
                    'Mass Flow': massFlowSolid,
                };
                break;

            case 'Crushers - Gyratory crusher':
                defaultValues = {
                    'Mass Flow': massFlowSolid,
                };
                break;
            
            case 'Crushers - Reversible hammer mill':
                defaultValues = {
                    'Mass Flow': massFlowSolid,
                };
                break;

            case 'Crushers - Ball mill':
                defaultValues = {
                    'Mass Flow': massFlowSolid,
                };
                break;

            case 'Haulage - Electric Fleet':
                defaultValues = {
                    'Mass Flow': massFlowSolid,
                };
                break;

            case 'Haulage - Diesel Fleet':
                    defaultValues = {
                        'Mass Flow': massFlowSolid,
                    };
                break;
            case 'Filters - Ultrafiltration (>0.8 L/s)':
                    defaultValues = {
                        'Flow': totalMassOut,
                        'Number' : 1
                    };
                break;
            case 'Filters - Ultrafiltration (<0.8 L/s)':
                    defaultValues = {
                        'Flow': totalMassOut,
                        'Number' : 1
                    };
                break;
            case 'Electrodialysis':
                    defaultValues = {
                        'Flow': totalMassOut,
                        'Number' : 1
                    };
                break;
            case 'Membrane Spiral':
                    defaultValues = {
                        'Flow': totalMassOut,
                        'Number' : 1
                    };
                break;
            case 'RO (<1600 m2)':
                    defaultValues = {
                        'Flow': totalMassOut,
                        'Number' : 1
                    };
                break;
            case 'RO (>1600 m2)':
                    defaultValues = {
                        'Flow': totalMassOut,
                        'Number' : 1
                    };
                break;
            case 'Aeration Basin (incl. aeration)':
                    defaultValues = {
                        'Flow': totalMassOut,
                        'Residence Time (hrs)': 0.5,
                        'Number' : 1
                    };
                break;
            case 'Thickener':
                    defaultValues = {
                        'Number' : 1
                    };
                break;

            
            // Add more cases for other dropdown options if needed
            default:
                break;
        }

        return defaultValues;
    };


    const handleExtraInputChange = (e, field) => {
        const equipmentType = unit.attributes['selectedDropdownValue'];
        if (!unit.attributes['equipment_Properties'][equipmentType]) {
            unit.attributes['equipment_Properties'][equipmentType] = {};
        }
        unit.attributes['equipment_Properties'][equipmentType][field] = e.target.value;
        forceUpdate();
    };

    const handleCostAndSize = () => {
        setShowCostAndSize(!showCostAndSize);
        setIsCostAndSizePressed(!isCostAndSizePressed);
    };

    const handleValueChange = (e, econName) => {
        unit.attributes['equipment_Economics'][econName]['Value'] = e.target.value
        if (unit.attributes['equipment_Properties']['unitType'] === 'Feed' || unit.attributes['equipment_Properties']['unitType'] === 'Product') {
            delete unit.attributes['costingChemical']
        }
        forceUpdate()
    }

    const handleUnitChange = (e, econName) => {
        unit.attributes['equipment_Economics'][econName]['Units'] = e.target.value
        forceUpdate()
    }

    const handleCalculate = async () => {
        if (unit.attributes['equipment_Properties']) {
            let dataToSend = {};
            //console.log(unit.attributes['selectedDropdownValue'])
            let selectedDropDownValue = unit.attributes['selectedDropdownValue']
            if (unit.attributes['equipment_Properties'][selectedDropDownValue]) {
                let propData = {};
                //console.log(unit.attributes['equipment_Properties'][selectedDropDownValue])
                let propDataKeys = Object.keys(unit.attributes['equipment_Properties'][selectedDropDownValue]);
                //console.log(propDataKeys)
                propDataKeys.forEach(key => {
                    propData[key] = unit.attributes['equipment_Properties'][selectedDropDownValue][key];
                });
                propData['unitName'] = selectedDropDownValue
                // for (let label in unit.attributes['equipment_Properties'][selectedDropDownValue]) {
                //    // Check if label is not the type of calculation
                //        console.log(label)
                //        let labelValue = label.endsWith('Value') ? 'value' : label;
                //        console.log(unit.attributes['equipment_Properties'][selectedDropDownValue])
                //        propData[labelValue] = unit.attributes['equipment_Properties'][selectedDropDownValue][label];
                // }
                //console.log(JSON.stringify(propData) + " propData")
                dataToSend = propData;
            }

            // Add 'type' property to dataToSend object
            //dataToSend['type'] = 'unitCosting';
            await FlowireAPI.authPost('/api/econ/equipmentsizing', dataToSend, getIdTokenClaims, isAuthenticated).then((data) => {
                if (data) {
                    //console.log(data)
                    unit.attributes['equipment_Economics']['Capex']['Value'] = data[0].result
                    unit.attributes['equipment_Economics']['Duty']['Value'] = data[1].duty//needs to change
                    forceUpdate()
                }
            })
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            updateGraphs()
            reCalculate()
        }
    }

    dutyFunction()

    return (
        <>
            {isOpen && (
                <PopupLayout
                    title={`${unit.attributes.attrs.shapename.innerHTML.replace(/<p>|<\/p>/g, '').includes('<br>') ? unit.attributes.unitType : unit.attributes.attrs.shapename.innerHTML.replace(/<p>|<\/p>/g, '')} Economics`}
                    closeFunction={onClose}
                    pageX={position.x}
                    pageY={position.y}
                    minWidth="300px"
                    componentRef={componentRef}
                    buttons={<>
                        {showCostAndSizeButton && (
                            <PopupButton onClick={handleCostAndSize}>Cost And Size</PopupButton>)}
                        {showCostAndSize && (<PopupButton onClick={handleCalculate}>Calculate</PopupButton>)}
                        {(unit.attributes['equipment_Properties']['unitType'] == "Feed" || unit.attributes['equipment_Properties']['unitType'] == "Product") && (
                            <PopupButton onClick={() => setDisplayChemicalCostingLookup(!displayChemicalCostingLookup)}>Chemical Price Look Up</PopupButton>
                        )}
                    </>}>

                    <Box overflow="auto">

                        {isFeedUnit && (
                            <Select value={selectedFeedDropDownValue}
                                    onChange={handleDropdownChange}>
                                {components.map(component => (
                                    <option key={component} value={component}>
                                        {component}
                                    </option>
                                ))}
                                <option key="Total Feed" value="Total Feed">Total Feed</option>
                            </Select>
                        )}

                        

                        {unit.prop('equipment_Economics') && Object.keys(unit.prop('equipment_Economics')).map(component => (
                            <Box key={component}>
                                <Flex
                                    p={2}
                                    justifyContent="space-between"
                                    alignItems="center">
                                    <Box width="40%">{component}</Box>
                                    <InputGroup width="60%">
                                        <InputRightElement pointerEvents="none" color="gray.600" background="gray.200"
                                                           fontWeight={600} width='auto' minWidth='var(--input-height)'
                                                           pl={1} pr={1}
                                                           fontSize=".9rem" borderTopRightRadius={8}
                                                           borderBottomRightRadius={8}
                                                           children={unit.prop('equipment_Economics')[component]['Units']}/>

                                        <Input
                                            disabled={isCostAndSizePressed}
                                            onKeyDown={handleKeyDown}
                                            value={unit.prop('equipment_Economics')[component]['Value']}
                                            onChange={(e) => {
                                                handleValueChange(e, component)
                                            }}
                                        />
                                        {/*<Input*/}
                                        {/*    disabled={isCostAndSizePressed}*/}
                                        {/*    onKeyDown={handleKeyDown}*/}
                                        {/*    value={unit.prop('equipment_Economics')[component]['Units']}*/}
                                        {/*    onChange={(e) => {*/}
                                        {/*        handleUnitChange(e, component)*/}
                                        {/*    }}*/}
                                        {/*/>*/}
                                    </InputGroup>
                                </Flex>
                                {unit.attributes['costingChemical'] ? (
                                    <Box float="right" fontStyle={"italic"} mb="2" mr="20">{unit.attributes['costingChemical']}</Box>
                                ) : <></>}
                            </Box>
                        ))}

                        {unit.attributes['equipment_Properties']['unitType'] !== 'product' && unit.attributes['equipment_Properties']['unitType'] !== 'Product' && unit.attributes['equipment_Properties']['unitType'] !== 'feed' && unit.attributes['equipment_Properties']['unitType'] !== 'Feed' && (
                            <Box className='dropdownContainer'>
                            <AsyncSelect
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                className='drop-down'
                                menuPortalTarget={document.body}
                                loadOptions={getCostAndSizeOptions}
                                defaultOptions={dictionaryUnits.map(unit => ({ value: unit, label: unit }))}
                                onChange={handleDropDownContainerChange}
                                value={selectedCostAndSizeDropdown}
                            />                            
                            </Box>
                        )}

                        {showCostAndSize && unit.attributes['selectedDropdownValue'] && unit.attributes['selectedDropdownValue'] in extraDivsDictionary && (
                            <React.Fragment key={unit.attributes['selectedDropdownValue']}>
                                {extraDivsDictionary[unit.attributes['selectedDropdownValue']].map((f, index) => {
                                    const field = extraDivsDictionaryPost[f] || f;
                                    const fieldUnit = (extraDivsDictionaryUnits[unit.attributes['selectedDropdownValue']][index] || '').trim();
                                    return (
                                        <Flex key={field} p={2} justifyContent="space-between" alignItems="center">
                                            <Box width="40%">{field}</Box>
                                            <InputGroup width="60%">
                                                {fieldUnit && <InputRightElement pointerEvents="none" color="gray.600"
                                                                                 background="gray.200"
                                                                                 fontWeight={600} width='auto'
                                                                                 minWidth='var(--input-height)'
                                                                                 pl={1} pr={1}
                                                                                 fontSize=".9rem"
                                                                                 borderTopRightRadius={8}
                                                                                 borderBottomRightRadius={8}
                                                                                 children={fieldUnit}/>}

                                                <Input
                                                    value={unit.attributes['equipment_Properties'][unit.attributes['selectedDropdownValue']][field] || ''}
                                                    onChange={(e) => handleExtraInputChange(e, field)}
                                                />
                                                {/*<Input*/}
                                                {/*    disabled={isCostAndSizePressed}*/}
                                                {/*    onKeyDown={handleKeyDown}*/}
                                                {/*    value={unit.prop('equipment_Economics')[component]['Units']}*/}
                                                {/*    onChange={(e) => {*/}
                                                {/*        handleUnitChange(e, component)*/}
                                                {/*    }}*/}
                                                {/*/>*/}
                                            </InputGroup>
                                        </Flex>
                                    );
                                })}
                            </React.Fragment>
                        )}

                        {displayChemicalCostingLookup && (
                            <Box borderTop='1px solid #eee' pt={4} mt="25">
                                <Heading mb="5" size='sm'>Chemical Costing Lookup</Heading>
                            <Flex justifyContent={"space-between"} alignItems={"center"}>
                                <Box width="50%">
                                    <AsyncSelect onChange={handleChemicalCostingChange} loadOptions={promiseOptions} isClearable defaultOptions />
                                </Box>
                                <InputGroup width="50%">
                                    <InputRightElement pointerEvents="none" color="gray.600"
                                    background="gray.200"
                                    fontWeight={600} width='auto'
                                    minWidth='var(--input-height)'
                                    pl={1} pr={1}
                                    fontSize=".75rem"
                                    borderTopRightRadius={8}
                                    borderBottomRightRadius={8}
                                    children={"USD/tonne"}/>
                                    <Input readOnly disabled={true} value={chemicalCostingPrice}></Input>
                                </InputGroup>
                            </Flex>
                            <Button mt="2" float="right" colorScheme='blue' size="sm" onClick={() => {
                                unit.attributes['equipment_Economics']['Cost']['Value'] = chemicalCostingPrice;
                                unit.attributes['costingChemical'] = selectedChemical;
                                forceUpdate()
                            }}>Add</Button>
                            </Box>
                        )}
                    </Box>
                </PopupLayout>
            )}
        </>
    );

}

function getOutletComponents(graph, feedUnit) {
    let stream = graph.getConnectedLinks(feedUnit)
    let streamComponents
    if (!stream || !stream[0] || !stream[0].attributes) {
        return []
    }

    streamComponents = Object.keys(stream[0].attributes['complete_Stream_Properties']['massFlow'])
    return streamComponents
}

export default PropertyPopup;