import { useEffect, useState } from "react";
import Simulator from '../Simulator/Diagram';
import Designer from '../Designer/Diagram';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";
import FlowireAPI from "../../flowireapi/FlowireAPI";
import FloLoading from "../FloLoading/FloLoading";

const GraphState = {
  none: "none",
  loading: "loading",
  designer: "designer",
  simulator: "simulator"
};

const Diagram = () => {
  const [useGraphState, setUseGraphState] = useState(GraphState.none);
  const [uuid, setUUID] = useState(null);
  const [graphData, setGraphData] = useState({});
  const [graphName, setGraphName] = useState("");
  const [graphGroup, setGraphGroup] = useState("");
  const [updated, setUpdated] = useState(false);
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const { dataId, isSim } = useParams();
  const [orgSectors, setOrgSectors] = useState(null);
  const [isTemplate, setIsTemplate] = useState(false);
  const navigate = useNavigate();

  const setData = (data, name) => {
    setGraphData(data);
    setGraphName(name);
  };

  const useSimulator = (uuid) => {
    navigate(`/d/${uuid}/sim`);
		setUUID(uuid);
    setUseGraphState(GraphState.simulator);
  };

  const useDesigner = (uuid) => {
    navigate(`/d/${uuid}`);
		setUUID(uuid);
    setUseGraphState(GraphState.designer);
  };

  useEffect(() => {
    if(!uuid) return;
    const getSectors = () => {
      FlowireAPI.authPost('/api/organizations/sector', {'graph_uuid': uuid}, getIdTokenClaims, isAuthenticated).then((data) => {
        if (data) {
            setOrgSectors(data.sectors);
        }
      });
    }
    
    getSectors();
  }, [uuid]);

  useEffect(() => {
    if(dataId === "new") {
      if (isSim === 'sim') {
        useSimulator(dataId);
        return;
      }

      useDesigner("new");

      return;
    }
    setUseGraphState(GraphState.loading);
    FlowireAPI.authGet(`/api/load/${uuid ? uuid : dataId}`, getIdTokenClaims, isAuthenticated, (e) => {
      if(e.status === 401) {
        navigate("/nopermissions");
      } else {
        navigate("/error");
      }
    }).then((data) => {
      if(data) {
        if(!data.data.designer || isSim === 'sim' || data.is_template) {
          useSimulator(data.data.uuid);
        } else {
          useDesigner(data.data.uuid);
        }
        setUUID(data.data.uuid);
        setData(data.data, data.name);
        setGraphGroup(data.group_uuid);
        setIsTemplate(data.is_template);
      }
    }).catch((error) => {
      console.log(error);
    });
  }, [isAuthenticated]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if(!updated) return;
      if(isTemplate) return;
      const message = "Are you sure you want to leave? Changes you made may not be saved.";
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [updated]);

  return (
    <div id="graph">
      {useGraphState === GraphState.simulator && (
        <Simulator orgSectors={orgSectors} isUpdated={updated} hasUpdated={setUpdated} setGraphData={setData} graphName={graphName} graphData={graphData} uuid={uuid} useDesigner={useDesigner} graphGroup={graphGroup} setGraphGroup={setGraphGroup} isTemplate={isTemplate}></Simulator>
      )}
      {useGraphState === GraphState.designer && (
        <Designer orgSectors={orgSectors} isUpdated={updated} hasUpdated={setUpdated} setGraphData={setData} graphName={graphName} graphData={graphData} uuid={uuid} useSimulator={useSimulator} graphGroup={graphGroup} setGraphGroup={setGraphGroup}></Designer>
      )}
      {useGraphState === GraphState.loading && (
        <FloLoading/>
      )}
    </div>
  );
};

export default Diagram;
