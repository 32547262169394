import { useEffect, useState } from "react";
import FlowireAPI from "../../flowireapi/FlowireAPI";
import { useAuth0 } from "@auth0/auth0-react";
import { Divider, Flex, Heading, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";
import { createPortal } from "react-dom";
import GraphItem from "./components/GraphItem";
import { useNavigate } from "react-router-dom";


const Templates = () => {
    const { isAuthenticated, getIdTokenClaims } = useAuth0();
    const [ templates, setTemplates ] = useState([]);
    const [ searchTerm, setSearchTerm ] = useState("");
    const [domReady, setDomReady] = useState(false);
    const navigate = useNavigate();

    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        setDomReady(true);
    }, []);

    useEffect(() => {
        const filtered = templates.filter((item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setFilteredData(filtered);
    }, [templates,searchTerm]);

    useEffect(() => {
        FlowireAPI
            .authGet('/api/template', getIdTokenClaims, isAuthenticated)
            .then((data) => data ? setTemplates(data) : setTemplates([]))
    }, [domReady])

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value)
    }

    const formatOptions = {
        // weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        // hour: 'numeric', // instead of '2-digit'
        // minute: '2-digit'
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        //timeZone: timezones[timezone]
        const formatter = new Intl.DateTimeFormat('en-US', {...formatOptions});
        return formatter.format(date); // + " " + timezone
    };

    const cardClicked = (uuid) => {
        navigate("/d/" + uuid)
    }

    return (
        <Flex direction="column">

            {
                domReady && createPortal(
                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <FaSearch size={16}/>
                        </InputLeftElement>
                        <Input placeholder="Search Projects" width="500px" onChange={handleSearchChange}/>
                    </InputGroup>,
                    document.getElementById('navbarContent')
                )
            }

            <Flex direction="row" alignItems="center" justifyContent="space-between" ml="8" mr="8" mb="3">
                <Heading size="md">
                    Templates
                </Heading>
            </Flex>

            <Flex mt="3" mr="8" ml="8">
                <Divider/>
            </Flex>

            <Flex flexWrap="wrap" m="4">
                {filteredData.map((item) => (
                    <GraphItem
                        key={item.uuid}
                        item={item}
                        formatDate={formatDate}
                        clicked={cardClicked}/>
                ))}
            </Flex>
        </Flex>
    )
}

export default Templates;