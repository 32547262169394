import React from 'react';
import PropertyPopup from './components/PropertyPopup';

const OverallEconomicsPopup = React.forwardRef((props, ref) => {
    return (
        <PropertyPopup
            ref={ref}
            calculateFeedEP={props.calculateFeedEP}
            simulateEconVars={props.simulateEconVars}
            isAuthenticated={props.isAuthenticated}
            getIdTokenClaims={props.getIdTokenClaims}
            setGetEconArraysF={props.setGetEconArraysF}
            openNPVGraph={props.openNPVGraph}
            openCapexGraph={props.openCapexGraph}
            openOpexGraph={props.openOpexGraph}
            solved_Dictionary={props.solved_Dictionary}
            closeFunction={props.closeFunction}
            graph={props.graph}
            econVars={props.econVars}
        />
    );
});

export default OverallEconomicsPopup;
