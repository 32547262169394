import React from 'react';
import { Box, Heading, Text, Center, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const NoPermissions = () => {
    const navigate = useNavigate()
  return (
    <Center h="100vh">
      <Box textAlign="center">
        <Heading size="xl" mb={4}>
          No Permissions
        </Heading>
        <Text fontSize="lg" mb={4}>
          Sorry, you don't have the necessary permissions to access this page.
        </Text>
        <Button colorScheme="blue" onClick={() => navigate("/")}>
          Go Home
        </Button>
      </Box>
    </Center>
  );
};

export default NoPermissions;
