import React, { useState, useImperativeHandle } from 'react';
import PropertyPopup from './components/PropertyPopup';

const InfoPopup = React.forwardRef((props, ref) => {
    const [popups, setPopups] = useState([]);

    const addInfoStreamPropertyPopup = (getMADropdownData, graph, linkId, pageX, pageY) => {
        if (popups) {
            const popupExists = popups.some(popup => popup.id === linkId.cid);
            if (!popupExists)
                setPopups(prevPopups => [...prevPopups, { getMADropdownData: getMADropdownData, id: linkId.cid, isOpen: true, graph, linkId, pageX, pageY }]);
        }
    }

    const openInfoStreamPropertyPopup = (id) => {
        if (popups) {
            const popupExists = popups.some(popup => popup.id === id);
            if (popupExists) {
                setPopups(prevPopups => prevPopups.map(popup =>
                    popup.id === id ? { ...popup, isOpen: true } : popup
                ));
            }
        }
    }

    const hideInfoStreamPropertyPopup = (id) => {
        if (popups) {
            const popupExists = popups.some(popup => popup.id === id);
            if (popupExists) {
                setPopups(prevPopups => prevPopups.map(popup =>
                    popup.id === id ? { ...popup, isOpen: false } : popup
                ));
            }
        }
    }

    const closeInfoStreamPropertyPopup = (id) => {
        setPopups(prevPopups => prevPopups.filter(popup => popup.id !== id));
    }

    useImperativeHandle(ref, () => ({
        addInfoStreamPropertyPopup,
        openInfoStreamPropertyPopup,
        hideInfoStreamPropertyPopup,
        closeInfoStreamPropertyPopup,
        popups
    }));

    return (
        <>
            {popups.map(popup =>
                <PropertyPopup
                    key={popup.id}
                    isOpen={popup.isOpen}
                    onOpen={() => openInfoStreamPropertyPopup(popup.id)}
                    onClose={() => closeInfoStreamPropertyPopup(popup.id)}
                    graph={popup.graph}
                    linkId={popup.linkId}
                    simulateFunction={props.simulateFunction}
                    selectedMassConversion={props.selectedMassConversion}
                    setSelectedMassConversion={props.setSelectedMassConversion}
                    massConversionOptions={props.massConversionOptions}
                    selectedFractionConversion={props.selectedFractionConversion}
                    setSelectedFractionConversion={props.setSelectedFractionConversion}
                    fractionConversionOptions={props.fractionConversionOptions}
                    econVars={props.econVars}
                    pageX={popup.pageX}
                    pageY={popup.pageY}
                    getMADropdownData={popup.getMADropdownData}
                />
            )}
        </>
    );
});

export default InfoPopup;
