import {Flex} from '@chakra-ui/react';
import {Navbar} from "./components/Navbar/Navbar";
import {Sidebar} from "./components/Sidebar/Sidebar";
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";

export function Layout(props) {
    const {pathname} = useLocation();
    const [withSidebar, setWithSidebar] = useState('true');

    useEffect(() => {
        setWithSidebar(pathname.indexOf('/sim') > 0);
    }, [pathname]);

    return <Flex direction="row">
        {!withSidebar ?
            <Sidebar>
                {props.sideBar}
            </Sidebar>
            : <></>}
        <Flex direction="column" width="100%">
            <Navbar>
                {props.navBar}
            </Navbar>
            {props.children}
        </Flex>
    </Flex>
}
