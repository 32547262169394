import React, { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, Input, useToast } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import FlowireAPI from '../../../../flowireapi/FlowireAPI';

const CreateGroupPopup = ({ isOpen, onClose, onGroupCreated, existingGroups }) => {
    const [groupName, setGroupName] = useState("");
    const { getIdTokenClaims, isAuthenticated } = useAuth0();
    const toast = useToast();

    const handleCreateGroup = async () => {
        if (groupName === "") {
            toast({
                title: "Group name cannot be empty",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        // Check for duplicate group name
        const isDuplicate = existingGroups.some(group => group.name.toLowerCase() === groupName.toLowerCase());
        if (isDuplicate) {
            toast({
                title: "Group name already exists",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        try {
            await FlowireAPI.authPost('/api/addgroup', { group_name: groupName }, getIdTokenClaims, isAuthenticated);
            onGroupCreated(groupName);
            onClose();
        } catch (error) {
            console.error("Error creating group:", error);
            toast({
                title: "Failed to create group",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Create New Group</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Input
                        placeholder="Enter group name"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleCreateGroup}>
                        Create
                    </Button>
                    <Button variant="ghost" onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CreateGroupPopup;
