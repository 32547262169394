import React, { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, useToast, Select, Text, Box } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import FlowireAPI from '../../../../flowireapi/FlowireAPI';

const DeleteGroupPopup = ({ isOpen, onClose, groups, onGroupDeleted }) => {
    const [selectedGroup, setSelectedGroup] = useState("");
    const { getIdTokenClaims, isAuthenticated } = useAuth0();
    const toast = useToast();

    const handleDeleteGroup = async () => {
        if (selectedGroup === "") {
          toast({
            title: "Please select a group to delete",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }
        try {
          await FlowireAPI.authPost('/api/deletegroup', { group_uuids: [selectedGroup] }, getIdTokenClaims, isAuthenticated);
          onGroupDeleted(selectedGroup);
          onClose();
        } catch (error) {
          console.error("Error deleting group:", error);
          toast({
            title: "Failed to delete group",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Delete Group</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Select placeholder="Select group to delete" onChange={(e) => setSelectedGroup(e.target.value)}>
                        {groups.filter(group => group.uuid !== 'all').map(group => (
                            <option key={group.uuid} value={group.uuid}>{group.name}</option>
                        ))}
                    </Select>
                    <Box my={8}> {/* This will add a margin of 8 units above and below */}
                    </Box>
                    <Text fontSize="sm" color="gray.600">
                        Please note: Deleting a group cannot be undone. All projects associated with this group will be unassigned.
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="red" mr={3} onClick={handleDeleteGroup}>
                        Delete
                    </Button>
                    <Button variant="ghost" onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default DeleteGroupPopup;
