import './FloLoading.css';

const FloLoading = () => {
	return (
		<div className="flo-loading-container">
			<div className="flo-loading"></div>
		</div>
	);
}

export default FloLoading;