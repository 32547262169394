import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import FloLoading from '../FloLoading/FloLoading'
import { useLocation } from 'react-router-dom';

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();
  
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const invite = queryParams.get('invitation');
    const org = queryParams.get('organization');
    const orgName = queryParams.get('organization_name');

    if (invite) {
      loginWithRedirect({ authorizationParams: { invitation: invite, organization: org, organizationName: orgName }});
    } else {
      loginWithRedirect();
    }
  }, [loginWithRedirect, location.search])
  return (
    <FloLoading/>
  );
};

export default Login;
