import { Box, Center, Divider, Flex, Input, Select, Text, Spinner } from "@chakra-ui/react";
import { FaHome, FaLayerGroup } from "react-icons/fa";
import { PopupButton, PopupLayout } from "../../../PopupLayout/PopupLayout";
import Plot from "react-plotly.js";
import { useState } from "react";

const MultivariateAnalysis = ({MAGraphData, isMAOpen, MAState, setMAState, MADropdownData, MASimulate, MAGraphLoading, setMAGraphLoading}) => {

    const [unitMin, setUnitMin] = useState(0);
    const [unitMax, setUnitMax] = useState(0);
    const [streamMin, setStreamMin] = useState(0);
    const [streamMax, setStreamMax] = useState(0);
    const [selectedUnit, setSelectedUnit] = useState("");
    const [selectedStream, setSelectedStream] = useState("");
    const [selectedReturnType, setSelectedReturnType] = useState("");

    const simulate = () => {
        MASimulate(selectedUnit, selectedStream, selectedReturnType, parseFloat(unitMin), parseFloat(unitMax), parseFloat(streamMin), parseFloat(streamMax));
    }

    return (
        <>
            {isMAOpen && (
            <Box
            position="absolute"
            bottom="0"
            left="4"
            margin="4"
            zIndex="1000"
            borderRadius="10px"
            boxShadow="lg"
            bgColor="white"
            padding="2"
            cursor="pointer"
            onClick={() => setMAState(MAState == "popup" ? "button" : "popup")}
            >
                <FaLayerGroup color="#1a58ab" size={30}/>
            </Box>
            )}
            {isMAOpen && MAState == "popup" && (
                <PopupLayout overflowY="hidden" minWidth={MAGraphData && MAGraphData.data.length > 0 && MAGraphData.data[0].length > 1 ? "750px" : "350px"} pageX={40} pageY={40} title="Multivariate Analysis" closeFunction={() => setMAState("button")}
                buttons={
                    <>
                    {
                    MAGraphLoading === true ? <Spinner /> : null
                    }
                    <Select value={selectedReturnType} onChange={(e) => setSelectedReturnType(e.target.value)} width="40%" size="sm" borderRadius={"10px"} placeholder="Select option">
                        <option value="1">NPV</option>
                        <option value="2">LCOP</option>
                    </Select>
                    <PopupButton disabled={MAGraphLoading} onClick={() => {
                        setMAGraphLoading(true)
                        simulate()
                    }}>Simulate</PopupButton>
                    </>
                }>
                    <Flex>
                        <Box>
                            <Select value={selectedUnit} onChange={(e) => setSelectedUnit(e.target.value)} placeholder="Select option">
                                {MADropdownData && MADropdownData.Units && MADropdownData.Units.map((item, index) => (
                                    <option key={index} value={item}>{item}</option>
                                ))}
                            </Select>
                            <Flex margin={2} justifyContent="space-between" alignItems="center">
                            <Box mr="2">Min</Box>
                            <Input value={unitMin} onChange={(e) => setUnitMin(e.target.value)} type="number" placeholder="Min"></Input>
                            </Flex>
                            <Flex margin={2} justifyContent="space-between" alignItems="center">
                            <Box mr="2">Max</Box>
                            <Input value={unitMax} onChange={(e) => setUnitMax(e.target.value)} type="number" placeholder="Max"></Input>
                            </Flex>
                        </Box>
                        <Center height='175px'>
                        <Divider orientation="vertical" variant="solid" colorScheme="black" margin="2" borderWidth={"1px"} />
                        </Center>
                        
                        <Box>
                            <Select value={selectedStream} onChange={(e) => setSelectedStream(e.target.value)} placeholder="Select option">
                            {MADropdownData && MADropdownData.Streams && MADropdownData.Streams.map((item, index) => (
                                    <option key={index} value={item}>{item}</option>
                                ))}
                            </Select>
                            <Flex margin={2} justifyContent="space-between" alignItems="center">
                            <Box mr="2">Min</Box>
                            <Input value={streamMin} onChange={(e) => setStreamMin(e.target.value)} type="number" placeholder="Min"></Input>
                            </Flex>
                            <Flex margin={2} justifyContent="space-between" alignItems="center">
                            <Box mr="2">Max</Box>
                            <Input value={streamMax} onChange={(e) => setStreamMax(e.target.value)} type="number" placeholder="Max"></Input>
                            </Flex>
                        </Box>
                    </Flex>

                    {MAGraphData && MAGraphData.data.length > 0 && MAGraphData.data[0].length > 1 && (
                        <>
                        <Divider borderWidth={"1px"} />
                        <Plot
                        data={[
                            {
                                z: MAGraphData.data,
                                x: MAGraphData.unitSteps,
                                y: MAGraphData.streamSteps,
                                type: 'surface'
                            }
                        ]}
                
                        layout={{width: 700, height: 500, title: 'Multivariate Analysis'}}/>
                        </>
                    )}
                </PopupLayout>
            )}
        </>
    );
}

export default MultivariateAnalysis;