import { Box, Button, Input, useForceUpdate } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { PopupLayout } from '../../PopupLayout/PopupLayout';

const EquipmentPropertyPopup = ({ closeEquipmentPropPopup, element_Id, equipment, element_View, label_Text, startPos, updateLinks }) => {
	const componentRef = useRef(null);
  const forceUpdate = useForceUpdate();

  return (
    <PopupLayout
    title={label_Text}
    closeFunction={() => closeEquipmentPropPopup(element_Id)} 
    pageX={startPos.x}
    pageY={startPos.y}
    componentRef={componentRef}>
      <Box className='stream-property-bottom-half'>
        {equipment.attributes.components && (Object.entries(equipment.attributes.components).map(([key, value], index) => {
          if (!equipment.attributes.RX_components || !equipment.attributes.RX_components[key]) {
            return (
              <React.Fragment key={key + index}>
                <Box className='stream-property-label' color="black" fontSize={"15"}>{key}</Box>
                <Input
                  className='stream-property-input'
                  placeholder='Value'
                  id={element_Id + key + 'Value_Input'}
                  value={value.M}
                  readOnly
                />
                <Input
                  className='stream-property-input'
                  placeholder='Units'
                  id={element_Id + key + 'Units_Input'}
                  value={"g/Mol"}
                  readOnly
                  disabled={true}
                />
              </React.Fragment>
            )
          }
        }))}
        {equipment.attributes.RX_components && (Object.entries(equipment.attributes.RX_components).map(([key, value], index) => (
          <React.Fragment key={key + index}>
            <Box className='stream-property-label' color="black" fontSize={"15"}>{key}</Box>
            <Input
              className='stream-property-input'
              placeholder='Value'
              id={element_Id + key + 'Value_Input'}
              value={value.M}
              readOnly
            />
            <Input
              className='stream-property-input'
              placeholder='Units'
              id={element_Id + key + 'Units_Input'}
              value={"g/Mol"}
              readOnly
              disabled={true}
            />
          </React.Fragment>
        )))}
      </Box>
        </PopupLayout>

    // <PopupLayout
    // pageX={startPos.x}
    // pageY={startPos.y}
    // title={label_Text}
    // closeFunction={() => closeEquipmentPropPopup(element_Id)}
    // minWidth="300px"
    // componentRef={componentRef}
    // >
    //   <Box>Hello</Box>
    // </PopupLayout>

    // <div
    //   id={element_Id + 'PropertyDiv'}
    //   className="stream-property-div"
		// 	position="absolute"
		// 	style={{transform: `translate(${position.x}px, ${position.y}px)`}}
    //   onMouseMove={isDragging ? onMouseMove : null}
    //   onMouseUp={isDragging ? onMouseUp : null}
		// 	ref={componentRef}
    // >
    //   <div
    //     className="stream-property-div-header"
    //     id={element_Id + 'PropertyDiv' + 'header'}
    //     onMouseDown={onMouseDown}
    //   >
    //     {label_Text}
    //   </div>
    //   <button
    //     className="close-stream-property-button"
    //     style={{ color: "red" }}
    //     id={element_Id + 'PropertyDiv' + "hide"}
    //     onClick={() => closeEquipmentPropPopup(element_Id)}
    //   >
    //     X
    //   </button>
    //   <div className='stream-property-bottom-half'>
    //     {equipment.attributes.components && (Object.entries(equipment.attributes.components).map(([key, value], index) => {
    //       if (!equipment.attributes.RX_components || !equipment.attributes.RX_components[key]) {
    //         return (
    //           <React.Fragment key={key + index}>
    //             <div className='stream-property-label'>{key}</div>
    //             <input
    //               className='stream-property-input'
    //               placeholder='Value'
    //               id={element_Id + key + 'Value_Input'}
    //               value={value.M}
    //               readOnly
    //             />
    //             <input
    //               className='stream-property-input'
    //               placeholder='Units'
    //               id={element_Id + key + 'Units_Input'}
    //               value={"g/Mol"}
    //               readOnly
    //             />
    //             <button
    //               className='stream-property-button'
    //               style={{ color: "red" }}
    //               id={element_Id + 'Remove_Button'}
    //               onClick={() => {
    //                 delete equipment.attributes['components'][key]
    //                 updateLinks(equipment, key)
    //                 updateLinks(equipment)
    //                 forceUpdate()
    //               }}
    //             >
    //               X
    //             </button>
    //           </React.Fragment>
    //         )
    //       }
    //     }))}
    //     {equipment.attributes.RX_components && (Object.entries(equipment.attributes.RX_components).map(([key, value], index) => (
    //       <React.Fragment key={key + index}>
    //         <div className='stream-property-label'>{key}</div>
    //         <input
    //           className='stream-property-input'
    //           placeholder='Value'
    //           id={element_Id + key + 'Value_Input'}
    //           value={value.M}
    //           readOnly
    //         />
    //         <input
    //           className='stream-property-input'
    //           placeholder='Units'
    //           id={element_Id + key + 'Units_Input'}
    //           value={"g/Mol"}
    //           readOnly
    //         />
    //         <button
    //           className='stream-property-button'
    //           style={{ color: "red" }}
    //           id={element_Id + 'Remove_Button'} 
    //           onClick={() => {
    //             delete equipment.attributes['RX_components'][key]
    //             updateLinks(equipment, key)
    //             updateLinks(equipment)
    //             forceUpdate()
    //           }}
    //         >
    //           X 
    //         </button>
    //       </React.Fragment>
    //     )))}
    //   </div>
    // </div>
  );
};

export default EquipmentPropertyPopup;
