import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, Card, Flex, Heading, Image, Input, Select, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaMinus, FaPlus } from 'react-icons/fa'
import FlowireAPI from '../../flowireapi/FlowireAPI'
import { InfoIcon } from '@chakra-ui/icons'

const OrganizationPermissions = () => {
  const [addedOrganizations, setAddedOrganizations] = useState([])
  const {isAuthenticated, getIdTokenClaims} = useAuth0()
  const [orgId, setOrgId] = useState("")
  const [readWrite, setReadWrite] = useState("r")

  const handleOrgIdChange = (e) => {
    setOrgId(e.target.value)
  }

  const handleSelectChange = (e) => {
    setReadWrite(e.target.value)
  }

  const fetchAddedOrganizations = async () => {
    await FlowireAPI.authGet('/api/organizations', getIdTokenClaims, isAuthenticated).then((data) => setAddedOrganizations(data))
  }

  useEffect(() => {
    fetchAddedOrganizations()
  }, [isAuthenticated])

  const addOrg = async () => {
    await FlowireAPI.authPost('/api/organizations/add', {
      orgId: orgId,
      readwrite: readWrite
    }, getIdTokenClaims, isAuthenticated).then((data) => fetchAddedOrganizations())
  }

  const removeOrg = async () => {
    await FlowireAPI.authPost('/api/organizations/remove', {
      orgId: orgId
    }, getIdTokenClaims, isAuthenticated).then((data) => fetchAddedOrganizations())
  }

  return(
    <Box>
      <Flex alignItems="center" mb={2}>
        <InfoIcon mr={2} />
        <Heading as='h6' size='sm' style={{ fontStyle: 'italic', fontWeight: 'normal' }}>
          Input organization ID to grant them permissions for all of your projects:
        </Heading>
      </Flex>
      <Flex>
        <Input
          placeholder="Organization ID"
          size="lg"
          onChange={handleOrgIdChange}
        />
        <Select size="lg" value={readWrite} onChange={handleSelectChange}>
          <option value="r">Read</option>
          <option value="w">Edit</option>
        </Select>
        <Button
          ml={2}
          p={4}
          backgroundColor="blue.500"
          color="white"
          onClick={addOrg}
          size="lg"
        >
          <FaPlus size={20} />
        </Button>
        <Button
          ml={2}
          p={4}
          backgroundColor="blue.500"
          color="white"
          onClick={removeOrg}
          size="lg"
        >
          <FaMinus size={20} />
        </Button>
      </Flex>

      <Flex flexWrap="wrap">
        {addedOrganizations.map((org, index) => (
          <Card key={index} width="200px" height="140px" m={4}>
            {org.logoURL ? (
              <Image
                height="50"
                src={org.logoURL}
                alt={org.displayName}
              />
            ) : (<></>
            )}
            <Text mt={2} fontWeight="bold">
              {org.displayName}
            </Text>
          </Card>
        ))}
      </Flex>
    </Box>
  )
}

export default OrganizationPermissions
