import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, CloseButton, Flex } from '@chakra-ui/react';
import { ResponsiveLine } from '@nivo/line';
import {PopupLayout} from "../../../../PopupLayout/PopupLayout";

const NPVGraphPopup = (props) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [data, setData] = useState([]);
  const [previousMousePosition, setPreviousMousePosition] = useState({ x: 0, y: 0 });
  const componentRef = useRef(null);
  let componentWidth = componentRef.current ? componentRef.current.offsetWidth : 0;
  let componentHeight = componentRef.current ? componentRef.current.offsetHeight : 0;

  useEffect(() => {
    if (props.isOpen) {
      setGraphSettings();
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }
  
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  const handleMouseUp = () => {
    setIsDragging(false);
  }

  const handleMouseDown = (event) => {
    setPreviousMousePosition({ x: event.clientX, y: event.clientY });
    setIsDragging(true);
  }

  const handleMouseMove = (event) => {
    event.preventDefault();
    if (isDragging) {
      const newPosition = {
        x: Math.min(Math.max(position.x + (event.clientX - previousMousePosition.x), 0), window.innerWidth - componentWidth),
        y: Math.min(Math.max(position.y + (event.clientY - previousMousePosition.y), 0), window.innerHeight - componentHeight)
      };
      setPosition(newPosition);
      setPreviousMousePosition({ x: event.clientX, y: event.clientY });
    }
  }

  const setGraphSettings = () => {
    const newData = [{
      "id": "NPV",
      "color": "hsl(406, 70%, 50%)",
      "data": []
    }]
    setData(newData);

    for (let i = 0; i < props.econVars.discountedCashflowCalculated.length; i++) {
      const item = {
        x: props.econVars.yearsTArray[i],
        y: props.econVars.discountedCashflowCalculated[i]
      };
      setData(prevData => {
        prevData[0]['data'].push(item);
        return prevData;
      });
    }
  }

  return (
    <>
      <div className='npv-graph-popup-container'>
        {props.isOpen && (
            <PopupLayout componentRef={componentRef} minWidth="500px" minHeight="500px"
                         title="Discounted Cash Flow Over Time"
                         closeFunction={props.onClose} overflow={false}
                         pageX={window.innerWidth/2} pageY={window.innerHeight/2}>
              <div style={{width: '480px', height: '400px'}}>
                <ResponsiveLine
                    data={data}
                    margin={{top: 50, right: 110, bottom: 110, left: 100}}
                    xScale={{type: 'linear', min: 'auto', max: 'auto'}}
                    yScale={{
                      type: 'linear',
                      min: 'auto',
                      max: 'auto',
                      stacked: false,
                      reverse: false
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                      orient: 'bottom',
                      tickValues: 'every 1 year',
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: 'Year',
                      legendOffset: 36,
                      legendPosition: 'middle'
                    }}
                    axisLeft={{
                      orient: 'left',
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: 'USD',
                      legendOffset: -80,
                      legendPosition: 'middle'
                    }}
                    colors={{scheme: 'nivo'}}
                    pointSize={10}
                    pointColor={{theme: 'background'}}
                    pointBorderWidth={2}
                    pointBorderColor={{from: 'serieColor'}}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    legends={[
                      {
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 100,
                        translateY: 0,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemBackground: 'rgba(0, 0, 0, .03)',
                              itemOpacity: 1
                            }
                          }
                        ]
                      }
                    ]}
                />
              </div>
            </PopupLayout>
        )}
      </div>
    </>
  )
}

export default React.forwardRef((props, ref) => NPVGraphPopup(props,ref));