import '@clientio/rappid/rappid.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import {ChakraProvider, extendTheme} from '@chakra-ui/react';
import {Auth0Provider} from '@auth0/auth0-react';
import {ToastContainer} from 'react-toastify';
import * as ReactDOM from 'react-dom/client';
import { MultiSelectTheme } from 'chakra-multiselect';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

const theme = extendTheme({
    colors: {
        'dark-blue': '#1953A2',
        'black': '#4F4F4F',
        blue: {
            500: '#2F80ED',
            600: '#1953a2'
        }
    },
    components: {
        MultiSelect: MultiSelectTheme
    }
});

root.render(
    <Auth0Provider
        domain="auth.flowire.com.au"
        clientId="d1KnolrhzawfEZ6hKC2PfRrQ8XfKPPnb"
        authorizationParams={{
            redirect_uri: `${window.location.origin}/callback`
        }}
        cacheLocation="memory"
    >
        <ChakraProvider theme={theme}>
            <App/>
            <ToastContainer/>
        </ChakraProvider>
    </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
