//import other libaries as needed

//calculate the total output mass balance for a each unit as a function

/*function totalOutputMB(unit, modelId, graph) {
    let totalFlow = 0;

    for (let i = 0; i < unit.attributes['equipment_Properties']['outputStreams'].length; i++) {
        let outputStreams = unit.attributes['equipment_Properties']['outputStreams'][i];
        let streamList = graph.getLinks(modelId);
        console.log("Stream List:", streamList); // Log to inspect streamList
        console.log("Output Streams:", outputStreams); // Log to inspect outputStreams

        for (let j = 0; j < outputStreams.length; j++) {
            let result = streamList.find(item => item.attributes && item.attributes.CustomName === parseInt(outputStreams[j]));
            console.log("Result for stream", outputStreams[j], ":", result); // Log to inspect each result

            if (result && result.attributes['complete_Stream_Properties']) {
                let specificFlow = result.attributes['complete_Stream_Properties']['totalFlow'];
                totalFlow += specificFlow;
                console.log(specificFlow)
            } else {
                console.log("No matching stream found for", outputStreams[j]);
            }
        }
    }
    console.log(totalFlow)

    return totalFlow;
}*/

function totalOutputMB(unit, modelId, graph) {
    let totalFlow = 0;

    for (let i = 0; i < unit.attributes['equipment_Properties']['outputStreams'].length; i++) {
        let outputStreamIndex = parseInt(unit.attributes['equipment_Properties']['outputStreams'][i], 10);
        let streamList = graph.getLinks(modelId);

        let result = streamList.find(item => item.attributes && parseInt(item.attributes.CustomName, 10) === outputStreamIndex);

        if (result && result.attributes['complete_Stream_Properties']) {
            let specificFlow = parseFloat(result.attributes['complete_Stream_Properties']['totalFlow']);
            totalFlow += specificFlow;
        }
    }
    return totalFlow;
}

export default totalOutputMB;
