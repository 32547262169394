import React, {useEffect, useRef, useState} from 'react';
import {Button, Flex, FormLabel, Input, InputGroup, InputRightElement} from '@chakra-ui/react';
import './model.css'
import {useAuth0} from '@auth0/auth0-react';
import {toast} from 'react-toastify';
import {PopupLayout} from "../../../../PopupLayout/PopupLayout";

const PropertyPopup = (props) => {
    const {isAuthenticated} = useAuth0()
    const componentRef = useRef(null);
    const [state, setState] = useState({
        keyProductStreamValue: "",
        economicPotentialValue: "",
        capexScaleValue: "",
        opexScaleValue: "",
        interestRateValue: "",
        yearsValue: "",
        NPVValue: "",
        LCOPValue: "",
        elecCostValue: "",
        installationValue: "",
        commissioningValue: "",
        additionalOpexValue: "",
        additionalCapexValue: "",
        totalOpexValue: "",
        totalCapexValue: "",
        totalInstalledValue: "",
        totalProductRevValue: "",
        totalFeedstockCostValue: "",
        totalWaterUsageValue: "",
        totalEmissionsValue: "",
        totalWasteValue: "",
    });
    const allowedVars = [
        'keyProductStreamValue',
        'capexScaleValue',
        'opexScaleValue',
        'interestRateValue',
        'yearsValue',
        'elecCostValue',
        'installationValue',
        'commissioningValue',
        'additionalOpexValue',
        'additionalCapexValue',
        'totalWaterUsageValue',
        'totalEmissionsValue',
        'totalWasteValue',
    ];

    useEffect(() => {
        doReq();
        props.setGetEconArraysF(getEconArrays())
    }, [isAuthenticated])

    const doReq = async () => {
        let [totalOpex, totalCapex, productCostSum, feedCostSum, capexSum] = await props.simulateEconVars(props.graph)
        let econVars = props.econVars

        setState({
            keyProductStreamValue: econVars.keyProductStream,
            economicPotentialValue: econVars.economicPotential,
            capexScaleValue: econVars.capexScaleValue,
            opexScaleValue: econVars.opexScaleValue,
            interestRateValue: econVars.interestRate,
            yearsValue: econVars.years,
            NPVValue: econVars.npvCalculated,
            LCOPValue: econVars.LCOP,
            elecCostValue: econVars.elecCost,
            installationValue: econVars.installation,
            commissioningValue: econVars.commissioning,
            additionalOpexValue: econVars.additionalOpex,
            additionalCapexValue: econVars.additionalCapex,
            totalOpexValue: totalOpex,
            totalCapexValue: capexSum,
            totalInstalledValue: totalCapex, //new value
            totalProductRevValue: productCostSum,
            totalFeedstockCostValue: feedCostSum,
            totalWaterUsageValue: econVars.totalWaterUsage,
            totalEmissionsValue: econVars.totalEmissions,
            totalWasteValue: econVars.totalWaste,
        });
    }

    const handleChange = (e, key) => {
        const {name, value} = e.target;
        const parsedValue = (value && !isNaN(parseFloat(value))) ? parseFloat(value) : 0;

        if (allowedVars.includes(key)) {
            setState({[key]: value});
            const mapping = {
                'keyProductStreamValue': 'keyProductStream',
                'capexScaleValue': 'capexScaleValue',
                'opexScaleValue': 'opexScaleValue',
                'interestRateValue': 'interestRate',
                'yearsValue': 'years',
                'elecCostValue': 'elecCost',
                'installationValue': 'installation',
                'commissioningValue': 'commissioning',
                'additionalOpexValue': 'additionalOpex',
                'additionalCapexValue': 'additionalCapex',
                'totalWaterUsageValue': 'totalWaterUsage',
                'totalEmissionsValue': 'totalEmissions',
                'totalWasteValue': 'totalWaste',
            };

            if (mapping[key]) {
                props.econVars[mapping[key]] = parsedValue;
            }
        }
    };

    const handleKeyDown = (event, key) => {
        if (allowedVars.includes(key)) {
            if (event.key === 'Enter')
                doReq();
        }
    }

    const handleCapexTotalClick = (e, name) => {
        props.openCapexGraph();
    }

    const handleNPVClick = (e, name) => {
        props.openNPVGraph();
    }

    const handleOpexTotalClick = (e, name) => {
        props.openOpexGraph();
    }
    
    const renderInputField = (name, buttonHandler, suffix) => {
        let labelName = name.charAt(0).toUpperCase() + name.slice(1).replace(/Value$/, '').replace(/([A-Z])/g, ' $1');
        return (
            <div key={name}>
                <Flex
                    bgColor="#fff"
                    p={2}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    {buttonHandler ?
                        <Button size="sm" fontSize=".9rem"
                                mr={1}
                                variant='solid'
                                style={{width: '70%'}} textAlign='left' display='inline'
                                cursor={"pointer"}
                                onClick={buttonHandler}
                                _hover={{bg: 'blue.100'}}>
                            {labelName}
                        </Button>
                        :
                        <FormLabel size="md" fontSize=".9rem" htmlFor={`input_${name}`}
                                   style={{width: '70%'}} textAlign='left' display='inline'>
                            {labelName}
                        </FormLabel>
                    }
                    <InputGroup width="110%">
                        {suffix ? <InputRightElement pointerEvents="none" color="gray.600" background="gray.200"
                                                     fontWeight={600} width='auto' minWidth='var(--input-height)'
                                                     pl={1} pr={1}
                                                     fontSize=".9rem" borderTopRightRadius={8}
                                                     borderBottomRightRadius={8}
                                                     children={suffix}/> : <></>}
                        <Input
                            id={`input_${name}`}
                            value={state[name]}
                            onChange={(e) => handleChange(e, name)}
                            onKeyDown={(e) => handleKeyDown(e, name)}
                            isDisabled={!allowedVars.includes(name)}
                            fontSize="small"
                        />
                    </InputGroup>
                </Flex>
            </div>
        );
    }

    const getEconArrays = () => {
        return [updateEconArrays, props.graph, props.solved_Dictionary, props.econVars]
    }

    const updateEconArrays = (graph, solved_Dictionary, econVars) => {
        let allEquipment = graph.getElements()
        //add together all element
        /*if(){
            let allEquipment = graph.getElements()
            console.log(allEquipment)
        }*/
        //initialise the sum arrrays
        let opexSum = 0
        let capexSum = 0
        let feedCostSum = 0
        let productCostSum = 0
        let streamEP = 0
        let equipNameArray = [];
        let capexArrayOG = [];
        let opexArrayOG = [];

        for (let i = 0; i < allEquipment.length; i++) {
            let streamsList = graph.getLinks()

            if (allEquipment[i].prop("isUnit") == "yes") {
                if (allEquipment[i].attributes['equipment_Properties']['unitType'] == 'feed' || allEquipment[i].attributes['equipment_Properties']['unitType'] == 'Feed') {
                    if ((allEquipment[i].attributes['equipment_Economics']) && (allEquipment[i].attributes['equipment_Economics']['Cost'])) {
                        //console.log("Calculate Feed EP is running")
                        //console.log(props)
                        streamEP = props.calculateFeedEP(graph, allEquipment[i])
                        feedCostSum += streamEP
                    } else {
                        //feedCostSum += 0 //if it doesn't exist, don't add anything
                    }
                } else if (allEquipment[i].attributes['equipment_Properties']['unitType'] == 'product' || allEquipment[i].attributes['equipment_Properties']['unitType'] == 'Product') {
                    if ((allEquipment[i].attributes['equipment_Economics']) && (allEquipment[i].attributes['equipment_Economics']['Cost'])) {
                        //productCostSum += parseInt(allEquipment[i].attributes['equipment_Economics']['Cost']['Value'])
                        let prodCost = parseFloat(allEquipment[i].attributes['equipment_Economics']['Cost']['Value'])
                        //inputStream = allEquipment[i].getConnectedLinks() //this should only return a list of 1 because it's feed or product
                        let inputStream = graph.getConnectedLinks(allEquipment[i])
                        //console.log(inputStream + "is the input stream")
                        let totalFlow = inputStream[0].attributes['complete_Stream_Properties']['totalFlow'] //total flow is in kg
                        let streamEP = totalFlow * prodCost
                        productCostSum += streamEP
                    } else {
                        //productCostSum += 0  do nothing
                    }
                } else {
                    if (allEquipment[i].attributes['equipment_Economics']) {
                        opexSum += parseFloat(allEquipment[i].attributes['equipment_Economics']['Opex']['Value'])
                        capexSum += parseFloat(allEquipment[i].attributes['equipment_Economics']['Capex']['Value'])
                        //marry the array with names using the
                        //capexArrayOG.push(allEquipment[i].attributes['equipment_Economics']['Capex'][''])
                        //capexArrayOG.push(allEquipment[i].attributes['equipment_Economics']['Opex'][''])
                        //console.log(capexArrayOG)
                        // Update the arrays in the loop
                        let equipName = allEquipment[i].attributes.attrs.shapename.innerHTML.replace(/<p>|<\/p>/g, '').includes('<br>') ? allEquipment[i].attributes.unitType : allEquipment[i].attributes.attrs.shapename.innerHTML.replace(/<p>|<\/p>/g, '');
                        let capexValue = allEquipment[i].attributes['equipment_Economics']['Capex']['Value'];
                        let opexValue = allEquipment[i].attributes['equipment_Economics']['Opex']['Value'];
                        equipNameArray.push(equipName);
                        capexArrayOG.push(capexValue);
                        opexArrayOG.push(opexValue);
                    } else {
                        opexSum += 0
                        capexSum += 0
                    }
                }
            }
        }
        let equipArray = [equipNameArray, capexArrayOG, opexArrayOG];

        let keyFeedNo = solved_Dictionary['streams'][Number(econVars.keyFeedStream)]['totalFlow']
        let streamEPNorm;
        if (keyFeedNo !== null) {
            streamEPNorm = streamEP / keyFeedNo
        } else {
            toast.error("Please specify all total flow values to calculate Economic Potential.")
        }
        return [equipArray, opexSum, capexSum, streamEPNorm, feedCostSum, productCostSum]
    }

    return (
        <>
            <PopupLayout closeFunction={props.closeFunction} ref={props.ref} pageX={0} pageY={0}
                         maxHeight={500} title="Analysis Results" componentRef={componentRef}>
                {['keyProductStreamValue', 'economicPotentialValue', 'capexScaleValue', 'opexScaleValue', 'interestRateValue', 'yearsValue', 'NPVValue'].map(name => (
                    renderInputField(name, name === 'NPVValue' ? handleNPVClick : undefined)
                ))}
                {['LCOPValue'].map(name => (
                    renderInputField(name, undefined, '$/T')
                ))}
                {['elecCostValue'].map(name => (
                    renderInputField(name, undefined, '$/MWh')
                ))}
                {/* Fields with % suffix */}
                {['installationValue', 'commissioningValue'].map(name => (
                    renderInputField(name, undefined, '%')
                ))}
                {/* Fields with USD suffix */}
                {['additionalOpexValue', 'additionalCapexValue', 'totalOpexValue', 'totalCapexValue', 'totalInstalledValue', 'totalProductRevValue', 'totalFeedstockCostValue', 'totalWaterUsageValue', 'totalEmissionsValue', 'totalWasteValue'].map(name => (
                    renderInputField(name, 
                        name === 'totalOpexValue' ? handleOpexTotalClick : 
                        name === 'totalCapexValue' ? handleCapexTotalClick : 
                        undefined,
                        name === 'totalOpexValue' || name === 'additionalOpexValue' || name === 'totalProductRevValue' || name === 'totalFeedstockCostValue' ? '$/Annum' :
                        name === 'totalWaterUsageValue' ? 'kL' : 
                        name === 'totalEmissionsValue' ? 'CO2etonnes' : 
                        name === 'totalWasteValue' ? 'tonnes' : 
                        '$' )
                ))}
            </PopupLayout>
        </>
    )
}

export default React.forwardRef((props, ref) => PropertyPopup(props, ref));
