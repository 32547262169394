import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Center, Heading } from '@chakra-ui/react';
import FloLoading from '../FloLoading/FloLoading';

const Callback = () => {
  const { isAuthenticated, isLoading, error } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect the user back to the originally requested route (if authenticated)
    if (isAuthenticated) {
      const returnUrl = sessionStorage.getItem('returnUrl');
      sessionStorage.removeItem('returnUrl');
      
      // Check if returnUrl is a full URL with the domain
      const isFullUrl = returnUrl && returnUrl.startsWith(window.location.origin);
      
      if (isFullUrl) {
        // If it's a full URL, extract the relative path
        const relativePath = returnUrl.replace(window.location.origin, '');
        navigate(relativePath || '/');
      } else {
        // If it's just a relative path, navigate to it directly
        navigate(returnUrl || '/');
      }
    }
  }, [isAuthenticated, navigate]);

  if (isLoading) {
    return <FloLoading />;
  }

  if (error) {
    return (
      <Center h="100vh">
      <Box textAlign="center">
        <Heading size="xl" mb={4}>
          Login Failed {error.message}
        </Heading>
        <Button colorScheme="blue" onClick={() => navigate("/")}>
          Go Home
        </Button>
      </Box>
    </Center>
    );
  }

  return null;
};

export default Callback;
