import React, { useEffect, useState } from 'react';
import {
  Card,
  Button,
  Flex, Heading,
} from '@chakra-ui/react';
import { ResponsiveLine } from '@nivo/line';

const OverallNPVGraph = ({ sankeyGraphId, setSankeyData, graphData }) => {
  const [zoomedX, setZoomedX] = useState(null);
  const [zoomedY, setZoomedY] = useState(null);
  const [animateGraph, setAnimateGraph] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {
    const rawData = [
      {
        id: 'ResetZoom',
        color: 'hsl(0,0,0)',
        data: [],
      },
      ...graphData.map((item, index) => ({
        id: `${item.name}`,
        color: `hsl(${(index * 50) % 360}, ${70 + (index * 10) % 30}%, ${50 + (index * 10) % 30}%)`,
        data: item.yearsTArray.map((year, i) => ({
          x: year,
          y: item.discountedCashflowCalculated[i],
        })),
      })),
    ]
    setData(rawData)
  }, [graphData])

  const [scaleData, setScaleData] = useState(data);

  const handleLineClick = (lineData) => {
    if (lineData && lineData.id) {
        if (lineData.id === 'ResetZoom') {
          setAnimateGraph(false)
          setZoomedX(null);
          setZoomedY(null);
          setSankeyData(null)

          setScaleData(data);
        } else {
          setAnimateGraph(false)
          const lineId = lineData.id.replace(/\..*/, '');
          setSankeyData(lineId)
          const clickedLineData = graphData.find(item => item.name === lineId);

          const xMin = Math.min(...clickedLineData.yearsTArray);
          const xMax = Math.max(...clickedLineData.yearsTArray);
          const yMin = Math.min(...clickedLineData.discountedCashflowCalculated);
          const yMax = Math.max(...clickedLineData.discountedCashflowCalculated);

          setZoomedX({ min: xMin, max: xMax });
          setZoomedY({ min: yMin, max: yMax });

          const filteredData = data.map(line => {
            if(!((line.id == lineId.replace(/\..*/, '')) || line.id === "ResetZoom")){
              return {
                ...line,
                data: []
              }
            } else {
              return line
            }
          })

          setScaleData(filteredData);
        }
      }
  };

  useEffect(() => {
    setAnimateGraph(false)
    setZoomedX(null);
    setZoomedY(null);
    setSankeyData(null)
    setScaleData(data);
  }, [data])

  useEffect(() => {
    setAnimateGraph(true)
  }, [zoomedX, zoomedY, scaleData])

  return (
    <div height="500px">
      <Card
        width="100%"
        height="450"
        boxShadow="md"
        bgColor="white"
      >
        <Heading size="sm" m={8}>
          Discounted Cash Flow Over Time
        </Heading>

        <ResponsiveLine
        animate={animateGraph}
          data={scaleData}
          height="400"

          margin={{ top: 50, right: 210, bottom: 110, left: 100 }}
          xScale={{
            type: 'linear',
            min: zoomedX ? zoomedX.min : 'auto',
            max: zoomedX ? zoomedX.max : 'auto',
          }}
          yScale={{
            type: 'linear',
            min: zoomedY ? zoomedY.min : 'auto',
            max: zoomedY ? zoomedY.max : 'auto',
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: 'bottom',
            tickValues: 'every 1 year',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Year',
            legendOffset: 36,
            legendPosition: 'middle',
          }}
          axisLeft={{
            orient: 'left',
            tickSize: 10,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Revenue',
            legendOffset: -80,
            legendPosition: 'middle',
          }}
          colors={d => d.color}
          pointSize={10}
          pointColor={{ theme: 'background' }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabelYOffset={-12}
          onClick={handleLineClick}
          useMesh={true}
          legends={[
            {
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1,
                  },
                },
              ],
              onClick: handleLineClick,
            },
          ]}
        />
      </Card>
    </div>
  );
};

export default OverallNPVGraph;
