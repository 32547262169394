import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    Flex,
    FormControl,
    Heading,
    HStack,
    Input,
    InputGroup,
    InputRightElement,
    VStack,
    Divider,
    Checkbox,
} from '@chakra-ui/react';
import {useAuth0} from '@auth0/auth0-react';
import OverallNPVGraph from './OverallNPVGraph';
import OverallSankeyGraph from './OverallSankeyGraph';
import FlowireAPI from '../../flowireapi/FlowireAPI';
import {FaWandMagicSparkles} from "react-icons/fa6";
import { MultiSelect } from 'chakra-multiselect';

const Assumptions = (props) => {
    const currency = props.currency ?? 'USD';
    const currencies = props.currencies ?? {
        USD: {
            symbol: "$",
            name: 'USD',
            rateToUSD: 1,
        },
        AUD: {
            symbol: "A$",
            name: 'AUD',
            rateToUSD: 0.73,
        },
    };
    const convertCurrency = (value) => (value * currencies[currency].rateToUSD);


    const [electricity, setElectricity] = useState('0');
    const [interestRate, setInterestRate] = useState('0.06');
    const [taxRate, setTaxRate] = useState('0');
    const [years, setYears] = useState('20');
    const {isAuthenticated, getIdTokenClaims} = useAuth0()
    const [npvGraphData, setNPVGraphData] = useState([])
    const [totalRevenue, setTotalRevenue] = useState("0.00")
    const [totalAssets, setTotalAssets] = useState("0.00")
    const [totalUnallocatedFunds, setTotalUnallocatedFunds] = useState("0.00")
    const [showSankeyGraph, setShowSankeyGraph] = useState(false)
    const [sankeyGraphData, setSankeyGraphData] = useState(null)
    const [sankeyGraphId, setSankeyGraphId] = useState(null)

    const [convertedElectricity, setConvertedElectricity] = useState('0');
    const [convertedTotalRevenue, setConvertedTotalRevenue] = useState('0');
    const [convertedTotalAssets, setConvertedTotalAssets] = useState('0');
    const [convertedTotalUnallocatedFunds, setConvertedTotalUnallocatedFunds] = useState('0');
    const [convertedNpvGraphData, setConvertedNpvGraphData] = useState([]);
    const [convertedSankeyGraphData, setConvertedSankeyGraphData] = useState({});

    const setSankeyData = (id) => {
        setSankeyGraphId(id)

        // Find the right object in npvGraphData by name
        const targetData = npvGraphData.find(item => item.name === id);

        if (targetData) {

            const data = {
                cashflowSum: targetData.cashflowSum,
                opex: targetData.opexSum,
                feed: targetData.feedCost,
                productRev: targetData.productRev,
                opexFeed: targetData.opexSum + targetData.feedCost
            }

            setSankeyGraphData(data)
            setShowSankeyGraph(true)
        } else {
            setSankeyGraphData(null)
            setShowSankeyGraph(false)
        }
    }

    const handleRAUKeyDown = (e) => {
        if (e.key == "Enter") {
            FlowireAPI.authPost('/api/econ/totals', {
                "totalRevenue": totalRevenue,
                "totalAssets": totalAssets,
                "totalUnallocatedFunds": totalUnallocatedFunds
            }, getIdTokenClaims, isAuthenticated)
        }
    }

    useEffect(() => {
        FlowireAPI.authGet('/api/econ/gettotals', getIdTokenClaims, isAuthenticated).then((data) => {
            if (!data) return;
            setTotalRevenue(data.totalRevenue)
            setTotalAssets(data.totalAssets)
            setTotalUnallocatedFunds(data.totalUnallocatedFunds)
            setConvertedTotalRevenue(convertCurrency(parseFloat(parseFloat(parseFloat(data.totalRevenue).toFixed(2))).toString()))
            setConvertedTotalAssets(convertCurrency(parseFloat(parseFloat(parseFloat(data.totalAssets).toFixed(2))).toString()))
            setConvertedTotalUnallocatedFunds(convertCurrency(parseFloat(parseFloat(parseFloat(data.totalUnallocatedFunds).toFixed(2))).toString()))
        })
    }, [isAuthenticated])

    const handleRevenueChange = (e) => {
        setTotalRevenue((parseFloat(e.target.value) / currencies[currency].rateToUSD).toFixed(2))
        setConvertedTotalRevenue(e.target.value)
    }

    const handleAssetsChange = (e) => {
        setTotalAssets((parseFloat(e.target.value) / currencies[currency].rateToUSD).toFixed(2))
        setConvertedTotalAssets(e.target.value)
    }

    const handleUnallocatedFundsChange = (e) => {
        setTotalUnallocatedFunds((parseFloat(e.target.value) / currencies[currency].rateToUSD).toFixed(2))
        setConvertedTotalUnallocatedFunds(e.target.value)
    }

    const handleElectricityChange = (e) => {
        setElectricity((parseFloat(e.target.value) / currencies[currency].rateToUSD).toFixed(2));
        setConvertedElectricity(e.target.value)
    };

    const handleInterestRateChange = (e) => {
        setInterestRate(e.target.value);
    };

    const handleTaxRateChange = (e) => {
        setTaxRate(e.target.value);
    };

    const handleYearsChange = (e) => {
        setYears(e.target.value);
    };

    useEffect(() => {
        setConvertedElectricity(convertCurrency(parseFloat(electricity)).toFixed(2))
        setConvertedTotalRevenue(convertCurrency(parseFloat(parseFloat(totalRevenue))).toFixed(2))
        setConvertedTotalAssets(convertCurrency(parseFloat(parseFloat(totalAssets))).toFixed(2))
        setConvertedTotalUnallocatedFunds(convertCurrency(parseFloat(parseFloat(totalUnallocatedFunds))).toFixed(2))
    }, [currency])

    useEffect(() => {
        const newConvertedSankeyGraphData = {};
        for (let item in sankeyGraphData) {
            newConvertedSankeyGraphData[item] = convertCurrency(sankeyGraphData[item]);
        }
        setConvertedSankeyGraphData(newConvertedSankeyGraphData);
    }, [currency, sankeyGraphData])

    useEffect(() => {
        const newConvertedNpvGraphData = npvGraphData.map(item => {
            const newItem = {...item};
            newItem.discountedCashflowCalculated = item.discountedCashflowCalculated.map(value =>
                convertCurrency(parseFloat(value.toFixed(2)))
            );
            return newItem;
        });

        setConvertedNpvGraphData(newConvertedNpvGraphData);
    }, [currency, npvGraphData])

    const fetchAssumptionGraphValues = async () => {
        if(selectedGroups.length === 0 && !showAll) return;
        if(showAll) {
            await FlowireAPI.authPost('/api/econ/assumptions/npv', {
                "electricity": electricity,
                "interest": interestRate,
                "tax": taxRate,
                "years": years
            }, getIdTokenClaims, isAuthenticated).then((data) => {
                if (data) {
                    setNPVGraphData(data)
                }
            })
        } else {
            await FlowireAPI.authPost('/api/econ/assumptions/npv', {
                "electricity": electricity,
                "interest": interestRate,
                "tax": taxRate,
                "years": years,
                "group_uuids": selectedGroups.map(group => group.value)
            }, getIdTokenClaims, isAuthenticated).then((data) => {
                if (data) {
                    setNPVGraphData(data)
                }
            })
        }
    }

    const handleResimulate = () => {
        fetchAssumptionGraphValues()
    };

    useEffect(() => {
        fetchAssumptionGraphValues()
    }, [isAuthenticated])


    const [groups, setGroups] = useState([])
    const [selectedGroups, setSelectedGroups] = useState([])
    const [showAll, setShowAll] = useState(true)

    useEffect(() => {
        FlowireAPI.authGet('/api/getgroups', getIdTokenClaims, isAuthenticated).then((data) => {
            if (data) {
                setGroups(data.groups)
            }
        })
    }, [isAuthenticated])



    return (
        <>
            <Heading size="md" ml={8}>
                Cash Flow Analysis
            </Heading>

            <Flex ml={8} mr={8} mb={3} mt={3}>
            <Divider />
            </Flex>

            <Heading ml={8} mr={8} size="sm">
                Groups
            </Heading>
            <Flex mt={3} ml={8} mr={8}>
            <Checkbox isChecked={showAll} onChange={() => {
                setShowAll(!showAll)
                setSelectedGroups([])
            }} mr="3" >All</Checkbox>
            <MultiSelect disabled={showAll} options={groups.map(group => { return {id: group.uuid, label: group.name, value: group.uuid} })} value={selectedGroups} onChange={setSelectedGroups}/>
            </Flex>

            <Flex ml={8} mr={8} mb={3} mt={3}>
            <Divider />
            </Flex>


            <Flex direction="row" margin={8}>
                <Card
                    boxShadow="md"
                    width="300px"  // Adjust the width as needed
                    p={4}
                    marginRight={4}
                >
                    <VStack spacing={4}>
                        <FormControl>
                            <Flex alignItems="center">
                                <label htmlFor="revenueInput" style={{width: '70%'}}>Revenue</label>
                                <InputGroup width="60%">
                                    <InputRightElement pointerEvents="none" color="gray.600" background="gray.200"
                                                       fontWeight={600}
                                                       fontSize=".9rem" borderTopRightRadius={8}
                                                       borderBottomRightRadius={8}
                                                       children={currencies[currency].name}/>
                                    <Input
                                        id="revenueInput"
                                        onKeyDown={handleRAUKeyDown}
                                        onChange={handleRevenueChange}
                                        value={convertedTotalRevenue}
                                    />
                                </InputGroup>
                            </Flex>
                        </FormControl>

                        <FormControl>
                            <Flex alignItems="center">
                                <label htmlFor="assetsInput" style={{width: '70%'}}>Assets</label>
                                <InputGroup width="60%">
                                    <InputRightElement pointerEvents="none" color="gray.600" background="gray.200"
                                                       fontWeight={600}
                                                       fontSize=".9rem" borderTopRightRadius={8}
                                                       borderBottomRightRadius={8}
                                                       children={currencies[currency].name}/>
                                    <Input
                                        id="assetsInput"
                                        onKeyDown={handleRAUKeyDown}
                                        onChange={handleAssetsChange}
                                        value={convertedTotalAssets}
                                    />
                                </InputGroup>
                            </Flex>
                        </FormControl>

                        <FormControl>
                            <Flex alignItems="center">
                                <label htmlFor="unallocatedFundsInput" style={{width: '70%'}}>Unallocated Funds</label>
                                <InputGroup width="60%">
                                    <InputRightElement pointerEvents="none" color="gray.600" background="gray.200"
                                                       fontWeight={600}
                                                       fontSize=".9rem" borderTopRightRadius={8}
                                                       borderBottomRightRadius={8}
                                                       children={currencies[currency].name}/>
                                    <Input
                                        id="unallocatedFundsInput"
                                        onKeyDown={handleRAUKeyDown}
                                        onChange={handleUnallocatedFundsChange}
                                        value={convertedTotalUnallocatedFunds}
                                    />
                                </InputGroup>
                            </Flex>
                        </FormControl>

                        <Divider/>

                        <FormControl>
                            <Flex alignItems="center">
                                <label htmlFor="convertedElectricity" style={{width: '70%'}}>Electricity</label>
                                <InputGroup width="60%">
                                    <InputRightElement pointerEvents="none" color="gray.600" background="gray.200"
                                                       fontWeight={600}
                                                       fontSize=".9rem" borderTopRightRadius={8}
                                                       borderBottomRightRadius={8}
                                                       children={currencies[currency].name}/>
                                    <Input id="convertedElectricity" value={convertedElectricity}
                                           onChange={handleElectricityChange}/>
                                </InputGroup>
                            </Flex>
                        </FormControl>

                        <FormControl>
                            <HStack>
                                <label htmlFor="interestRate" style={{width: '70%'}}>Interest Rate</label>
                                <InputGroup width="60%">
                                    <InputRightElement pointerEvents="none" color="gray.600" background="gray.200"
                                                       fontWeight={600}
                                                       fontSize=".9rem" children="%" borderTopRightRadius={8}
                                                       borderBottomRightRadius={8}/>
                                    <Input id="interestRate" value={interestRate} onChange={handleInterestRateChange}/>
                                </InputGroup>
                            </HStack>
                        </FormControl>

                        <FormControl>
                            <Flex alignItems="center">
                                <label htmlFor="taxRate" style={{width: '70%'}}>Tax Rate</label>
                                <InputGroup width="60%">
                                    <InputRightElement pointerEvents="none" color="gray.600" background="gray.200"
                                                       fontWeight={600}
                                                       fontSize=".9rem" children="%" borderTopRightRadius={8}
                                                       borderBottomRightRadius={8}/>
                                    <Input id="taxRate" value={taxRate} onChange={handleTaxRateChange}/>
                                </InputGroup>
                            </Flex>
                        </FormControl>

                        <FormControl>
                            <Flex alignItems="center">
                                <label htmlFor="years" style={{width: '70%'}}>Years</label>
                                <InputGroup width="60%">
                                    <Input id="years" value={years} onChange={handleYearsChange}/>
                                </InputGroup>
                            </Flex>
                        </FormControl>

                        <Button rightIcon={<FaWandMagicSparkles/>} size="md" colorScheme="blue"
                                onClick={handleResimulate}>
                            Simulate
                        </Button>
                    </VStack>
                </Card>

                <Box
                    width="calc(100% - 316px)"
                    borderRadius="8px"
                >
                    <OverallNPVGraph sankeyGraphId={sankeyGraphId} setSankeyData={setSankeyData}
                                     graphData={convertedNpvGraphData}/>
                    {showSankeyGraph && convertedSankeyGraphData && (sankeyGraphId || sankeyGraphId == 0) && (
                        <OverallSankeyGraph graphData={convertedSankeyGraphData}/>)}
                </Box>
            </Flex>
        </>
    );
}

export default Assumptions;
