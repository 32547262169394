import React, { useState, useImperativeHandle } from 'react';
import PropertyPopup from './components/PropertyPopup'

let extraDivsDictionary = {
    'Custom': ['Mass Flow (m3/day)'],
    'Filters - Plate and frame': ['Mass Flow (m3/day)'],
    'Tanks - cone roof':  ['Mass Flow (m3/day)', 'Residence Time (hrs)', 'Operating Level (%)', 'Height (m)'],
    'Tanks + Agitator':  ['Mass Flow (m3/day)', 'Residence Time (hrs)', 'Operating Level (%)', 'Height (m)', 'Agitation Application'],
    'Reactors - Jacketed, agitated': ['Mass Flow (m3/day)', 'Residence Time (hrs)', 'Operating Level (%)', 'Height (m)', 'Agitation Application','Reaction Temperature (°C)'],
    'Extruder - Screw' : ['Mass Flow (m3/day)'],
    // 'Pressure vessels - Vertical': ['S (shell mass- m3)'],
    'Agitators & mixers - Static mixer': ['Mass Flow (m3/day)'],
    'Crushers - Jaw crusher': ['Mass Flow (tonnes/h)'],
    'Crushers - Gyratory crusher': ['Mass Flow (tonnes/h)'],
    'Crushers - Reversible hammer mill': ['Mass Flow (tonnes/h)'],
    'Crushers - Ball mill': ['Mass Flow (tonnes/hr)'],
    'Haulage - Electric Fleet': ['Mass Flow (tonnes/h)'],
    'Haulage - Diesel Fleet': ['Mass Flow (tonnes/h)'],
};

const FinancialPopup = React.forwardRef((props, ref) => {
    const [popups, setPopups] = useState([]);

    useImperativeHandle(ref, () => ({
        popups,
        addFinancialStreamPropertyPopup,
        hideFinancialStreamPropertyPopup,
        openFinancialStreamPropertyPopup,
        closeFinancialStreamPropertyPopup
    }));

    const addFinancialStreamPropertyPopup = (getMADropdownData, graph, modelId, econVars,pageX,pageY, orgSectors) => {
        if(popups){
            const popupExists = popups.some(
                popup => popup.id === modelId
            );
            if(!popupExists){
                setPopups(prevPopups => [...prevPopups, { getMADropdownData: getMADropdownData, id: modelId, isOpen: true, graph, modelId, econVars,pageX,pageY, orgSectors: orgSectors }]);
            }
        }
    }

    const openFinancialStreamPropertyPopup = (id) => {
        if(popups){
            const popupExists = popups.some(
                popup => popup.id === id
            );
            if(popupExists){
                setPopups(prevPopups => prevPopups.map(popup =>
                    popup.id === id ? { ...popup, isOpen: true } : popup
                ));
            }
        }
    }

    const hideFinancialStreamPropertyPopup = (id) => {
        if(popups){
            const popupExists = popups.some(
                popup => popup.id === id
            );
            if(popupExists){
                setPopups(prevPopups => prevPopups.map(popup =>
                    popup.id === id ? { ...popup, isOpen: false } : popup
                ));
            }
        }
    }

    const closeFinancialStreamPropertyPopup = (id) => {
        setPopups(prevPopups => prevPopups.filter(popup => popup.id !== id));
    }

    return (
        <div className="financial-popup-container">
        {popups.map(popup => 
            <PropertyPopup 
            getMADropdownData={popup.getMADropdownData}
            isAuthenticated={props.isAuthenticated}
            getIdTokenClaims={props.getIdTokenClaims}
            key={popup.id}
            isOpen={popup.isOpen}
            onOpen={() => openFinancialStreamPropertyPopup(popup.id)}
            onClose={() => closeFinancialStreamPropertyPopup(popup.id)}
            updateGraphs={props.updateGraphs}
            graph={popup.graph}
            modelId={popup.modelId}
            econVars={popup.econVars}
            reCalculate={props.reCalculate}
            pageX={popup.pageX}
            pageY={popup.pageY}
            orgSectors={popup.orgSectors}
        />
        )}
    </div>
    );
});

export default FinancialPopup;
