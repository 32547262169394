import { Table, Thead, Tbody, Tr, Th, Td, Input, Checkbox, Button, Flex, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, FormControl, FormLabel, Input as ChakraInput, Button as ChakraButton, useToast, Box } from "@chakra-ui/react";
import { useState, useMemo, useEffect } from "react";
import FlowireAPI from "../../flowireapi/FlowireAPI";
import { useAuth0 } from "@auth0/auth0-react";
import { v4 } from 'uuid';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Costing = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [editableData, setEditableData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const { getIdTokenClaims, isAuthenticated } = useAuth0();
  const [tableData, setTableData] = useState([]);
  const [editingRowId, setEditingRowId] = useState(null);
  const toast = useToast();

  const setTableDataFromAPI = () => {
    FlowireAPI.authGet('/api/getchemicals', getIdTokenClaims, isAuthenticated).then((data) => {
      if(data.error){
        toast({
          title: "Error",
          description: data.error,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      const chemicals = data['chemicals'].map((chemical, index) => {
        const date = new Date(chemical.date);

        const day = date.getUTCDate();
        const month = date.getUTCMonth() + 1;
        const year = date.getUTCFullYear();
        const formattedDate = `${day}/${month}/${year}`;

        return {
          ...chemical,
          id: index,
          date: formattedDate,
        };
      });

      setTableData(chemicals);
    });
  }

  useEffect(() => {
    setTableDataFromAPI();
  }, []);

  const handleRowSelection = (rowId) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(rowId)) {
        return prevSelectedRows.filter((id) => id !== rowId);
      } else {
        return [...prevSelectedRows, rowId];
      }
    });
  };

  const handleEdit = (uuid,field, value, rowId) => {
    setEditableData((prevEditableData) => ({ ...prevEditableData, [field]: value, id: rowId, uuid: uuid }));
  };

  const handleSave = async () => {
    const updatedTableDataWithChemical = {
      casrn: editableData.CASRN,
      name: editableData.name,
      price: editableData.price,
      date: editableData.date,
      source: editableData.source,
      notes: editableData.notes,
      region: editableData.region,
      uuid: editableData.uuid !== undefined ? editableData.uuid : v4(),
    }

    const updatedTableData = tableData.map((row) => {
      if (row.id === editableData.id) {
        const updatedRow = { ...row, ...editableData, uuid: updatedTableDataWithChemical.uuid };
        if (updatedRow.date) {
          const [year, month, day] = updatedRow.date.split('/');
          updatedRow.date = [day, month, year].join('/');
        }
        return updatedRow;
      }
      return row;
    });

    setTableData(updatedTableData);
    setIsEditing(false);
    setEditingRowId(null);

    if(Object.keys(editableData).length === 0) return;

    await FlowireAPI.authPost('/api/savechemical', updatedTableDataWithChemical, getIdTokenClaims, isAuthenticated).then((data) => {
      if(data.error){
        toast({
          title: "Error",
          description: data.error,
          status: "error",
          duration: 9000,
          isClosable: true,
        });

        const updatedTableData = tableData.filter((row) => row.uuid !== editableData.uuid);
        setTableData(updatedTableData);
      }
    });

    setEditableData({});
    setTableDataFromAPI();
  };

  const handleDelete = async (uuid) => {
    try {
      await FlowireAPI.authPost('/api/deletechemicals', [uuid], getIdTokenClaims, isAuthenticated);
  
      // If the deletion was successful, show a success toast
      toast({
        title: "Success",
        description: "Chemical deleted successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
  
      // Update the tableData from the API after successful deletion
      setTableDataFromAPI();
    } catch (error) {
      // If there was an error during deletion, show an error toast
      toast({
        title: "Error",
        description: "Failed to delete chemical",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
  
      // Restore the original tableData in case of an error
      setTableData((prevTableData) => [...prevTableData]);
    } finally {
      // Always reset editing state after attempting deletion
      setIsEditing(false);
      setEditingRowId(null);
    }
  };
  

  const handleStartEditing = (rowId) => {
    setIsEditing(true);
    setEditingRowId(rowId);
  };

  const handleCloseEditing = (uuid) => {
    if(uuid === undefined) setTableData((prevTableData) => prevTableData.filter((row) => row.id !== editingRowId));
    setIsEditing(false);
    setEditableData({});
    setEditingRowId(null);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleAddRow = () => {
    addEmptyRow();
  };

  const handleDeleteRows = async () => {
    const deletedRows = tableData
      .filter((row) => selectedRows.includes(row.id))
      .map((row) => row.uuid);
  
    try {
      await FlowireAPI.authPost('/api/deletechemicals', deletedRows, getIdTokenClaims, isAuthenticated);
  
      // If the deletion was successful, show a success toast
      toast({
        title: "Success",
        description: "Chemicals deleted successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
  
      // Update the tableData from the API after successful deletion
      setTableDataFromAPI();
    } catch (error) {
      // If there was an error during deletion, show an error toast
      toast({
        title: "Error",
        description: "Failed to delete chemicals",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
  
      // Restore the original tableData and selectedRows in case of an error
      setTableData((prevTableData) => [...prevTableData]);
      setSelectedRows([]);
    } finally {
      // Always reset editing state after attempting deletion
      setIsEditing(false);
      setEditingRowId(null);
    }
  };
  

  const addEmptyRow = () => {
    let newDate = new Date();
    let day = newDate.getUTCDate();
    let month = newDate.getUTCMonth() + 1;
    let year = newDate.getUTCFullYear();
    let formattedDate = `${day}/${month}/${year}`;
    setTableData((prevTableData) => [...prevTableData, {
      id: tableData.length,
      CASRN: '',
      name: '',
      price: '',
      date: formattedDate,
      source: '',
      notes: '',
      region: '',
    }]);
    setSelectedRows([...selectedRows, tableData.length]);
    setIsEditing(true);
    setEditingRowId(tableData.length);
    setEditableData({
      date: newDate.toISOString().slice(0, 10).replace(/-/g, '/'),
    });
  };

  const selectAll = (e) => {
    if(e.target.checked) setSelectedRows(tableData.map((row) => row.id));
    else setSelectedRows([]);
  }

  const filteredTableData = useMemo(() => {
    return tableData.filter((row) =>
      Object.values(row).some(
        (value) => value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [tableData, searchQuery]);

  const columns = [
    { Header: 'CASRN', accessor: 'CASRN' },
    { Header: 'Chemical Name', accessor: 'name' },
    { Header: 'USD/Tonne', accessor: 'price' },
    { Header: 'Date', accessor: 'date' },
    { Header: 'Source', accessor: 'source' },
    { Header: 'Notes', accessor: 'notes' },
    { Header: 'Region', accessor: 'region' },
  ];

  return (
    <>
      <Flex mb={4} align="center">
        <Input
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearch}
          width="300px"
        />
        <Button colorScheme="green" ml={4} onClick={handleAddRow}>
          Add Row
        </Button>
        <Button colorScheme="red" isDisabled={(selectedRows.length == 0)} ml={4} onClick={handleDeleteRows}>
          Delete Rows
        </Button>
      </Flex>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th><Checkbox onChange={e => selectAll(e)}></Checkbox></Th>
            {columns.map((column) => (
              <Th key={column.Header}>{column.Header}</Th>
            ))}
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredTableData.map((row) => (
            <Tr key={row.id}>
              <Td>
                {row.id !== -1 && (
                  <Checkbox
                    isChecked={selectedRows.includes(row.id)}
                    onChange={() => handleRowSelection(row.id)}
                  />
                )}
              </Td>
              {columns.map((column, index) => (
                <Td key={column.accessor}>
                  {row.id !== -1 ? (
                    isEditing && editingRowId === row.id ? column.accessor !== "date" ? (
                      <Input
                        key={row.id}
                        value={editableData[column.accessor] || row[column.accessor]}
                        onChange={(e) => handleEdit(row.uuid, column.accessor, e.target.value, row.id)}
                      />
                    ) : (
                      <DatePicker key={row.id}
                        dateFormat="dd/MM/yyyy" 
                        utcOffset={0}
                        customInput={<Input />} 
                        selected={editableData.date ? new Date(editableData.date) : new Date(row[column.accessor].split("/").reverse().join("/"))} 
                        onChange={(date) => {
                          handleEdit(row.uuid, column.accessor, date.toISOString().slice(0, 10).replace(/-/g, '/'), row.id);
                        }} 
                      />
                    ) : (
                      row[column.accessor]
                    )
                  ) : (
                    ''
                  )}
                </Td>
              ))}
              <Td>
                {row.id !== -1 && (
                  <Flex>
                    {isEditing && editingRowId === row.id ? (
                      <>
                        <Button colorScheme="blue" size="sm" onClick={handleSave}>
                          Save
                        </Button>
                        <Button colorScheme="red" size="sm" onClick={() => handleDelete(row.uuid)} ml={2}>
                          Delete
                        </Button>
                        <Button colorScheme="gray" size="sm" onClick={() => handleCloseEditing(row.uuid)} ml={2}>
                          Close
                        </Button>
                      </>
                    ) : !isEditing ? (
                      <Button colorScheme="blue" size="sm" onClick={() => handleStartEditing(row.id)}>
                        Edit
                      </Button>
                    ) : <></>}
                  </Flex>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};

export default Costing;
