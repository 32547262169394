import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Input,
    FormControl,
    FormLabel,
    useToast,
  } from '@chakra-ui/react';
  import { useEffect, useState } from 'react';
  
  function DeleteConfirmPopup({ isOpen, onClose, onDelete, item }) {
    const [inputValue, setInputValue] = useState('');
    const [isInputValid, setInputValid] = useState(false);
    const toast = useToast();

    useEffect(()=> {
        setInputValue("")
        setInputValid(false)
    },[isOpen])
  
    const handleChange = (event) => {
      const value = event.target.value;
      setInputValue(value);
      if(item)
        setInputValid(value === item.name);
    };
  
    const handleDelete = () => {
      if (isInputValid) {
        onDelete();
      } else {
        toast({
          title: "Incorrect name",
          description: "Please type the exact name of the item you wish to delete.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };
  
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Item</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Type the name of the item to confirm deletion <br/>({item ? item.name : ""}):</FormLabel>
              <Input
                value={inputValue}
                onChange={handleChange}
                placeholder="Type the item name"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              onClick={handleDelete}
              isDisabled={!isInputValid}
            >
              Yes, Delete
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
  
  export default DeleteConfirmPopup;
  