import {Box, Button, Card, CloseButton, Flex, Heading, Spacer} from "@chakra-ui/react";
import React, {useEffect, useRef, useState} from "react";

export function PopupButton(props) {
    return <Button onClick={props.onClick} isDisabled={props.disabled} colorScheme='blue' size="sm" ml={2}>
        {props.children}
    </Button>
}

export function PopupLayout({maxHeight, pageX, pageY, title, closeFunction, minWidth, minHeight, overflow, ...props}) {
    const componentRef = useRef(null);
    let componentBoundingBox = componentRef.current ? componentRef.current.getBoundingClientRect() : null;
    let componentWidth = componentBoundingBox ? componentBoundingBox.width : 0;
    let componentHeight = componentBoundingBox ? componentBoundingBox.height : 0;

    const [isDragging, setIsDragging] = useState(false);
    const [zIndex, setZIndex] = useState(1000);
    const [position, setPosition] = useState({
        x: pageX,
        y: pageY
    });
    const [previousMousePosition, setPreviousMousePosition] = useState({x: 0, y: 0});

    useEffect(()=>{
        const prev = window.localStorage.getItem(title);
        if (prev) {
            setPosition(JSON.parse(prev));
        }
    }, [])
 
    useEffect(() => {
        if (isDragging) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }
    }, [isDragging])

    const handleClose = () => {
        window.localStorage.removeItem(title);
        closeFunction();
    }

    const handleMouseUp = () => {
        setIsDragging(false);
    }

    const handleMouseMove = (event) => {
        event.preventDefault();
        if (isDragging) {
            const newPosition = {
                x: Math.min(Math.max(position.x + (event.clientX - previousMousePosition.x), 0), window.innerWidth - componentWidth),
                y: Math.min(Math.max(position.y + (event.clientY - previousMousePosition.y), 0), window.innerHeight)
            };

            window.localStorage.setItem(title, JSON.stringify(newPosition));

            setPosition(newPosition);
            setPreviousMousePosition({x: event.clientX, y: event.clientY});
        }
    }

    const handleMouseDown = (event) => {
        event.preventDefault();
        setPreviousMousePosition({x: event.clientX, y: event.clientY});
        setIsDragging(true);

        //TODO: Calculate highest zIndex and update zIndex so popup comes on top when clicked
    }

    return <Card position="absolute" width={minWidth || '320px'} height={minHeight || ''}
                 zIndex={zIndex || 1000} borderRadius="10px" boxShadow="lg" bgColor="white"
                 top={position.y + "px"} left={position.x + "px"} overflow="auto"
                 ref={componentRef}>
        <Flex className="handle" onMouseDown={handleMouseDown} cursor="grab"
              position='fixed' background="white" width={minWidth || '320px'} zIndex={10000}
              pt={4} pb={4} pl={4} pr={2} borderBottom='1px solid #eee'
              justifyContent="space-between" alignItems="center">
            <Heading size='sm'>{title}</Heading>
            <Spacer/>
            <CloseButton
                onClick={handleClose} size="sm"/>
        </Flex>
        <Box mt='46px'>
            <Box maxHeight={maxHeight} overflowY={props.overflowY ? props.overflowY : (!overflow ? '' : 'scroll')} overflowX='hidden' pl={2}
                 pr={2}>
                {props.children}
            </Box>
            {props.buttons && <Flex mt={4} pt={4} pb={4} pl={4} pr={2} borderTop='1px solid #eee'
                                    alignItems="center" justifyContent="flex-end">
                {props.buttons}
            </Flex>}
        </Box>
    </Card>
}
