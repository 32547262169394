import {Box, Button, Card, Flex, Switch, Tooltip} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import {
    FaArrowLeft,
    FaDollarSign,
    FaFileExcel,
    FaHome,
    FaPlay,
    FaPrint,
    FaRedo,
    FaRegSave,
    FaSave,
    FaUndo,
    FaWindowClose
} from "react-icons/fa";
import {
    FaMagnifyingGlassMinus,
    FaMagnifyingGlassPlus,
    FaWandMagicSparkles
} from "react-icons/fa6";
import {useNavigate, useParams} from "react-router-dom";

export function SimulatorToolbar(props) {
    const navigate = useNavigate();
    const {dataId} = useParams();
    const toolRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({
        x: 0,
        y: 0
    });
    const [previousMousePosition, setPreviousMousePosition] = useState({x: 0, y: 0});
    let componentBoundingBox = toolRef.current ? toolRef.current.getBoundingClientRect() : null;
    let componentWidth = componentBoundingBox ? componentBoundingBox.width : 0;
    let componentHeight = componentBoundingBox ? componentBoundingBox.height : 0;

    useEffect(() => {
        if (isDragging) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }
    }, [isDragging])

    const handleMouseUp = () => {
        setIsDragging(false);
    }

    const handleMouseMove = (event) => {
        event.preventDefault();
        if (isDragging) {
            const newPosition = {
                x: Math.min(Math.max(position.x + (event.clientX - previousMousePosition.x), 0), window.innerWidth - componentWidth),
                y: Math.min(Math.max(position.y + (event.clientY - previousMousePosition.y), 0), window.innerHeight - componentHeight*1.5)
            };

            setPosition(newPosition);
            setPreviousMousePosition({x: event.clientX, y: event.clientY});
        }
    }

    const handleMouseDown = (event) => {
        event.preventDefault();
        setPreviousMousePosition({x: event.clientX, y: event.clientY});
        setIsDragging(true);

        //TODO: Calculate highest zIndex and update zIndex so popup comes on top when clicked
    }

    return <Card onMouseDown={handleMouseDown} left={position.x + "px"} top={position.y+"px"} ref={toolRef}><Flex cursor="grab" direction="row"
                 p={4} pl={2} pr={2}
                 background="white"
                 boxShadow="md"
                 borderRadius={8}>
        <Tooltip label="Home" aria-label="Home">
        <Button variant="link" onClick={() => props.toolbarService.toolbar.trigger('home:pointerclick')}>
            <FaHome/>
        </Button>
        </Tooltip>
        <Box ml={1} mr={1}>|</Box>

        {!props.isTemplate && (
            <>
                <Tooltip label="Save" aria-label="Save">
                    <Button variant="link" onClick={() => {
                        if (props.toolbarService) {
                            props.toolbarService.toolbar.trigger('save-json:pointerclick');
                        }
                    }}>
                        <FaSave/>
                    </Button>
                    </Tooltip>
                    <Tooltip label="Save As" aria-label="Save As">
                    <Button variant="link" onClick={() => {
                        if (props.toolbarService) {
                            props.toolbarService.toolbar.trigger('save-as:pointerclick');
                        }
                    }}>
                        <FaRegSave/>
                    </Button>
                </Tooltip>
            </>
        )}
        
        <Tooltip label="Zoom In" aria-label="Zoom In">
        <Button variant="link" onClick={() => {
            if (props.paperScroller) {
                props.paperScroller.zoom(0.1);
            }
        }
        }>
            <FaMagnifyingGlassPlus/>
        </Button>
        </Tooltip>
        <Tooltip label="Zoom Out" aria-label="Zoom Out">
        <Button variant="link" onClick={() => {
            if (props.paperScroller) {
                props.paperScroller.zoom(-0.1);
            }
        }
        }>
            <FaMagnifyingGlassMinus/>
        </Button>
        </Tooltip>
        <Tooltip label="Print" aria-label="Print">
        <Button variant="link" onClick={() => {
            if (props.toolbarService) {
                props.toolbarService.toolbar.trigger('print:pointerclick');
            }
        }
        }>
            <FaPrint/>
        </Button>
        </Tooltip>

        <Box ml={1} mr={3}>|</Box>

        {!props.isTemplate && (
            <>
                <Tooltip label="Switch To Designer" aria-label="Switch To Designer">
                <Box position="relative" onClick={() => {
                    if (props.toolbarService) {
                        props.toolbarService.toolbar.trigger('switch-designer:pointerclick');          
                    }
                }}>
                    <Switch colorScheme='blue' size='lg' isChecked={true} mr={1}/>
                    <Box position="absolute" top={1.5} right={2}>
                        <FaWandMagicSparkles color='#06c'/>
                    </Box>
                </Box>
                </Tooltip>
            </>
        )}

        <Tooltip label="Simulate" aria-label="Simulate">
        <Button variant="link" onClick={() => {
            if (props.toolbarService) {
                props.toolbarService.toolbar.trigger('mb-switch:pointerclick');
            }
        }
        }>
            <FaPlay color="green"/>
        </Button>
        </Tooltip>

        <Tooltip label="Open Economics" aria-label="Open Economics">
        <Button variant="link" onClick={() => {
            if (props.toolbarService) {
                props.toolbarService.toolbar.trigger('economics:pointerclick');
            }
        }
        }>
            <FaDollarSign/>
        </Button>
        </Tooltip>
        
        <Tooltip label="Export Data Table" aria-label="Export Data Table">
        <Button variant="link" onClick={() => {
            if (props.toolbarService) {
                props.toolbarService.toolbar.trigger('streamtable:pointerclick');
            }
        }
        }>
            <FaFileExcel/>
        </Button>
        </Tooltip>

        <Box ml={1} mr={1}>|</Box>

        <Tooltip label="Close All Popups" aria-label="Close All Popups">
        <Button variant="link" onClick={() => {
            if (props.toolbarService) {
                props.toolbarService.toolbar.trigger('close-all:pointerclick');
            }
        }
        }>
            <FaWindowClose/>
        </Button>
        </Tooltip>

    </Flex>
    </Card>
}
