import {useAuth0} from "@auth0/auth0-react";
import {useEffect, useRef, useState} from "react";
import RappidService from './services/kitchen-sink-service';
import {ToolbarService} from "./services/toolbar-service";
import {useNavigate} from "react-router-dom";
import {Box, Button, CloseButton, Flex, Heading, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Select, Text, useDisclosure} from "@chakra-ui/react";
import ConfirmPopup from "../ConfirmPopup/ConfirmPopup";
import {DesignerToolbar} from "./DesignerToolbar";
import {createPortal} from "react-dom";
import EquipmentPropertyPopup from "./components/EquipmentPropertyPopup";
import RXSpecWizard from "./components/RXSpecWizard";
import FeedTagWizard from "./components/FeedTagWizard";
import {Sidebar} from "../Layout/components/Sidebar/Sidebar";
import FlowireAPI from "../../flowireapi/FlowireAPI";
import './css/style.css';
import { setTheme } from "@clientio/rappid";

const Diagram = ({orgSectors, isUpdated, hasUpdated, setGraphData, graphName, graphData, uuid, useSimulator, graphGroup, setGraphGroup}) => {
  const [rappid, setRappid] = useState(null)
  const { isAuthenticated, getIdTokenClaims } = useAuth0()
  const [rxSpecPopup, setRXSpecPopup] = useState(false)
  const [rxSpecElement, setRXSpecElement] = useState(null)
  const [rxSpecType, setRXSpecType] = useState(null)
  const [feedTagPopup, setFeedTagPopup] = useState(false)
  const [feedTagElement, setFeedTagElement] = useState(null)
  const [feedTagType, setFeedTagType] = useState(null)
  const [saveNamePopup, setSaveNamePopup] = useState(false)
  const [saveNameBox, setSaveNameBox] = useState(graphName ? graphName : "")
  const appRef = useRef(null);
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [equipmentPropertyPopups, setEquipmentPropertyPopups] = useState([])

  const handleConfirm = () => {
    onClose();
    navigate("/")
  };

  useEffect(() => {
    if(rappid)
    rappid.orgSectors = orgSectors;
  }, [orgSectors, rappid]);

  const onSave = async () => {
    onClose()
    let dontNav = await rappid.saveGraph()
    if(dontNav)
      rappid.confirmNavigate = true
    else
      navigate("/")
  }

  const displayEquipmentPropPopup = (element_Id, equipment, element_View, label_Text, targ) => {
    const pos = element_View.getBBox(),
      offset = {
          x: pos.x,
          y: pos.y
      };

    const x = (targ.left > 0 ? targ.left : 0) + offset.x;
    const y = (targ.top > 0 ? targ.top : 0) + offset.y + 20;

    setEquipmentPropertyPopups(prevPopups => {
      const existingPopup = prevPopups.find(popup => popup.element_Id === element_Id);
      if (existingPopup) {
        return prevPopups;
      } else {
        return [
          ...prevPopups,
          {
            element_Id: element_Id,
            equipment: equipment,
            element_View: element_View,
            label_Text: label_Text,
            position: { x, y }
          }
        ];
      }
    });
  }

  const closeEquipmentPropPopup = (element_Id) => {
    setEquipmentPropertyPopups(prevPopups => {
      return prevPopups.filter(popup => {
        return popup.element_Id !== element_Id
      });
    });
  }

  const displayRXSpecPopup = (element, type, active) => {
    setRXSpecElement(element)
    setRXSpecType(type)
    setRXSpecPopup(active);
  }

  const closeRXSpecPopup = () => {
    setRXSpecPopup(false)
    setRXSpecElement(null)
    setRXSpecType(null)
    rappid.paperScroller.center()
  }

  const displayFeedTagPopup = (element, type, active) => {
    setFeedTagElement(element)
    setFeedTagType(type)
    setFeedTagPopup(active)
  }

  const closeFeedTagPopup = () => {
    setFeedTagPopup(false)
    setFeedTagElement(null)
    setFeedTagType(null)
    rappid.paperScroller.center()
  }

  useEffect(() => {
    if(rappid){
      rappid.isUpdated = () => isUpdated
    }
  }, [isUpdated]);

  useEffect(() => {
    // Create a new RappidService instance and set rappid using setRappid
    if(!isAuthenticated || !orgSectors) return;
    setTheme('modern')
    const service = new RappidService(
      appRef.current,
      new ToolbarService(),
      navigate,
      displayRXSpecPopup,
      displayFeedTagPopup,
      toggleSaveNamePopup,
      isAuthenticated,
      getIdTokenClaims,
      useSimulator,
      setGraphData,
      onOpen,
      hasUpdated,
      () => isUpdated,
      displayEquipmentPropPopup,
      graphGroup,
      orgSectors
    );
    setRappid(service); // Set the rappid instance
    service.startRappid();

    if(graphData.cells){
      service.loadJSON(graphName, uuid, graphData, service.isUpdated())
    } else {
      if(uuid === "new") {
        service.uuid = uuid
        return;
      }
      navigate("/error")
    }

    return () => {
      if (rappid) {
        rappid.stopRappid();
      }
    };
  }, [isAuthenticated, getIdTokenClaims, orgSectors]);

  const handleSaveAs = (e,name) => {
    if(name == "") return;
    rappid.graphName = name
    rappid.saveGraph(selectedGroup)
    setGraphGroup(selectedGroup)
    toggleSaveNamePopup()
    if(rappid.confirmNavigate)
      navigate("/")
  }

  const handleSaveNameBoxChange = (e) => {
    const {value} = e.target
    setSaveNameBox(value)
  }

  const toggleSaveNamePopup = () => {
    setSaveNamePopup(!saveNamePopup)
  }

  const updateLinks = (element, remove=null) => {
    rappid.updateLinks(element, remove)
  }

  useEffect(() => {
    document.documentElement.style.overflow = 'hidden';
  return () => {
    document.documentElement.style.overflow = 'auto';
  };
  }, []);

  const [groups, setGroups] = useState([])

  useEffect(() => {
    if (isAuthenticated) {
      const getGroups = async () => {
        FlowireAPI
                .authGet('/api/getgroups', getIdTokenClaims, isAuthenticated)
                .then((data) => setGroups(data.groups))
      }

      getGroups()
    }
  }, [isAuthenticated, getIdTokenClaims]);

  const [selectedGroup, setSelectedGroup] = useState(graphGroup ? graphGroup : "")

  const handleGroupChange = (e) => {
    setSelectedGroup(e.target.value)
  }

  return (
    <Box ref={appRef} className="Diagram" overflow="hidden" position="absolute" width="99vw" height="100vh" left="0" top="0">
      {createPortal(
          <DesignerToolbar toggleSaveNamePopup={toggleSaveNamePopup}
                           paperScroller={rappid ? rappid.paperScroller : {}}
                           toolbarService={rappid ? rappid.toolbarService : {}}
                           commandManager={rappid ? rappid.commandManager : {}}
          />,
          document.getElementById('navbarContent')
      )}

      <ConfirmPopup isOpen={isOpen} onClose={onClose} onConfirm={handleConfirm} onSave={onSave} />

      <div className='save-as'>
        {saveNamePopup && (
          <Modal
            width="300px"
            borderRadius="10px"
            boxShadow="lg"
            bgColor="white"
            isOpen={saveNamePopup}
            onClose={toggleSaveNamePopup}
          >
          <ModalOverlay/>
          <ModalContent>
            <ModalBody>
          <Flex
            bgColor="white"
            p={2}
            justifyContent="space-between"
            alignItems="center"
            borderBottom='1px solid #eee'
            mb="2"
          >
              <Heading size="sm">Save As</Heading>
              
            <CloseButton onClick={() => {
              toggleSaveNamePopup()
            }}/>
          </Flex>

          <Flex justifyContent={"space-between"} mt={3} mb={3} mr={3} ml={3}>
            Select Group
              <Select placeholder="Select group" width="50%" value={selectedGroup} onChange={handleGroupChange}>
                {groups.map((group) => (
                  <option key={group.uuid} value={group.uuid}>{group.name}</option>
                ))}
              </Select>
          </Flex>
          <Flex justifyContent={"space-between"} mr={3} ml={3}>
            Enter New Name
            <Input  width="50%" type="text" value={saveNameBox} onChange={handleSaveNameBoxChange}/>
          </Flex>
          </ModalBody>
          <ModalFooter>
          <Button mt="5" mr="2" mb="5" colorScheme="blue" size="sm" float="right" onClick={(e) => handleSaveAs(e,saveNameBox)} isDisabled={saveNameBox == undefined || saveNameBox == ""}>Save</Button>
          </ModalFooter>
          </ModalContent>
        </Modal>
        )}
      </div>
      <div className="toolbarContainer" />

      <Sidebar>
        <Box className="stencilContainer" mt={8}/>
      </Sidebar>

      {equipmentPropertyPopups.map((item, index) => (
        <EquipmentPropertyPopup key={item.element_Id} closeEquipmentPropPopup={closeEquipmentPropPopup} element_Id={item.element_Id} element_View={item.element_View} label_Text={item.label_Text} equipment={item.equipment} startPos={item.position} updateLinks={updateLinks}/>
      ))}
      {rxSpecPopup && (
        <RXSpecWizard element={rxSpecElement} type={rxSpecType} closeRXSpecPopup={closeRXSpecPopup} on_Screen_Menu_Focus={rappid.on_Screen_Menu_Focus} updateLinks={updateLinks}/>
      )}
      {feedTagPopup && (
        <FeedTagWizard element={feedTagElement} type={feedTagType} closeFeedTagPopup={closeFeedTagPopup} on_Screen_Menu_Focus={rappid.on_Screen_Menu_Focus} updateLinks={updateLinks}></FeedTagWizard>
      )}

      <div className="paperContainer" id="paper-container" />

    </Box>
  )
}

export default Diagram
