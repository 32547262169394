import { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Input,
  Text,
  CloseButton,
  Flex,
  Spacer,
  useForceUpdate,
  Switch
} from '@chakra-ui/react';
import './model.css'
import { PopupLayout } from '../../../../PopupLayout/PopupLayout';

const PropertyPopup = (props) => {
  const [showMassFlow, setShowMassFlow] = useState(true);
  const [showMassFrac, setShowMassFrac] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({
    x: props.pageX,
    y: props.pageY
  });
  const forceUpdate = useForceUpdate();
  const [previousMousePosition, setPreviousMousePosition] = useState({ x: 0, y: 0 });
  const [specTotalFlow, setSpecTotalFlow] = useState(null);
  const [specMassFrac, setSpecMassFrac] = useState({});
  const [specMassFlow, setSpecMassFlow] = useState({});
  const componentRef = useRef(null);
  let componentWidth = componentRef.current ? componentRef.current.offsetWidth : 0;
  let componentHeight = componentRef.current ? componentRef.current.offsetHeight : 0;

  const handleMouseUp = () => {
    setIsDragging(false);
  }

  const handleMouseMove = (event) => {
    event.preventDefault();
    if (isDragging) {
      const newPosition = {
        x: position.x + (event.clientX - previousMousePosition.x),
        y: position.y + (event.clientY - previousMousePosition.y)
      };
      setPosition(newPosition);
      setPreviousMousePosition({ x: event.clientX, y: event.clientY });
    }
  }

  useEffect(() => {
    props.getMADropdownData(props.graph);
  }, []);

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }
  }, [isDragging]);

  const handleMouseDown = (event) => {
    setPreviousMousePosition({ x: event.clientX, y: event.clientY });
    setIsDragging(true);
  }

  const handleMassFlowClick = () => {
    console.log("mass flow button clicked")
  }

  const handleMassFracClick = () => {
    console.log("mass frac button clicked")
  }

  const handleMassFlowChange = (e, comp) => {
    // Regular expression that matches invalid characters
    const nonNumericRegex = /[^0-9.]/g;

    // Replace non-numeric characters with an empty string
    let value = e.target.value.replace(nonNumericRegex, '');

    // To prevent multiple decimal points
    if ((value.match(/\./g) || []).length > 1) {
      value = value.replace(/\.+$/, '');
    }

    setSpecMassFlow({ ...specMassFlow, [comp]: value });
  }

  const handleMassFracChange = (e, comp) => {
    // Regular expression that matches invalid characters
    const nonNumericRegex = /[^0-9.]/g;

    // Replace non-numeric characters with an empty string
    let value = e.target.value.replace(nonNumericRegex, '');

    // To prevent multiple decimal points
    if ((value.match(/\./g) || []).length > 1) {
      value = value.replace(/\.+$/, '');
    }

    setSpecMassFrac({ ...specMassFrac, [comp]: value });

  }

  const handleTotalFlowChange = (e) => {
    // Regular expression that matches invalid characters
    const nonNumericRegex = /[^0-9.]/g;

    // Replace non-numeric characters with an empty string
    let value = e.target.value.replace(nonNumericRegex, '');

    // To prevent multiple decimal points
    if ((value.match(/\./g) || []).length > 1) {
      value = value.replace(/\.+$/, '');
    }
    setSpecTotalFlow(value);
  }

  const handleMassFracUpdate = (e, comp) => {
    if (e.target.value !== '') {
      props.graph.getCell(props.linkId).attributes.specified_Stream_Properties.massFrac[comp] = e.target.value / props.fractionConversionOptions[Object.keys(props.fractionConversionOptions)[props.selectedFractionConversion]];
    } else {
      props.graph.getCell(props.linkId).attributes.specified_Stream_Properties.massFrac[comp] = null;
      props.graph.getCell(props.linkId).attributes.complete_Stream_Properties.massFrac[comp] = null;
    }
    setSpecMassFrac({ ...specMassFrac, [comp]: null });
    forceUpdate();
    props.getMADropdownData(props.graph);
  }

  const handleMassFlowUpdate = (e, comp) => {
    if (e.target.value !== '') {
      props.graph.getCell(props.linkId).attributes.specified_Stream_Properties.massFlow[comp] = e.target.value / props.massConversionOptions[Object.keys(props.massConversionOptions)[props.selectedMassConversion]];
    } else {
      props.graph.getCell(props.linkId).attributes.specified_Stream_Properties.massFlow[comp] = null;
      props.graph.getCell(props.linkId).attributes.complete_Stream_Properties.massFlow[comp] = null;
    }
    forceUpdate();
    setSpecMassFlow({ ...specMassFlow, [comp]: null });
    props.getMADropdownData(props.graph);
  }

  const handleTotalFlowUpdate = (e) => {
    if (e.target.value !== '') {
      props.graph.getCell(props.linkId).attributes.specified_Stream_Properties.totalFlow = e.target.value / props.massConversionOptions[Object.keys(props.massConversionOptions)[props.selectedMassConversion]];
    } else {
      props.graph.getCell(props.linkId).attributes.specified_Stream_Properties.totalFlow = null;
      props.graph.getCell(props.linkId).attributes.complete_Stream_Properties.totalFlow = null;
    }
    forceUpdate();
    setSpecTotalFlow(null);
    props.getMADropdownData(props.graph)
  }

  const handleReSimulate = (e, type, comp) => {
    if (e.key === "Enter") {
      switch (type) {
        case "massFlow":
          handleMassFlowUpdate(e, comp)
          break;
        case "massFrac":
          handleMassFracUpdate(e, comp)
          break;
        case "totalFlow":
          handleTotalFlowUpdate(e)
          break;
        default:
          break;
      }
      props.simulateFunction();
      props.getMADropdownData(props.graph);
    }
  }

  const handleSetKeyFeedStream = () => {
    const stream = props.graph.getCell(props.linkId);
    props.econVars.keyFeedStream = stream.attributes.stream_Number;
  }

  const { graph, linkId, isOpen, onClose } = props;
  const stream = graph.getCell(linkId);

  const specifiedMassFlow = stream.attributes.specified_Stream_Properties.massFlow;
  const completeMassFlow = stream.attributes.complete_Stream_Properties.massFlow;

  const specifiedMassFrac = stream.attributes.specified_Stream_Properties.massFrac;
  const completeMassFrac = stream.attributes.complete_Stream_Properties.massFrac;

  const specifiedTotalFlow = stream.attributes.specified_Stream_Properties.totalFlow;
  const completeTotalFlow = stream.attributes.complete_Stream_Properties.totalFlow;

  const pHValue = stream.attributes.complete_Stream_Properties.pH;
  const LSIValue = stream.attributes.complete_Stream_Properties.LSI;

  let displayedTotalFlow = specifiedTotalFlow !== null ? specifiedTotalFlow : completeTotalFlow;
  if (displayedTotalFlow !== null && displayedTotalFlow !== 0) {
    displayedTotalFlow = displayedTotalFlow * props.massConversionOptions[Object.keys(props.massConversionOptions)[props.selectedMassConversion]];
  }

  return (
    <>
      {isOpen && (
        <PopupLayout
          closeFunction={onClose}
          pageX={position.x}
          pageY={position.y}
          title={`Stream - ${stream.prop('stream_Number')}`}
          minWidth="300px"
          componentRef={componentRef}
        >
          <Box p={3}
            resize="vertical">
            {/*<Button colorScheme='blue' size="sm"
            onClick={handleSetKeyFeedStream}>Set Key Feed Stream</Button>*/}
            <Flex alignContent={"center"} justifyContent={"center"} mt="4" mb="2">
              {showMassFlow && <Button colorScheme='blue' variant="ghost" size="sm" width="65%" alignSelf="left" onClick={() => props.setSelectedMassConversion()}> {"Mass Flow (" + Object.keys(props.massConversionOptions)[props.selectedMassConversion] + ")"}</Button>}
              {showMassFrac && <Button colorScheme='blue' variant="ghost" size="sm" width="65%" alignSelf="left" onClick={() => props.setSelectedFractionConversion()}> {"Mass Fraction (" + Object.keys(props.fractionConversionOptions)[props.selectedFractionConversion] + ")"}</Button>}
              <Switch id={`${linkId}massFracFlowSwitch`} colorScheme='blue' size='lg' isChecked={showMassFrac} onChange={() => {
                setShowMassFrac(!showMassFrac);
                setShowMassFlow(!showMassFlow);
              }} />
            </Flex>
            {showMassFlow && (
              <Box className='collapse' borderTop='1px solid #eee' borderBottom='1px solid #eee'>
                {Object.keys(specifiedMassFlow).map(comp => (
                  <Flex
                    p={2}
                    justifyContent="space-between"
                    alignItems="center">
                    <Box id={`${linkId}${comp}massFlow_Label`} fontSize="small" width="40%">{comp}</Box>
                    <Input
                      width="60%"
                      id={`${linkId}${comp}massFlow_Input`}
                      onBlur={(e) => handleMassFlowUpdate(e, comp)}
                      size="sm"
                      value={specMassFlow[comp] ?? ((mf = (specifiedMassFlow[comp] !== null ? specifiedMassFlow[comp] : completeMassFlow[comp])) => mf !== null && mf !== 0 ? mf * props.massConversionOptions[Object.keys(props.massConversionOptions)[props.selectedMassConversion]] : mf)()}
                      onChange={(e) => handleMassFlowChange(e, comp)}
                      isDisabled={specifiedMassFlow[comp] === null && completeMassFlow[comp] !== null}
                      onKeyDown={(e) => handleReSimulate(e, "massFlow", comp)}
                    />
                  </Flex>
                ))}
              </Box>
            )}
            {showMassFrac && (
              <Box borderBottom="1px solid #eee" borderTop="1px solid #eee">
                {Object.keys(specifiedMassFrac).map((comp) => {
                  const isDisabled = !specifiedMassFrac[comp] && completeMassFrac[comp] !== null;
                  const value = specMassFrac[comp] ?? specifiedMassFrac[comp] ?? completeMassFrac[comp];
                  const conversionValue = props.fractionConversionOptions[Object.keys(props.fractionConversionOptions)[props.selectedFractionConversion]];
                  const convertedValue = value !== null && value !== 0 ? value * conversionValue : value;
                  const formattedValue = isDisabled && convertedValue !== null ? parseFloat(convertedValue).toFixed(12) : convertedValue;

                  return (
                    <Flex key={comp} p={2} justifyContent="space-between" alignItems="center">
                      <Box width="40%" fontSize="small" id={`${linkId}${comp}massFrac_Label`}>
                        {comp}
                      </Box>
                      <Input
                        id={`${linkId}${comp}massFrac_Input`}
                        width="60%"
                        size="sm"
                        value={specMassFrac[comp] ?? ((mf = (specifiedMassFrac[comp] !== null ? specifiedMassFrac[comp] : completeMassFrac[comp])) => mf !== null && mf !== 0 ? mf * props.fractionConversionOptions[Object.keys(props.fractionConversionOptions)[props.selectedFractionConversion]] : mf)()}
                        //value={convertedValue}
                        onBlur={(e) => handleMassFracUpdate(e, comp)}
                        onChange={(e) => handleMassFracChange(e, comp)}
                        isDisabled={isDisabled}
                        onKeyDown={(e) => handleReSimulate(e, "massFrac", comp)}
                      />
                    </Flex>
                  );
                })}
              </Box>
            )}
            <Flex
              p={2}
              justifyContent="space-between"
              alignItems="center"
              borderBottom='1px solid #eee'>
              <Box width="40%" fontSize={"small"} id={`${linkId}totalFlow_Label`}>{"Total (" + Object.keys(props.massConversionOptions)[props.selectedMassConversion] + ")"}</Box>
              <Input
                size="sm"
                width="60%"
                id={`${linkId}totalFlow_Input`}
                value={specTotalFlow ?? displayedTotalFlow}
                onBlur={handleTotalFlowUpdate}
                onChange={handleTotalFlowChange}
                isDisabled={specifiedTotalFlow === null && completeTotalFlow !== null}
                onKeyDown={(e) => handleReSimulate(e, "totalFlow")}
              />
            </Flex>
            {pHValue && (
              <Flex p={2} justifyContent="space-between" alignItems="center" borderBottom='1px solid #eee'>
                <Box width="40%" fontSize={"small"} id={`${linkId}pH_Label`}>
                  {"pH"}
                </Box>
                <Input
                  size="sm"
                  width="60%"
                  id={`${linkId}pH_Input`}
                  value={pHValue}
                  onBlur={() => { }}
                  onChange={() => { }}
                  isDisabled={true}
                  onKeyDown={() => { }}
                />
              </Flex>
            )}
            {LSIValue && (
              <Flex p={2} justifyContent="space-between" alignItems="center" borderBottom='1px solid #eee'>
                <Box width="40%" fontSize={"small"} id={`${linkId}LSI_Label`}>
                  {"LSI"}
                </Box>
                <Input
                  size="sm"
                  width="60%"
                  id={`${linkId}LSI_Input`}
                  value={LSIValue}
                  onBlur={() => { }}
                  onChange={() => { }}
                  isDisabled={true}
                  onKeyDown={() => { }}
                />
              </Flex>
            )}
          </Box>

        </PopupLayout>

      )}
    </>
  );
}

export default PropertyPopup;
