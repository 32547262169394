const FlowireAPI = {
    async authGet(endpoint, getIdTokenClaims, isAuthenticated, errorHandler = defaultErrorHandler) {
        if (!isAuthenticated) return Promise.reject("User is not authenticated");
        const token = (await getIdTokenClaims()).__raw;
        try {
            if (process.env.REACT_APP_MOCK_API) {
                if (endpoint.includes('load')) {
                    return require('../mock/load.json');
                }
            }
            const response = await fetch(endpoint, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                errorHandler(response);
            }
            return response.json();
        } catch (error) {
            errorHandler(error);
        }
    },

    async authPost(endpoint, data, getIdTokenClaims, isAuthenticated, errorHandler = defaultErrorHandler) {
        if (!isAuthenticated) return Promise.reject("User is not authenticated");
        const token = (await getIdTokenClaims()).__raw;
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                errorHandler(response);
            }
            return response.json();
        } catch (error) {
            errorHandler(error);
        }
    },

    async get(url, errorHandler = defaultErrorHandler) {
        try {
            const response = await fetch(`${url}`);
            if (!response.ok) {
                errorHandler(response);
            }
            return response.json();
        } catch (error) {
            errorHandler(error);
        }
    },

    async post(url, data, errorHandler = defaultErrorHandler) {
        try {
            const response = await fetch(`${url}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                errorHandler(response);
            }
            return response.json();
        } catch (error) {
            errorHandler(error);
        }
    },
};

function defaultErrorHandler(error) {
    console.error(error.statusText);
}

export default FlowireAPI;
