import {
    Box,
    Button,
    Flex,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList
} from '@chakra-ui/react';
import {useAuth0} from "@auth0/auth0-react";
import {useLocation, useNavigate} from "react-router-dom";

export function Navbar(props) {
    const {isAuthenticated, logout, user, getIdTokenClaims} = useAuth0();
    const navigate = useNavigate();
    const location = useLocation();

    return (isAuthenticated &&
        <Flex
            justify="space-between"
            align="center"
            mb="4"
            position={location.pathname.endsWith('/sim') ? "absolute" : "sticky"}
            top="0"
            bg={location.pathname.startsWith('/d/') ? '' : "white"}
            zIndex="500"
            p="8"
            alignItems="end"
            width="100%"
        >
            {props.children}

            <Box id="navbarContent"/>

            <Menu>
                <MenuButton className="profile-menu">
                    <Image
                        src={user.picture}
                        alt={user.name}
                        width="40px"
                        height="40px"
                        borderRadius="full"
                        cursor="pointer"
                    />
                </MenuButton>
                <MenuList p={4}>
                    {/*<MenuGroup title='Profile'>*/}
                    <MenuItem>Account</MenuItem>
                    {/*</MenuGroup>*/}
                    {/*<MenuDivider />*/}
                    {/*<MenuGroup title='Organization'>*/}
                    <MenuItem onClick={() => {
                        navigate('/settings')
                    }}>Permissions</MenuItem>
                    {/*</MenuGroup>*/}
                    {/*<MenuDivider />*/}
                    {/*<MenuGroup title='Settings'>*/}
                    {/*<Box px={4} py={2}>*/}
                    {/*  <Text mb={2}>Change Currency</Text>*/}
                    {/*  <Select*/}
                    {/*    value={currency}*/}
                    {/*    onChange={(e) => setCurrency(e.target.value)}*/}
                    {/*  >*/}
                    {/*  {Object.keys(currencies).map((currKey) => (*/}
                    {/*            <option key={currKey} value={currKey}>*/}
                    {/*              {currKey}*/}
                    {/*            </option>*/}
                    {/*          ))}*/}
                    {/*  </Select>*/}
                    {/*</Box>*/}
                    {/*<Box px={4} py={2}>*/}
                    {/*  <Text mb={2}>Change Timezone</Text>*/}
                    {/*  <Select*/}
                    {/*    value={timezone}*/}
                    {/*    onChange={(e) => setTimezone(e.target.value)}*/}
                    {/*  >*/}
                    {/*    {Object.keys(timezones).map((zoneKey) => (*/}
                    {/*      <option key={zoneKey} value={zoneKey}>*/}
                    {/*        {zoneKey}*/}
                    {/*      </option>*/}
                    {/*    ))}*/}
                    {/*  </Select>*/}
                    {/*</Box>*/}
                    {/*</MenuGroup>*/}
                    {/*<MenuDivider/>*/}
                    <Button colorScheme="red" size="sm" width="100%" marginTop="4"
                            onClick={() => logout({logoutParams: {returnTo: window.location.origin}})}>Logout</Button>

                </MenuList>
            </Menu>
        </Flex>
    )
}