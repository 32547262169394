import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Spacer
} from '@chakra-ui/react';

function ConfirmPopup({ isOpen, onClose, onConfirm, onSave }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirm Action</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure you want to leave without saving your changes?
        </ModalBody>
        <ModalFooter>
        <Flex width="full" justifyContent="space-between" alignItems="center">
            <Flex gap="2">
            <Button variant='ghost' onClick={onSave}>Save</Button>
            <Button colorScheme="red" onClick={onConfirm}>
                Leave without Saving
            </Button>
            </Flex>
            <Spacer />
            <Button variant="ghost" onClick={onClose}>
            Cancel
            </Button>
        </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ConfirmPopup