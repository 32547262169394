import React from 'react';
import { ResponsiveSankey } from '@nivo/sankey';
import { Button, Card, Flex } from '@chakra-ui/react';

const OverallSankeyGraph = ({graphData}) => {
    const data = {
        nodes: [
          { id: 'cashflow', value: graphData.cashflowSum },  // Set the value for "cashflow"
          { id: 'productRevenue' },
          { id: 'opexFeed' },
          { id: 'opex' },
          { id: 'feedstock' },
        ],
        links: [
          { source: 'cashflow', target: 'productRevenue', value: graphData.productRev },
          { source: 'cashflow', target: 'opexFeed', value: graphData.opexFeed },
          { source: 'opexFeed', target: 'opex', value: graphData.opex },
          { source: 'opexFeed', target: 'feedstock', value: graphData.feed },
        ],
      };

  return (
    <div height="500px">
      <Card
        width="100%"
        height="450"
        boxShadow="lg"
        bgColor="white"
        marginTop="10"
      >
        <Flex
          className="handle"
          p={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Button variant="ghost" fontSize="18">
            Discounted Cash Flow Over Time
          </Button>
        </Flex>
      <ResponsiveSankey
        data={data}
        margin={{ top: 10, right: 40, bottom: 10, left: 40 }}
        align="justify"
        colors={{ scheme: 'nivo' }}
        nodeOpacity={1}
        nodeThickness={18}
        nodeInnerPadding={3}
        nodeSpacing={24}
        nodeBorderWidth={0}
        nodeBorderColor={{ from: 'color', modifiers: [['darker', 0.8]] }}
        linkOpacity={0.5}
        linkHoverOthersOpacity={0.1}
        enableLabels={true}
        labelPosition="outside"
        labelOrientation="vertical"
        labelPadding={16}
      />
      </Card>
    </div>
  );
};

export default OverallSankeyGraph;
