import {useEffect, useRef, useState} from 'react';
import {Box, Button, Heading, Select, Input, HStack, Flex, CloseButton} from '@chakra-ui/react';
import {PopupButton, PopupLayout} from "../../../PopupLayout/PopupLayout";

const StoichWizard = (props) => {
    const [state, setState] = useState({
        step: 1,
        conversion: 50,
        basis: '',
        components: [],
        incomingComponents: [],
        rx_Data: props.element.attributes['equipment_Properties']['rx_Data'] ? props.element.attributes['equipment_Properties']['rx_Data'] : {}
    });
    const componentRef = useRef(null);
    const unitName = props.element.attributes.attrs.shapename.innerHTML.replace('<p>', '').replace('</p>', '');

    useEffect(() => {
        if (!props.element.attributes.components && !props.element.attributes.RX_components)
            setState({...state, components: []})
        else {
            let newComponents = {}
            if (!props.element.attributes.components) props.element.attributes.components = {}
            Object.keys(props.element.attributes.components).forEach(key => {
                newComponents[key] = {...props.element.attributes.components[key]}
            })

            if (!props.element.attributes.RX_components) props.element.attributes.RX_components = {}
            Object.keys(props.element.attributes.RX_components).forEach(key => {
                newComponents[key] = {...props.element.attributes.RX_components[key]}
            })

            setState(prev => ({...prev, components: newComponents}))
        }

        setState(prev => ({
            ...prev,
            conversion: props.element.attributes['equipment_Properties']['conversion'] ? props.element.attributes['equipment_Properties']['conversion'] : '0',
            basis: props.element.attributes['equipment_Properties']['basis'] ? props.element.attributes['equipment_Properties']['basis'] : props.element.attributes['equipment_Properties']['components'][0] || ''
        }))

        let incomingComponents = props.element.attributes.components || {}

        if (Object.keys(incomingComponents).length === 1) {
            setState(prev => ({...prev, basis: Object.keys(incomingComponents)[0]}))
        }

        setState(prev => ({...prev, incomingComponents: incomingComponents}))
    }, [])

    const updateStoich = (name, value) => {
        const updated = {...state.components};
        setState(prevState => ({
            ...prevState,
            rx_Data: {...prevState.rx_Data, [name]: {stoich: value, molmass: state.components[name].M}}
        }));
        setState(prevState => ({...prevState, components: updated}));
    };

    const addSpecLabels = () => Object.keys(state.components).map(name => (
        <HStack key={name} marginBlockEnd={4}>
            <Box size="sm" width="100px">
                {name}
            </Box>
            <Box size="sm" width="40%">{state.components[name].M} (g/Mol)</Box>
            <Input width="30%"
                   value={state.rx_Data[name].stoich}
                   onChange={e => updateStoich(name, e.target.value)}
            />
        </HStack>
    ));

    const addConversionSection = () => {
        const options = Object.keys(state.incomingComponents);
        return (
            <HStack key={name} marginBlockEnd={4}>
                <Select value={state.basis} onChange={e => {
                    setState(prevState => ({...prevState, basis: e.target.value}))
                }}>
                    {options.map(opt => (
                        <option key={opt} value={opt}>{opt}</option>
                    ))}
                </Select>
                <Input
                    value={state.conversion}
                    onChange={e => setState(prevState => ({...prevState, conversion: e.target.value}))}
                    placeholder="%"
                />
            </HStack>
        );
    };

    const finish = () => {
        props.element.attributes['equipment_Properties']['conversion'] = state.conversion;
        props.element.attributes['equipment_Properties']['basis'] = state.basis;
        props.element.attributes['equipment_Properties']['rx_Data'] = state.rx_Data;
        props.resolve();
    };

    return (
        <div id="StoichWizard">
            <PopupLayout
                minWidth="500px"
                componentRef={componentRef}
                pageX={window.innerWidth / 2 - 250}
                pageY={window.innerHeight / 2 - 250}
                closeFunction={props.resolve}
                title={unitName ? `Provide Stoichiometry for ${unitName.replace(/<br>/g, '')}` : ''}
                buttons={<PopupButton onClick={() => finish()}>Finish</PopupButton>}
            >
                <Flex ml={2} mr={2} mt={3} flexDirection="column">
                    {addSpecLabels()}

                    <Heading size="sm" mb={4}>Specify Conversion (%)</Heading>

                    {addConversionSection()}
                </Flex>
            </PopupLayout>

        </div>
    );
};

export default StoichWizard;
