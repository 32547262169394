import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Dashboard from './Dashboard/Dashboard'
import AuthWrapper from './AuthenticatedRoute/AuthWrapper';
import {Layout} from "./Layout/Layout";
import {FaDatabase, FaFolder} from "react-icons/fa";
import {FaChartBar, FaFileExport} from "react-icons/fa6";
import {SidebarLink} from "./Layout/components/Sidebar/Sidebar";
import Assumptions from "./Assumptions/Assumptions";
import Login from "./Login/Login";
import NoPermissions from "./NoPermissions/NoPermissions";
import Diagram from "./Diagram/Diagram";
import Callback from "./Callback/Callback";
import Error from "./Error/Error";
import OrganizationPermissions from "./OrganizationPermissions/OrganizationPermissions";
import Costing from "./CostingDB/Costing";
import Templates from './Templates/Templates';

function App() {
    return (
        <Router>
            <Layout sideBar={<>
                <SidebarLink leftIcon={<FaFolder size={16}/>} label="Projects"/>
                <SidebarLink leftIcon={<FaChartBar size={16}/>} link="/assumptions" label="Portfolio"/>
                <SidebarLink leftIcon={<FaDatabase size={16}/>} link="/costing" label="Costing Database"/>
                <SidebarLink leftIcon={<FaFileExport size={16}/>} link="/templates" label="Templates"/>
            </>}
                    topBar={<></>}>
                <Routes>
                    <Route index element={<AuthWrapper path="/" element={Dashboard}/>}/>
                    <Route path="/templates" element={<AuthWrapper path="/templates" element={Templates}/>}/>
                    <Route path="/assumptions" element={<AuthWrapper path="/assumptions" element={Assumptions}/>}/>
                    <Route path="/settings" element={<AuthWrapper path="/settings" element={OrganizationPermissions}/>}/>
                    <Route path="/costing" element={<AuthWrapper path="/costing" element={Costing}/>}/>

                    <Route path="/login" element={<Login/>}/>
                    <Route path="/nopermissions" element={<NoPermissions/>}/>
                    <Route
                        path="/d/:dataId/:isSim?"
                        element={<AuthWrapper element={Diagram}/>}
                    />
                    <Route path="/callback" element={<Callback/>}/>
                    <Route path="/error" element={<Error/>}/>
                </Routes>

            </Layout>
        </Router>
    );
}


export default App;
