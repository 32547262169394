import {Box, Button, Card, Flex, Heading, Icon, IconButton, Image, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, Tooltip, useToast} from '@chakra-ui/react';
import {useAuth0} from '@auth0/auth0-react';
import {FaCalendar, FaCopy, FaFontAwesome, FaIcons, FaRegEdit, FaRegFontAwesomeLogoFull, FaRegHandPaper, FaTrash, FaUser} from "react-icons/fa";
import { useState } from 'react';
import FlowireAPI from '../../../flowireapi/FlowireAPI';
import { useNavigate } from 'react-router-dom';

const GraphItem = ({ item, formatDate, clicked}) => {
  const {isAuthenticated, getIdTokenClaims} = useAuth0();
  const toast = useToast()
  const navigate = useNavigate();

  const handleCloneClick = async (e, uuid) => {
    e.stopPropagation(); // This stops the click from bubbling up to the parent
    try {
      await FlowireAPI.authPost('/api/clone', { uuid: uuid }, getIdTokenClaims, isAuthenticated, () => {
        toast({
          title: "Error",
          description: "Failed to clone graph",
          status: "error",
          duration: 9000,
          isClosable: true,
        })
      }).then((data) => {
        if(data){
          toast({
            title: "Success",
            description: "Successfully cloned graph",
            status: "success",
            duration: 9000,
            isClosable: true,
          })

          navigate('/d/' + data.new_uuid)
        }
      });
    } catch (error) {
      console.error(error); // Handle the error
    }
  };


  return (
    <Card
      key={item.uuid}
      m="4"
      width="300px"
      boxShadow="md"
      cursor="pointer"
      overflow="hidden"
      onClick={() => clicked(item.uuid)}
    >
      <Flex alignItems="center" p="4">
        <Heading fontWeight='bold' fontSize='1.1rem' width="calc(100%)" whiteSpace="nowrap"
          overflow="hidden" textOverflow="ellipsis">
          {item.name}
        </Heading>
        <IconButton
          onClick={(e) => handleCloneClick(e, item.uuid)}
          aria-label="Clone"
          background="white"
          variant="link"
          minW="6"
        >
          <FaCopy size={14} />
        </IconButton>
      </Flex>
      <Box backgroundColor="#EEE" height="70px" width="100%" />
      <Flex p="4">
        <Flex w="50%" alignItems="center" justifyContent="flex-start">
          <FaUser />
          <Text textOverflow="ellipsis" width="100px" overflow="hidden" whiteSpace="nowrap">
            &nbsp;{item.last_updated_by}
          </Text>
        </Flex>
        <Flex w="50%" alignItems="center" textOverflow="ellipsis">
          <FaCalendar />
          &nbsp;{formatDate(item.last_updated)}
          
        </Flex>
        <Box>
          <Tooltip label={item.org_name} placement="top" hasArrow>
            <Image src={item.logo_url} alt="logo" width="20px" height="20px" />
          </Tooltip>
        </Box>
      </Flex>
    </Card>
  );
        }  
export default GraphItem;
