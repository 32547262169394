import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import FloLoading from '../FloLoading/FloLoading';

function AuthWrapper({ element: Element }) {
  const { isAuthenticated, isLoading } = useAuth0();

  const redirectToLoginWithReturnUrl = () => {
    setInterval(() => {}, 6000)
    
    const { dataId, path } = useParams();
    const location = useLocation();

    const returnUrl = dataId
      ? `${window.location.origin}${location.pathname}`
      : path || location.pathname;
  
    sessionStorage.setItem('returnUrl', returnUrl);
    return <Navigate to="/login" replace />;
  };

  if(!isLoading){
    return isAuthenticated ? (
      <Element />
    ) : (
      redirectToLoginWithReturnUrl()
    );
  } else {
    return <FloLoading />
  }
}

export default AuthWrapper;
