import {Box, Button, Card, Flex, Heading, Icon, IconButton, Image, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, Tooltip, useToast} from '@chakra-ui/react';
import FlowireAPI from '../../../../flowireapi/FlowireAPI';
import {useAuth0} from '@auth0/auth0-react';
import {FaCalendar, FaCopy, FaFontAwesome, FaIcons, FaRegEdit, FaRegFontAwesomeLogoFull, FaRegHandPaper, FaTrash, FaUser} from "react-icons/fa";
import { useState } from 'react';

const GraphItem = ({ item, onDelete, formatDate, clicked, loadSimulationData, simulationGroups}) => {
  const {isAuthenticated, getIdTokenClaims} = useAuth0();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [newName, setNewName] = useState(item.name);
  const [newGroup, setNewGroup] = useState(item.group_uuid ? item.group_uuid : "");
  const toast = useToast()

  const handleDeleteClick = (e, uuid) => {
    e.stopPropagation(); // This stops the click from bubbling up to the parent
    onDelete(uuid);
  };

  const handleCloneClick = async (e, uuid) => {
    e.stopPropagation(); // This stops the click from bubbling up to the parent
    try {
      await FlowireAPI.authPost('/api/clone', { uuid: uuid }, getIdTokenClaims, isAuthenticated, () => {
        toast({
          title: "Error",
          description: "Failed to clone graph",
          status: "error",
          duration: 9000,
          isClosable: true,
        })
      }).then((data) => {
        if(data){
          toast({
            title: "Success",
            description: "Successfully cloned graph",
            status: "success",
            duration: 9000,
            isClosable: true,
          })
          loadSimulationData()
        }
      });
    } catch (error) {
      console.error(error); // Handle the error
    }
  };

  const handleRenameClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setEditModalOpen(true);
  };

  const handleRenameSubmit = async (uuid) => {
    if(newName === item.name) return;
    item.name = newName;
    try {
      await FlowireAPI.authPost('/api/rename', { uuid: uuid, graphName: newName }, getIdTokenClaims, isAuthenticated, () => {
        toast({
          title: "Error",
          description: "Failed to rename graph",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }).then((data) => {
        if (data) {
          toast({
            title: "Success",
            description: "Successfully renamed graph",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          loadSimulationData();
        }
      });
    } catch (error) {
      console.error(error); 
    }
  };

  const handleGroupSubmit = async (uuid) => {
    let newGroupObj = simulationGroups.find(group => group.uuid === newGroup);
    if(!newGroupObj) return;
    if(newGroupObj.uuid === item.group_uuid) return;
    item.group_uuid = newGroupObj.uuid;
    try {
      await FlowireAPI.authPost('/api/sim/setgroup', { uuid: uuid, group_uuid: newGroupObj.uuid }, getIdTokenClaims, isAuthenticated, () => {
        toast({
          title: "Error",
          description: "Failed to change group",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }).then((data) => {
        if (data) {
          toast({
            title: "Success",
            description: "Successfully changed group",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          loadSimulationData();
        }
      });
    } catch (error) {
      console.error(error); 
    }
  }

  const handleConfirmEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleRenameSubmit(item.uuid)
    handleGroupSubmit(item.uuid)
    setEditModalOpen(false);
  }


  return (
    <Card
      key={item.uuid}
      m="4"
      width="300px"
      boxShadow="md"
      cursor="pointer"
      overflow="hidden"
      onClick={() => clicked(item.uuid)}
    >
      <Flex alignItems="center" p="4">
        <Heading fontWeight='bold' fontSize='1.1rem' width="calc(100% - 48px)" whiteSpace="nowrap"
          overflow="hidden" textOverflow="ellipsis">
          {item.name}
        </Heading>
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleRenameClick(e);
          }}
          aria-label="Rename"
          background="white"
          variant="link"
          minW="6"
        >
          <FaRegEdit size={14} />
        </IconButton>
        <IconButton
          onClick={(e) => handleCloneClick(e, item.uuid)}
          aria-label="Clone"
          background="white"
          variant="link"
          minW="6"
        >
          <FaCopy size={14} />
        </IconButton>
        <IconButton
          onClick={(e) => handleDeleteClick(e, item.uuid)}
          aria-label="Delete"
          background="white"
          variant="link"
          minW="6"
        >
          <FaTrash size={14} color="red" />
        </IconButton>
      </Flex>
      <Box backgroundColor="#EEE" height="70px" width="100%" />
      <Flex p="4">
        <Flex w="50%" alignItems="center" justifyContent="flex-start">
          <FaUser />
          <Text textOverflow="ellipsis" width="100px" overflow="hidden" whiteSpace="nowrap">
            &nbsp;{item.last_updated_by}
          </Text>
        </Flex>
        <Flex w="50%" alignItems="center" textOverflow="ellipsis">
          <FaCalendar />
          &nbsp;{formatDate(item.last_updated)}
          
        </Flex>
        <Box>
          <Tooltip label={item.org_name} placement="top" hasArrow>
            <Image src={item.logo_url} alt="logo" width="20px" height="20px" />
          </Tooltip>
        </Box>
      </Flex>
  
      {/* Rename Modal */}
      <Modal isOpen={editModalOpen} onClose={() => setEditModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Graph</ModalHeader>
          <ModalBody>
            <Flex justifyContent={"space-between"}>
              Enter New Name<Input width="60%" value={newName} onChange={(e) => setNewName(e.target.value)} placeholder="Enter new name" />
            </Flex>
            <Flex mt="5" justifyContent={"space-between"}>
              Select Group
            <Select width="60%" value={newGroup} onChange={(e) => setNewGroup(e.target.value)} placeholder="Select new group" >
              {simulationGroups && simulationGroups.map((group) => (
                <option key={group.uuid} value={group.uuid}>{group.name}</option>
              ))}
            </Select>
            </Flex>

          </ModalBody>
  
          <ModalFooter>
            <Button onClick={() => setEditModalOpen(false)} aria-label="Cancel" minW="6">
              Cancel
            </Button>
            <Button
              onClick={(e) => handleConfirmEdit(e)}
              aria-label="Add"
              colorScheme="blue"
              minW="6"
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
        }  
export default GraphItem;
