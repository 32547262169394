import { useAuth0 } from "@auth0/auth0-react"
import { Box, Button, Card, CloseButton, Flex, Heading, Input, Text, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import AsyncSelect from 'react-select/async';
import FlowireAPI from "../../../flowireapi/FlowireAPI";

const RXSpecWizard = ({element, type, closeRXSpecPopup, on_Screen_Menu_Focus, updateLinks}) => {
    const [selecting, setSelecting] = useState(true)
    const [selectingComponents, setSelectingComponents] = useState(false)
    const [components, setComponents] = useState([])
    const [customCompounds, setCustomCompounds] = useState({})
    const [compoundInput, setCompoundInput] = useState(null)
    const [addingCompound, setAddingCompound] = useState(false)
    const [compoundMWeight, setCompoundMWeight] = useState("")
    const [compoundName, setCompoundName] = useState("")
    const [updateCustomCompoundsState, setUpdateCustomCompoundsState] = useState(true);
    const [componentsToRemove, setComponentsToRemove] = useState([])
    const toast = useToast();
    const {isAuthenticated, getIdTokenClaims} = useAuth0()
    const [isAddCompoundDisabled, setIsAddCompoundDisabled] = useState(true)
    const [isDeleteCompoundDisabled, setIsDeleteCompoundDisabled] = useState(true)
    const [compoundToDelete, setCompoundToDelete] = useState(null)
    const [isDeleteCompoundListDisabled, setIsDeleteCompoundListDisabled] = useState(true)
    const [compoundMFormula, setCompoundMFormula] = useState("")
    const [componentCCID, setComponentCCID] = useState("");
    
    useEffect(() => {
        const fetchAllComponentDetails = async () => {
            if (element.attributes['RX_components']) {
                let updatedComponents = [];
    
                for (const [compName, compAttributes] of Object.entries(element.attributes['RX_components'])) {
                    const details = await fetchCompoundDetails(compName);
                
                    if (details) {
                        updatedComponents.push({
                            compName: compName,
                            mW: compAttributes.M,
                        });
                    } else {
                        updatedComponents.push({
                            compName: compName,
                            mW: compAttributes.M, 
                        });
                    }
                    
                }
    
                setComponents(updatedComponents); 
            }
        };
    
        fetchAllComponentDetails();
    }, [element.attributes]); 
    
    useEffect(() => {
        if(!element.attributes['RX_components']) element.prop({ 'RX_components': {} })
        if(Object.keys(element.attributes['RX_components']).length > 0){
            setSelecting(false)
            setSelectingComponents(true)
        }
    }, [isAuthenticated])

    const handleYesSelect = () => {
        setSelecting(false)
        element.prop('reaction', 'Yes');
        element.prop('rx_spec', false)
        setSelectingComponents(true)
    }

    const handleNoSelect = () => {
        setSelecting(true)
        element.prop('reaction', 'No');
        element.prop('rx_spec', true)
        on_Screen_Menu_Focus(false)
        closeRXSpecPopup()
    }

    const handleClose = () => {
        on_Screen_Menu_Focus(false)
        closeRXSpecPopup()
    }

    const handleAddComponent = async (compoundName) => {
        // Error handle no name
        if (!compoundName) {
            toast({
                title: "Please enter a compound name.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        // Function to check if IUPAC name is in synonyms
        const checkCompatibleName = async (compoundName) => {
            try {
                const iupacName = await getIupacName(compoundName)
                
                const cleanCompoundName = compoundName.replace(/_/g, ' '); // Replace underscores with spaces for valid API call
                const response = await fetch(`https://pubchem.ncbi.nlm.nih.gov/rest/pug/compound/name/${encodeURIComponent(cleanCompoundName)}/synonyms/json`);
                
                if (response.ok) {
                    const data = await response.json();
                    const synonyms = data.InformationList.Information[0].Synonym;

                    // Check if the compoundName is in the list of synonyms
                    const isCompatible = synonyms.some(synonym => synonym.toLowerCase() === iupacName.toLowerCase());

                    return isCompatible; // Return true if found, false otherwise
                } else {
                    console.error('Failed to fetch synonyms');
                    return false;
                }
            } catch (error) {
                console.error('Error fetching synonyms:', error);
                return false; // In case of error, return false
            }
        };

        // Check if compoundName is not compatible and show error if so
        const isCompatible = checkIsCustomCompound(compoundName) || await checkCompatibleName(compoundName);
        if (!isCompatible) {
            toast({
                title: `Compound not found for ${compoundName}.`,
                description: 'Add as custom compound or try another compound.',
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        } else {
            // Get new component's ccid
            let ccid, compoundWeight, compoundFormula;
            try {
                let details = await fetchCompoundDetails(compoundName);
                compoundWeight = details.molecularWeight;
                compoundFormula = details.molecularFormula;
                if (!checkIsCustomCompound(compoundName)) {
                    ccid = details.ccid;
                    compoundName = details.iupacName;
                } else {
                    ccid = compoundFormula;
                }
            } catch (error) {
                toast({
                    title: "Failed to retrieve details.",
                    description: error.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
                return;
            }

            // Check if component's ccid is in the list of current components
            async function checkDuplicateCCID(compoundName, ccid, customCompounds, components) {
                let componentsCCIDs = new Set(); // Stores unique CCIDs
                
                // Check if compound's ccid is already in components
                if (componentsCCIDs.has(ccid)) {
                    toast({
                        title: "This compound has already been added.",
                        status: "warning",
                        duration: 3000,
                        isClosable: true,
                    });
                    return true; // Found a duplicate ccid for primary compound
                } else {
                    componentsCCIDs.add(ccid); // Add primary compound's ccid
                }
                
                // Check ccids for each component
                for (const component of components) {
                    let compccid;
                    let details = await fetchCompoundDetails(component.compName); // Fetch ccid for each component in components by name
                    compccid = details.ccid;
                
                    if (compccid) {
                        if (componentsCCIDs.has(compccid)) {
                            return true; // Found a duplicate ccid
                        } else {
                            componentsCCIDs.add(compccid);
                        }
                    }
                }
                
                return false; // No duplicate ccids
            }

            let duplicateCCID = await checkDuplicateCCID(compoundName, ccid, customCompounds, components);
            // Error handle duplicate CCID
            if (duplicateCCID) {
                toast({
                    title: "This compound has already been added.",
                    status: "warning",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                // Add new component with fetched details if no duplicate
                setComponents(prevComponents => [
                    ...prevComponents,
                    {
                        compName: compoundName,
                        mW: compoundMWeight
                    },
                ]);
                toast({
                    title: "Successfully added the compound.",
                    description: compoundName,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });

                // Clear input fields and reset state variables
                setCompoundInput(null); 
                setCompoundName(""); 
                setCompoundMWeight(""); 
                setCompoundMFormula("");
            }
        }
         
    };

    async function getIupacName(compoundName) {
        const cleanName = compoundName.replace(/_/g, ' '); // Replace underscores with spaces
        const url = `https://pubchem.ncbi.nlm.nih.gov/rest/pug/compound/name/${encodeURIComponent(cleanName)}/json`;
    
        const response = await fetch(url);
                const data = await response.json();
        
                const compound = data.PC_Compounds[0]; // Get the first compound
                const iupac = compound.props.find(
                    (prop) => prop.urn.label === 'IUPAC Name' && prop.urn.name === 'Allowed'
                );
        
                return iupac ? iupac.value.sval : compoundName;
    };

    const removeComponent = async (compToRemove) => {
        // Fetches CCID by name for the component to remove
        let detailsToRemove = await fetchCompoundDetails(compToRemove);
        if(detailsToRemove == null) {
            toast({
                title: "Please wait",
                description: "Components are still loading.",
                status: "warning",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        let ccidToRemove = detailsToRemove.ccid;
    
        // Add CCIDs to version of components
        let componentsWithCCID = await Promise.all(components.map(async (component) => {
            let details = await fetchCompoundDetails(component.compName); // fetch
            return { ...component, ccid: details.ccid }; // add ccid
        }));
    
        // Filter out component with the matching ccid
        const updatedComponentsWithCCID = componentsWithCCID.filter(component => component.ccid !== ccidToRemove);
        const removedComponentsWithCCID = componentsWithCCID.filter(component => component.ccid === ccidToRemove);
        
        // Format back to no-CCIDs in components
        let removedComponents = removedComponentsWithCCID.map(({ ccid, ...rest }) => rest);
        const updatedComponents = updatedComponentsWithCCID.map(({ ccid, ...rest }) => rest);

        let RX_components = element.attributes['RX_components']
        removedComponents = removedComponents.filter((comp) => RX_components[comp.compName.replace(/\s/g, '_')])
        
        // Set components with removed component filtered out
        setComponentsToRemove(removedComponents);
        setComponents(updatedComponents);
    };

    const handleFinish = () => {
        if (!element.prop('components')) element.prop({ 'components': {} })
        let comps = element.prop('components');
        if (!element.prop('RX_components')) element.prop({ 'RX_components': {} })
        let RX_comps = element.prop('RX_components');

        for(let i = 0; i < componentsToRemove.length; i++){
            let comp = componentsToRemove[i].compName.replace(/\s/g, '_')
            if (comps[comp]) {
                delete element.attributes['components'][comp]
            }
            if (RX_comps[comp]) {
                delete element.attributes['RX_components'][comp]
            }

            updateLinks(element, comp)
        }

        for (let i = 0; i < components.length; i++) {
            let comp = components[i]
            let compNameWithUnderscores = comp.compName.replace(/\s/g, '_')

            if (!comps[compNameWithUnderscores]) {
                element.prop({ 'components': { [compNameWithUnderscores]: { 'M': comp.mW } } });
            }

            if (!RX_comps[compNameWithUnderscores]) {
                element.prop({ 'RX_components': { [compNameWithUnderscores]: { 'M': comp.mW } } });
            }
        }

        if (Object.keys(element.attributes['RX_components']).length == 0) {
            element.prop('rx_spec', true)
            element.prop('reaction', 'No')
        } else {
            element.prop('rx_spec', false)
            element.prop('reaction', 'Yes')
        }

        updateLinks(element)

        on_Screen_Menu_Focus(false)
        closeRXSpecPopup()
    }

    const fetchCompoundDropdown = async (inputValue) => {
        // Generates autocomplete dropdown menu with API call and customCompound updater to retrieve data
        // Continually throws error until successfully retrieves compound
        let customComps = customCompounds;
        if(updateCustomCompoundsState){
            customComps = await updateCustomCompounds()
            setCustomCompounds(customComps)
            setUpdateCustomCompoundsState(false)
        }
        let data = {};
        await FlowireAPI.get('https://pubchem.ncbi.nlm.nih.gov/rest/pug/compound/name/' + encodeURIComponent(inputValue) + '/json', () => {}).then((response) => {
        if (response && response.PC_Compounds) {
            data[inputValue] = ""
        }});        

        Object.assign(data, customComps)
        const options = Object.keys(data).map(compoundName => ({
            value: compoundName.toLowerCase(),
            label: compoundName
        }));
        let filteredData
        if(inputValue != "")
            filteredData = options.filter((item) =>
                item.value.toLowerCase().includes(inputValue.toLowerCase())
            );
        else filteredData = options
        return filteredData        
    }

    const promiseOptions = inputValue => new Promise(resolve => {
        fetchCompoundDropdown(inputValue).then(data => {
            resolve(data);
        }).catch(error => {
            console.error('Error fetching data:', error);
            resolve([]);
        });
    });

    const getCompoundsList = inputValue => new Promise(resolve => {
        const getOps = async (inputValue) => {
            let data = await updateCustomCompounds()

            const options = Object.keys(data).map(compoundName => ({
                value: compoundName.toLowerCase(),
                label: compoundName
            }));
            let filteredData
            if(inputValue != "")
                filteredData = options.filter((item) =>
                    item.value.toLowerCase().includes(inputValue.toLowerCase())
                );
            else filteredData = options

            return filteredData
        }

        getOps(inputValue).then(data => {
            resolve(data);
        }).catch(error => {
            console.error('Error fetching data:', error);
            resolve([]);
        });
    })


    const handleChange = async (newValue) => {
        // Checks isCustomCompound, retrieves and sets name, mW, mF, ccid.
        setCompoundInput(newValue);
        if (newValue) {
            const compoundName = newValue.label;
            setCompoundName(compoundName);
            let isCustomCompound = checkIsCustomCompound(compoundName);
            let details;
            if (isCustomCompound) {
                let [molecularFormula, molecularWeight, iupacName] = customCompounds[compoundName];
                setCompoundMWeight(molecularWeight);
                setCompoundMFormula(molecularFormula);
                setComponentCCID(molecularFormula);
                setCompoundName(iupacName)
            } else {
                details = await fetchCompoundDetails(compoundName);
                let {ccid, molecularFormula, molecularWeight, iupacName} = details;
                setCompoundMWeight(molecularWeight);
                setCompoundMFormula(molecularFormula);
                setCompoundName(iupacName)
                setComponentCCID(ccid);
            }
        } else {
            setCompoundName("");
            setCompoundMWeight("");
            setCompoundMFormula("");
            setComponentCCID("");
        }
    };

    const handleAddCustomCompound = async () => {
        if(compoundName == "" || compoundMWeight == "" || compoundMFormula == ""){
            toast({
                title: "Compound name or molecular weight was inccorectly provided.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        const has_compoundmFormula = Object.entries(customCompounds).some(([key, value]) => value[0] === compoundMFormula);

        const checkDuplicateName = (compoundName, customCompounds) => {
            // Check if compound's name is already in components
            if (compoundName in customCompounds || has_compoundmFormula) {
                return true; // Found a duplicate name for primary compound
            }
            return false; // No duplicate names -> proceed to add
        }

        let duplicateName = checkDuplicateName(compoundName, customCompounds);

        // No duplicate names -> proceed to add
        if (!duplicateName) {
            await FlowireAPI.authPost('/api/addcompound', {
                "compound_name": compoundName,
                "compound_mweight": compoundMWeight,
                "compound_mformula": compoundMFormula
            }, getIdTokenClaims, isAuthenticated).then((data) => {
                if(data){
                    toast({
                        title: "Successfully added compound " + compoundName,
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    });
                    setCompoundName("")
                    setCompoundMWeight("")
                    setCompoundMFormula("")
                    setAddingCompound(false)
                    setUpdateCustomCompoundsState(true)
                } else {
                    toast({
                        title: "Failed to add compound " + compoundName,
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    });
                }
            })
        } else {
            toast({
                title: "Please enter a different name.",
                description: "Compound with name or molecular formula already exists",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
    }

    const fetchCompoundDetails = async (compoundName) => {
        let isCustomCompound = checkIsCustomCompound(compoundName);
        if (!isCustomCompound) {
            try {
                const cleanCompoundName = compoundName.replace(/_/g, ' '); // Ensure valid API call by removing underscore in 2+-part names
                const response = await FlowireAPI.get(`https://pubchem.ncbi.nlm.nih.gov/rest/pug/compound/name/${encodeURIComponent(cleanCompoundName)}/json`, () => {});
                if (response && response.PC_Compounds && response.PC_Compounds.length > 0) {
                    const compound = response.PC_Compounds[0];
                    const ccid = compound.id.id.cid;
                    const molecularFormula = compound.props.find(prop => prop.urn.label === 'Molecular Formula').value.sval;
                    const molecularWeight = compound.props.find(prop => prop.urn.label === 'Molecular Weight').value.sval;
                    const iupacNameProp = compound.props.find(prop => prop.urn.label === 'IUPAC Name' && prop.urn.name === 'Allowed');
                    const iupacName = iupacNameProp ? iupacNameProp.value.sval : cleanCompoundName; 

                    return { ccid, molecularFormula, molecularWeight, iupacName};
                } else {
                    console.error(`Compound not found for ${compoundName}.`);
                    return null;
                }
            } catch (error) {
                console.error(`Error fetching details for ${compoundName}:`, error);
                toast({
                    title: `Compound not found for ${compoundName}.`,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
                return null;
            }
        } else {
            const [molecularFormula, molecularWeight] = customCompounds[compoundName];
            const ccid = molecularFormula;
            return { ccid, molecularFormula, molecularWeight, compoundName};
        }
    };

    const checkIsCustomCompound = (compoundName) => {
        let isCustomCompound;
        isCustomCompound = customCompounds.hasOwnProperty(compoundName);
        return isCustomCompound;
    }

    const handleCancel = () => {
        setCompoundName("")
        setCompoundMWeight("")
        setCompoundMFormula("")
        setCompoundInput(null)
        setAddingCompound(false)
    }

    const handleAddCustomCompoundClick = () => {
        setAddingCompound(true)
        setCompoundName("")
        setCompoundMWeight("")
        setCompoundMFormula("")
        setCompoundToDelete("")
        setIsAddCompoundDisabled(true)
        setIsDeleteCompoundDisabled(true)
    }

    const handleClickAddComponent = () => {
        // Check that user has entered a component name
        if (compoundInput?.label) {
            handleAddComponent(compoundInput.label);
        } else {
        // Handle no-name error case
            toast({
                title: "Please enter a compound name.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
    };

    const updateCustomCompounds = async () => {
        return await FlowireAPI.authGet('/api/getcompounds', getIdTokenClaims, isAuthenticated, (e) => {
            return {}
        }).then((data) => {
            if (data) {
                return data.compounds
            } else {
                return {}
            }
        })
    }

    const handleDeleteCompound = () => {
        FlowireAPI.authPost('/api/deletecompound', {
            "compound_name": compoundToDelete.label
        }, getIdTokenClaims, isAuthenticated).then((response) => {
            if (response) {
                toast({
                    title: "Successfully deleted compound " + compoundToDelete.label,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                setCompoundToDelete("")
                setUpdateCustomCompoundsState(true)
            } else {
                toast({
                    title: "Error deleting compound " + compoundToDelete.label,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        })

        setAddingCompound(false)
    }

    useEffect(() => {
        setIsAddCompoundDisabled(compoundName === "" || compoundMWeight === "" || compoundMFormula === "");
        setIsDeleteCompoundListDisabled(compoundName !== "" || compoundMWeight !== "" || compoundMFormula !== "");
      }, [compoundName, compoundMWeight, compoundMFormula]);

    return (
        <Card id="RXSpecWizard"
        borderRadius="10px" boxShadow="lg" bgColor="white" minWidth="300px"
        minHeight="200px" position={'absolute'} top="50%" transform="translateY(-50%)">
            {!addingCompound && (
                <>
                    {selecting && (
                        <>
                        <Flex
                        borderBottom='1px solid #eee'
                        justifyContent="space-between" alignItems="center"
                        pt={2} pb={2} pl={4} pr={3}
                        >
                        
                        
                            <Heading size="sm" color="black">Vessel Reaction</Heading>
                            </Flex>
                            <Flex mt="10" alignContent="center" justifyContent={"space-evenly"}>
                            <Button size="sm"  width="33%" colorScheme="blue" onClick={handleNoSelect}>No</Button>
                            <Button size="sm" width="33%" colorScheme="blue" onClick={handleYesSelect}>Yes</Button>
                            </Flex>
                        </>
                    )}
                    {selectingComponents && (
                        <>
                        {type == "Feed" ? (
                            <Flex
                            borderBottom='1px solid #eee'
                        justifyContent="space-between" alignItems="center"
                        pt={2} pb={2} pl={4} pr={3}
                            >
                            
                            <Heading size="sm" color="black">Add Components</Heading>
                            </Flex>
                        ) : (
                            <Flex
                            borderBottom='1px solid #eee'
                        justifyContent="space-between" alignItems="center"
                        pt={2} pb={2} pl={4} pr={3}
                            > 
                            <Heading size="sm" color="black">Add Reaction Products</Heading>
                            </Flex>
                        )}
                        <Box overflowY="scroll" maxHeight="300px">
                        {components.map((item, index) => (
                        <Flex key={element.id + item.compName}
                        justifyContent="space-between" alignItems="center"
                        pt={1} pb={1} pl={2} pr={2}>
                            <Input textAlign="center" readOnly id={element.id+item.compName+"Name_Input"} size="sm" value={item.compName}></Input>
                            <Input textAlign="center" readOnly id={element.id+item.compName+"M_Input"} size="sm" value={item.mW + "(g/Mol)"}></Input>
                            <Button color="red" bgColor={"white"} _hover={{bgColor: "lightgrey"}} size="sm" id={element.id+'Remove_Button' + item.compName} onClick={() => removeComponent(item.compName)}> X </Button>
                        </Flex>
                        ))}
                        </Box>
                        <div className="component-addition-table" style={{overflow: "visible"}}>
                            <AsyncSelect isClearable defaultOptions loadOptions={promiseOptions} onChange={handleChange} value={compoundInput}/>
                            <Input className="stream-property-input" size="md" fontSize={"small"} id={element.id+"New_M_Input"} value={compoundMWeight} placeholder="M (g/mol)" readOnly></Input>
                            <Button className="stream-property-button" colorScheme="blue" variant="outline" id={element.id + 'Component_Add_Button'} onClick={handleClickAddComponent}>Add</Button>
                        </div>
                        
                        <Button className="stream-property-button" size="sm" width="50%" mt="1" mb="1" colorScheme='blue' ml="5" variant="link" alignSelf={"left"} id={element.id + 'Component_Add_Button'} onClick={handleAddCustomCompoundClick} style={{float:"left"}}>Add Custom Compound</Button>
                        <Flex alignContent={"center"} mt="1" ml="5" mb="2">
                        <Button className="stream-property-button" size="sm" mr="1" width="20%" colorScheme='red' alignSelf={"center"} id={element.id + 'Component_Add_Button'} onClick={() => {
                            if(components.length > 0 || componentsToRemove.length > 0) handleClose()
                            else {
                                element.prop('rx_spec', true)
                                element.prop('reaction', 'No')
                            }

                            handleClose()
                        }} style={{float:"right"}}>Cancel</Button>
                        {(components.length > 0 || componentsToRemove.length > 0) && (<Button className="stream-property-button" mt="1" mb="1" width="20%" colorScheme='blue' size="sm" alignSelf={"center"} id="FinishAddingComponentsButton" onClick={handleFinish}>Done</Button>)}
                        </Flex>
                        </>
                    )}
                </>
            )}

            {addingCompound && (
                <>
                    <Flex
                        borderBottom='1px solid #eee'
                        justifyContent="space-between" alignItems="center"
                        pt={0} pb={2} pl={2} pr={2}
                        >
                        
                        <Heading size="sm" color="black">Add Compound</Heading>
                    </Flex>
                    <Flex className="component-addition-table" style={{overflow: "visible"}}>
                        <Input fontSize={"smaller"} className="stream-property-input" id={element.id+"New_Compound_Input"} placeholder="Compound Name" value={compoundName} onChange={(e) => setCompoundName(e.target.value)}></Input>
                        <Input fontSize={"smaller"}className="stream-property-input" id={element.id+"New_M_Input"} placeholder="M (g/mol)" value={compoundMWeight} onChange={(e) => setCompoundMWeight(e.target.value)}></Input>
                        <Input fontSize={"smaller"} className="stream-property-input" id={element.id+"New_MF_Input"} placeholder="Molecular Formula" value={compoundMFormula} onChange={(e) => setCompoundMFormula(e.target.value)}></Input>
                    </Flex>
                    <Box ml="20px" mr="20px">
                    <AsyncSelect isDisabled={isDeleteCompoundListDisabled} isClearable defaultOptions loadOptions={getCompoundsList} onChange={e => {setCompoundToDelete(e); setIsDeleteCompoundDisabled(e === null)}} value={compoundToDelete}/>
                    </Box>
                    <Flex className="component-addition-table" style={{overflow: "visible"}} alignContent={"center"}>
                        <Button colorScheme="blue" id={element.id + 'Component_Add_Button'} onClick={handleCancel}>Cancel</Button>
                        <Button colorScheme="blue" id={element.id + 'Component_Add_Button'} isDisabled={isAddCompoundDisabled} onClick={handleAddCustomCompound}>Add</Button>
                        <Button colorScheme="red" id={element.id + 'Component_Delete_Button'} isDisabled={isDeleteCompoundDisabled} onClick={handleDeleteCompound}>Delete</Button>
                    </Flex>
                </>
            )}
        </Card>
    )
}

export default RXSpecWizard