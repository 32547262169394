import {Button, Flex, Image, Link} from "@chakra-ui/react";
import {useLocation, useNavigate} from "react-router-dom";

export function SidebarLink({link, label, ...rest}) {
    const navigator = useNavigate();
    const location = useLocation();

    return <Button {...rest}
                   fontSize={16}
                   size="lg" marginY="3" variant="link"
                   color={location.pathname === (link ?? '/') ? 'blue.500' : ''}
                   fontWeight={location.pathname === (link ?? '/') ? '600' : '400'}
                   onClick={() => navigator(link ?? '/')}>{label}</Button>
}

export function Sidebar(props) {
    return <Flex
        bg="white"
        width="200px"
        flexBasis="200px"
        padding="8"
        direction="column"
        alignItems="start"
        height="100vh"
        position="sticky"
        top="0"
        boxSizing="content-box"
    >
        <Link href="/"
              size="md" marginBottom="6" fontSize={18} fontWeight={600}
              textColor="#3EC1D3" display="flex" flexDirection="row"
              alignItems="centre" width="100%">
            <Image src="/logo.png" width={6} height={6} mr={2}/>
            Flowire
        </Link>
        {props.children}
    </Flex>;
}
