import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import {ResponsivePie} from '@nivo/pie';
import {PopupLayout} from "../../../../PopupLayout/PopupLayout";

const CapexGraphPopup = (props, ref) => {
    const [data, setData] = useState([]);
    const [update, setState] = useState(false);
    const forceUpdate = React.useCallback(() => setState(s => !s), []);

    useImperativeHandle(ref, () => ({
        forceUpdate: () => {
            forceUpdate
        }
    }));

    useEffect(() => {
        if (props.isOpen) {
            setGraphSettings();
        }
    }, [props.isOpen, update]);

    const setGraphSettings = () => {
        //console.log(props)
        let results = props.getEconArrays[0](props.getEconArrays[1], props.getEconArrays[2], props.getEconArrays[3])
        let equipArray1 = results[0]
        const capexArray = equipArray1[1];
        const categoriesArray = equipArray1[0];

        const newData = [];
        // Create data array for the pie chart
        for (let i = 0; i < capexArray.length; i++) {
            const item = {
                id: categoriesArray[i],
                label: categoriesArray[i],
                value: capexArray[i]
            };
            newData.push(item);
        }
        setData(newData);
    }

    return (
        <>
            {props.isOpen && (
                <PopupLayout componentRef={ref} pageX={window.innerWidth/2} pageY={window.innerHeight/2} minWidth="500px" minHeight="500px" title="Capex Graph"
                             closeFunction={props.onClose} overflow={false}>
                    <div style={{width: '480px', height: '400px'}}>
                        <ResponsivePie
                            data={data}
                            margin={{top: 40, right: 100, bottom: 130, left: 150}}
                            innerRadius={0.5}
                            padAngle={0.7}
                            cornerRadius={3}
                            colors={{scheme: 'nivo'}} // use nivo default colors
                            borderWidth={1}
                            borderColor={{from: 'color', modifiers: [['darker', 0.2]]}}
                            radialLabelsSkipAngle={10}
                            radialLabelsTextXOffset={6}
                            legends={[
                                {
                                    anchor: 'bottom',
                                    direction: 'row',
                                    justify: false,
                                    translateX: 0,
                                    translateY: 56,
                                    itemsSpacing: 0,
                                    itemWidth: 100,
                                    itemHeight: 18,
                                    itemDirection: 'left-to-right',
                                    itemTextColor: '#999',
                                    itemOpacity: 1,
                                    symbolSize: 18,
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: '#000'
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />
                    </div>
                </PopupLayout>
            )}
        </>
    );
}

export default React.forwardRef((props, ref) => CapexGraphPopup(props, ref));
