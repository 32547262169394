import {useEffect, useState} from 'react';
import {Tooltip, Box, Button, Checkbox, Divider, Flex, Heading, Input, InputGroup, InputLeftElement, Select, Text, VStack, useDisclosure, useToast} from '@chakra-ui/react';
import {useAuth0} from '@auth0/auth0-react';
import {useNavigate} from 'react-router-dom';
import CreateGroupPopup from './components/CreateGroupPopup/CreateGroupPopup';
import DeleteGroupPopup from './components/DeleteGroupPopup/DeleteGroupPopup';
import GraphItem from './components/GraphItem/GraphItem';
import DeleteConfirmPopup from './components/DeleteConfirmPopup/DeleteConfirmPopup';
import FlowireAPI from '../../flowireapi/FlowireAPI';
import {FaPlus} from "react-icons/fa6";
import {createPortal} from "react-dom";
import {FaSearch, FaTrash} from "react-icons/fa";
import { MultiSelect } from 'chakra-multiselect';

const Dashboard = () => {
    const {isAuthenticated, logout, user, getIdTokenClaims} = useAuth0();
    const navigate = useNavigate();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure();
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
    const toast = useToast();
    const [groups, setGroups] = useState([{ name: "All", uuid: "all" }]);

    // Callback function when a group is created
    const handleGroupCreated = (newGroupName) => {
        setGroups([...groups, { name: newGroupName, uuid: newGroupName }]);
        getGroups(); // Refresh groups from the API
    };

    const handleGroupDeleted = (deletedGroupId) => {
        setGroups(groups.filter(group => group.uuid !== deletedGroupId));
    };

    const [domReady, setDomReady] = useState(false);

    const handleCreateButtonClick = () => {
        navigate(`/d/new`);
    }

    const [jsonData, setJSONData] = useState([]);

    const currencies = {
        USD: {
            symbol: "$",
            name: 'USD',
            rateToUSD: 1,
        },
        AUD: {
            symbol: "A$",
            name: 'AUD',
            rateToUSD: 0.73,
        },
    }
    const [currency, setCurrency] = useState("USD")

    const convertCurrency = (value) => {
        return (value * currencies[currency].rateToUSD)
    }

    const timezones = {
        "AEST": "Australia/Brisbane",
        "GMT": "GMT",
        "EST": "America/New_York",
    };
    const [timezone, setTimezone] = useState("AEST")

    const loadSimulationData = async () => {
        FlowireAPI
            .authGet('/api/load', getIdTokenClaims, isAuthenticated)
            .then((data) => data ? setJSONData(data) : setJSONData([]))
    }

    useEffect(() => {
        loadSimulationData()
    }, [isAuthenticated, getIdTokenClaims]);
    const cardClicked = (uuid) => {
        navigate("/d/" + uuid)
    }

    const getGroups = async () => {
        FlowireAPI
        .authGet('/api/getgroups', getIdTokenClaims, isAuthenticated)
        .then((data) => {
            setGroups([{ name: "All", uuid: "all" }, ...data.groups]); // Add "All" group here if needed
        })
        .catch((error) => {
            console.error('Error fetching groups:', error);
        });
    }

    useEffect(() => {
        if (isAuthenticated) {
            getGroups()
        }
    }, [isAuthenticated, getIdTokenClaims]);

    const [searchTerm, setSearchTerm] = useState("")

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value)
    }

    const formatOptions = {
        // weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        // hour: 'numeric', // instead of '2-digit'
        // minute: '2-digit'
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        //timeZone: timezones[timezone]
        const formatter = new Intl.DateTimeFormat('en-US', {...formatOptions});
        return formatter.format(date); // + " " + timezone
    };

    const handleDelete = async () => {
        onClose();
        const id = deleteItem.uuid;

        setJSONData((prevData) => prevData.filter((simulation) => simulation.uuid !== id));

        await FlowireAPI.authPost('/api/delete', {uuid: id}, getIdTokenClaims, isAuthenticated)
            .then((response) => {
                //console.log(response);
                toast({
                    title: `Successfully deleted simulation ${deleteItem.name}`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                console.log(error);
            });

        loadSimulationData();
    };

    const [deleteItem, setDeleteItem] = useState(null)

    const handleDeleteOpen = (item) => {
        setDeleteItem(item);
        onOpen();
    };

    useEffect(() => {
        setDomReady(true);
    }, []);

    const [groupName, setGroupName] = useState("");

    const handleGroupChange = (e) => {
        setGroupName(e.target.value);
    };

    const [selectedGroups, setSelectedGroups] = useState([
        { id: "all", label: "All", value: "all" } // "All" option as an object
    ]);

    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {

        // Check if "All" is selected
        const isAllSelected = selectedGroups.some(group => group.id === "all");

        let filtered;
        if (isAllSelected) {
            // If "All" is selected, show all items
            filtered = jsonData.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
        } else {
            // Filter based on selected groups
            filtered = jsonData.filter((item) =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            selectedGroups.some(group => group.id === item.group_uuid) // Check if the item's group is in the selected groups
            );
        }
        setFilteredData(filtered); // Set the filtered data to state
    }, [jsonData, searchTerm, selectedGroups]); // Ensure dependencies are correct
    
        
    return (
        <Flex direction="column">

            {
                domReady && createPortal(
                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <FaSearch size={16}/>
                        </InputLeftElement>
                        <Input placeholder="Search Projects" width="500px" onChange={handleSearchChange}/>
                    </InputGroup>,
                    document.getElementById('navbarContent')
                )
            }

            <Flex direction="row" alignItems="center" justifyContent="space-between" ml="8" mr="8">
                <Heading size="md">
                    My Projects
                </Heading>
                <Button leftIcon={<FaPlus/>} colorScheme="blue" onClick={handleCreateButtonClick}>
                    New project
                </Button>
            </Flex>
            <Flex mt="3" mr="8" ml="8">
            <Divider/>
            </Flex>
            <Heading ml="8" mr="8" mt="5" mb="3" size="sm">Groups</Heading>
            <Flex ml="8" mr="8" mt="3" width="60%">
            <MultiSelect 
                options={groups.map(group => { return {id: group.uuid, label: group.name, value: group.uuid} })}
                value={selectedGroups}
                onChange={setSelectedGroups}
            />
            <Tooltip label="Create a new group" aria-label="Create a new group">
            <Button colorScheme='blue' ml="3" onClick={onCreateOpen}>
                <FaPlus/></Button></Tooltip>
            <CreateGroupPopup 
                isOpen={isCreateOpen} 
                onClose={onCreateClose} 
                onGroupCreated={handleGroupCreated}
                existingGroups={groups} // Pass existing groups here 
            />
            <Tooltip label="Delete a group" aria-label="Delete a group">
            <Button ml="3" colorScheme='red' onClick={onDeleteOpen}><FaTrash/></Button></Tooltip>
            </Flex>
            <DeleteGroupPopup 
                isOpen={isDeleteOpen} 
                onClose={onDeleteClose} 
                groups={groups}
                onGroupDeleted={handleGroupDeleted}
            />
            <Flex mt="3" mb="3" mr="8" ml="8">
            <Divider/>
            </Flex>
            <Flex flexWrap="wrap" m="4">
                <DeleteConfirmPopup onClose={onClose} onDelete={handleDelete} isOpen={isOpen}
                                    item={deleteItem}/>
                {filteredData.map((item) => (
                    <GraphItem
                        key={item.uuid}
                        item={item}
                        onDelete={() => handleDeleteOpen(item)}
                        formatDate={formatDate}
                        clicked={cardClicked}
                        loadSimulationData={loadSimulationData}
                        simulationGroups={groups}/>
                ))}
            </Flex>
        </Flex>
    );

};

export default Dashboard;