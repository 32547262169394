import { useRef, useState, useEffect } from 'react';
import { setTheme } from '@clientio/rappid';
import { ToolbarService } from './services/toolbar-service';
import { KeyboardService } from './services/keyboard-service';
import RappidService from './services/kitchen-sink-service';
import { ThemePicker } from './views/theme-picker';
import { SimulatorService } from './services/simulation-service';
import InfoPopup from './components/InfoPopup/InfoPopup';
import FinancialPopup from './components/FinancialPopup/FinancialPopup';
import OverallEconomicsPopup from './components/OverallEconomicsPopup/OverallEconomicsPopup';
import './css/style.css';
import './css/theme-picker.css';
import CapexGraphPopup from './components/GraphPopup/components/CapexGraphPopup';
import OpexGraphPopUp from './components/GraphPopup/components/OpexGraphPopUp';
import NPVGraphPopup from './components/GraphPopup/components/NPVGraphPopup';
import StoichWizard from './components/StoichWizardPopup/StoichWizardPopup';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate} from 'react-router-dom';
import { Box, Button, CloseButton, Flex, Heading, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Select, useDisclosure } from '@chakra-ui/react';
import ConfirmPopup from '../ConfirmPopup/ConfirmPopup';
import {createPortal} from "react-dom";
import {SimulatorToolbar} from "./SimulatorToolbar";
import MultivariateAnalysis from './components/MultivariateAnalysis/MultivariateAnalysis';
import FlowireAPI from '../../flowireapi/FlowireAPI';
import { useToast } from '@chakra-ui/react';

const Diagram = ({orgSectors, isUpdated, hasUpdated, setGraphData, graphName, graphData, uuid, useDesigner, graphGroup, setGraphGroup, isTemplate}) => {
  const appRef = useRef(null);
  const themePickerRef = useRef(null);
  const [MAGraphData, setMAGraphData] = useState(null);
  const [MAGraphLoading, setMAGraphLoading] = useState(false);
  const [MADropdownData, setMADropdownData] = useState([]);
  const [isMAOpen, setIsMAOpen] = useState(false);
  const [MAState, setMAState] = useState("button");
  const [displayOverallFinancialPopup, setDisplayOverallFinancialPopup] = useState(false);
  const [overallFinancialPopupGraph, setOverallFinancialPopupGraph] = useState(null);
  const [capexGraphIsOpen, setCapexGraphIsOpen] = useState(false);
  const [npvGraphIsOpen, setNpvGraphIsOpen] = useState(false);
  const [opexGraphIsOpen, setOpexGraphIsOpen] = useState(false);
  const [tempClosedPopups, setTempClosedPopups] = useState({
    capexGraphPopup: false,
    npvGraphPopup: false,
    opexGraphPopUp: false,
  });
  
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [selectedStoichElement, setSelectedStoichElement] = useState(null);
  const infoPopupRef = useRef(null);
  const financialPopupRef = useRef(null);
  const capexGraphPopupRef = useRef(null);
  const opexGraphPopupRef = useRef(null);
  const npvGraphPopupRef = useRef(null);
  const overallFinancialPopupRef = useRef(null);
  const [getEconArrays, setGetEconArrays] = useState(null);
  const [rappid, setRappid] = useState(null);
  const [solved_Dictionary, setSolved_Dictionary] = useState(null);
  const navigate = useNavigate();
  const [saveNamePopup, setSaveNamePopup] = useState(false);
  const [saveNameBox, setSaveNameBox] = useState(graphName ? graphName : "");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const defaultMassConversionOptions = {
    "tpd": 1,
    "tph": 1/24,
    "kg/hr": 1000/24,
    "kg/s": 1000/24/3600,
  }

  const [selectedMassConversion, setSelectedMassConversion] = useState(0);

  const defaultFractionConversionOptions = {
    "fraction": 1,
    "%":100,
    "ppm": 1000000,
  }

  const [selectedFractionConversion, setSelectedFractionConversion] = useState(0);

  const MASimulate = (unit, stream, returnType, unitMin, unitMax, streamMin, streamMax) => {
    rappid.multivariateAnalysis(unit,stream,returnType, unitMin, unitMax, streamMin, streamMax);
  }

  useEffect(() => {
    if(rappid)
    getMADropdownData(rappid.graph);
  }, [rappid]);

  const getMADropdownData = (graph) => {
    const cells = graph.getCells();
    const links = graph.getLinks();

    try {
      setMADropdownData({
        Units: cells
        .filter(cell => cell.attributes.isUnit === "yes")
        .map(cell => {
          if (cell.attributes.unitType === "Feed") {
            if (cell.attributes.equipment_Economics == null) return [];
            const connectedLinks = graph.getConnectedLinks(cell);
            return cell.attributes.unitType + " - " + connectedLinks[0].attributes.CustomName;
          } else if (cell.attributes.unitType === "Product") {
            if (cell.attributes.equipment_Economics == null) return [];
            const connectedLinks = graph.getConnectedLinks(cell);
            return cell.attributes.unitType + " - " + connectedLinks[0].attributes.CustomName;
          } else {
            if (cell.attributes.equipment_Economics == null) return [];
            const equipmentEconomicsKeys = Object.keys(cell.attributes.equipment_Economics);

            if (cell.attributes.attrs.shapename.innerHTML.replace(/<\/?[^>]+>/g, "") == "") return [];

            return equipmentEconomicsKeys.map(key => `${cell.attributes.attrs.shapename.innerHTML.replace(/<\/?[^>]+>/g, "")} - ${key}`);
          }
        })
        .flat()
        .sort((a, b) => {
          if (a.startsWith("Feed")) return -1;
          if (a.startsWith("Product") && !b.startsWith("Feed")) return -1;
          return a.localeCompare(b);
        }),
        Streams: links.map(link => {
          const componentsKeys = Object.keys(link.attributes.specified_Stream_Properties.massFlow);
          let result =  componentsKeys.map(key => {
            if(link.attributes.specified_Stream_Properties.massFlow[key] == null) return [];
            return "Stream - " + link.attributes.CustomName + " - " + key;
          }).flat();

          if(link.attributes.specified_Stream_Properties.totalFlow != null) {
            result.push("Stream - " + link.attributes.CustomName + " - Total Flow");
          }

          return result;
      }).flat()
      });
    } catch (e) {
      toast({
        title: "Error",
        description: "Error getting dropdown data",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }

  useEffect(() => {
    if(rappid)
    rappid.orgSectors = orgSectors;
  }, [orgSectors, rappid]);

  const handleMAStateChange = (state) => {
    setMAState(state);
  };

  const handleConfirm = () => {
    onClose();
    navigate("/")
  };

  const { isAuthenticated, getIdTokenClaims } = useAuth0();

  const openInfoStreamPropertyPopup = (graph, linkId, pageX, pageY) => {
    if (infoPopupRef.current) {
      infoPopupRef.current.addInfoStreamPropertyPopup(getMADropdownData, graph, linkId, pageX, pageY);
    }
  };

  const toggleSaveNamePopup = () => {
    setSaveNamePopup(!saveNamePopup)
  }

  let openFinancialStreamPropertyPopup = (graph, modelId, econVars, pageX, pageY, orgSectors) => {
    if (financialPopupRef.current) {
      financialPopupRef.current.addFinancialStreamPropertyPopup(getMADropdownData, graph, modelId, econVars, pageX, pageY, orgSectors);
    }
  };

  const closeAllPopups = () => {
    if(!infoPopupRef) return;
    if(!infoPopupRef.current) return;
    infoPopupRef.current.popups.forEach((popup) => {
      infoPopupRef.current.closeInfoStreamPropertyPopup(popup.id);
      if(window.localStorage.getItem(`Stream - ${popup.linkId.attributes.stream_Number}`)){
        window.localStorage.removeItem(`Stream - ${popup.linkId.attributes.stream_Number}`);
      }
    });

    financialPopupRef.current.popups.forEach((popup) => {
      financialPopupRef.current.closeFinancialStreamPropertyPopup(popup.id);
    });

    setCapexGraphIsOpen(false);
    setOpexGraphIsOpen(false);
    setNpvGraphIsOpen(false);
  };

  const tempCloseAllPopups = () => {
    infoPopupRef.current.popups.forEach((popup) => {
      infoPopupRef.current.hideInfoStreamPropertyPopup(popup.id);
    });

    financialPopupRef.current.popups.forEach((popup) => {
      financialPopupRef.current.hideFinancialStreamPropertyPopup(popup.id);
    });

    setTempClosedPopups({
      capexGraphPopup: capexGraphIsOpen,
      npvGraphPopup: npvGraphIsOpen,
      opexGraphPopUp: opexGraphIsOpen,
    });

    setCapexGraphIsOpen(false);
    setNpvGraphIsOpen(false);
    setOpexGraphIsOpen(false);
    updateGraphs();
  };

  const tempReopenAllPopups = () => {
    infoPopupRef.current.popups.forEach((popup) => {
      infoPopupRef.current.openInfoStreamPropertyPopup(popup.id);
    });

    financialPopupRef.current.popups.forEach((popup) => {
      financialPopupRef.current.openFinancialStreamPropertyPopup(popup.id);
    });

    setCapexGraphIsOpen(tempClosedPopups.capexGraphPopup);
    setNpvGraphIsOpen(tempClosedPopups.npvGraphPopup);
    setOpexGraphIsOpen(tempClosedPopups.opexGraphPopUp);
    updateGraphs();
  };

  const closeOverallFinancialStreamPropertyPopup = () => {
    setDisplayOverallFinancialPopup(false);
    toggleOverallFinancialStreamPropertyPopup(null, null, null);
  };

  const openCapexGraph = () => {
    setCapexGraphIsOpen(true);
  };

  const handleCapexGraphClose = () => {
    setCapexGraphIsOpen(false);
  };
  
  const openOpexGraph = () => {
    setOpexGraphIsOpen(true);
  };

  const handleOpexGraphClose = () => {
    setOpexGraphIsOpen(false);
  };

  const openNPVGraph = () => {
    setNpvGraphIsOpen(true);
  };

  const handleCloseNPVGraph = () => {
    setNpvGraphIsOpen(false);
  };

  const setGetEconArraysF = (func) => {
    setGetEconArrays(func);
  };

  const updateGraphs = () => {
    capexGraphPopupRef.current.forceUpdate();
    opexGraphPopupRef.current.forceUpdate();
  };

  const sim = () => {
    rappid.simulate();
  };

  const toggleOverallFinancialStreamPropertyPopup = (graph, econVars, solved_Dictionary) => {
    rappid.overallPopupOpen = !rappid.overallPopupOpen
    if (rappid.overallPopupOpen) {
      setIsMAOpen(true);
      setDisplayOverallFinancialPopup(true);
      setOverallFinancialPopupGraph(graph);
      setSolved_Dictionary(solved_Dictionary);
    } else {
      setIsMAOpen(false);
      handleMAStateChange("button");
      setDisplayOverallFinancialPopup(false);
    }
  };


  const reCalculate = () => {
    if (overallFinancialPopupRef.current) {
      overallFinancialPopupRef.current.doReq();
    }
  };

  const handleOpenWizard = (element) => {
    setSelectedStoichElement(element);
    setIsWizardOpen(true);
  };

  const handleResolve = () => {
    setSelectedStoichElement(null);
    setIsWizardOpen(false);
  };

  const handleSaveAs = (e,name) => {
    if(name == "") return;
    rappid.graphName = name
    rappid.saveGraph(selectedGroup)
    setGraphGroup(selectedGroup)
    toggleSaveNamePopup()
    if(rappid.confirmNavigate)
      navigate("/")
  }

  const handleSaveNameBoxChange = (e) => {
    const {value} = e.target
    setSaveNameBox(value)
  }

  const onSave = async () => {
    onClose()
    let dontNav = await rappid.saveGraph()
    if(dontNav)
      rappid.confirmNavigate = true
    else
      navigate("/")
  }

  useEffect(() => {
    if(rappid)
      rappid.isUpdated = () => isUpdated
  }, [isUpdated]);

  useEffect(() => {
    document.documentElement.style.overflow = 'hidden';
  return () => {
    document.documentElement.style.overflow = 'auto';
  };
  }, []);

  useEffect(() => {
    if(!isAuthenticated || !orgSectors) return
    setTheme('modern');

    // Create a new RappidService instance and set rappid using setRappid
    const service = new RappidService(
      appRef.current,
      new ToolbarService(),
      new KeyboardService(),
      new SimulatorService(),
      openInfoStreamPropertyPopup,
      openFinancialStreamPropertyPopup,
      tempCloseAllPopups,
      tempReopenAllPopups,
      closeAllPopups,
      handleOpenWizard,
      isAuthenticated,
      getIdTokenClaims,
      navigate,
      toggleSaveNamePopup,
      useDesigner,
      setGraphData,
      onOpen,
      hasUpdated,
      () => isUpdated,
      setMAGraphData,
      setMAGraphLoading,
      graphGroup,
      isTemplate
    );
    setRappid(service); // Set the rappid instance
    service.startRappid();

    const themePicker = new ThemePicker({
      el: themePickerRef.current,
      mainView: service,
    });
    themePicker.render();
    themePickerRef.current = themePicker;

    if(graphData.cells) {
      service.loadGraph(graphName, uuid, graphData, service.isUpdated())
    } else {
      if(uuid == "new") {
        service.uuid = uuid
        return
      }
      navigate("/error")
    }

    return () => {
      if (rappid) {
        rappid.stopRappid();
      }
      themePicker.remove();
    };
  }, [isAuthenticated, getIdTokenClaims, orgSectors]);
  if(rappid){
    rappid.toggleOverallFinancialStreamPropertyPopup = toggleOverallFinancialStreamPropertyPopup
  }

  const [groups, setGroups] = useState([])

  useEffect(() => {
    if (isAuthenticated) {
      const getGroups = async () => {
        FlowireAPI
                .authGet('/api/getgroups', getIdTokenClaims, isAuthenticated)
                .then((data) => setGroups(data.groups))
      }

      getGroups()
    }
  }, [isAuthenticated, getIdTokenClaims]);

  const [selectedGroup, setSelectedGroup] = useState(graphGroup ? graphGroup : "")

  const handleGroupChange = (e) => {
    setSelectedGroup(e.target.value)
  }

  return (
      <Box ref={appRef} className="Diagram">

      {createPortal(
          <SimulatorToolbar toggleSaveNamePopup={toggleSaveNamePopup}
                           paperScroller={rappid ? rappid.paperScroller : {}}
                           toolbarService={rappid ? rappid.toolbarService : {}}
                           commandManager={rappid ? rappid.commandManager : {}}
                           isTemplate={isTemplate}
          />,
          document.getElementById('navbarContent')
      )}

      {isWizardOpen && (
        <StoichWizard element={selectedStoichElement} resolve={handleResolve} />
      )}
      <div
        style={{
          pointerEvents: isWizardOpen ? 'none' : 'auto',
          opacity: isWizardOpen ? 0.2 : 1,
        }}
      >
        {rappid != null && (<>
        <div className="popups">
          <MultivariateAnalysis MAGraphData={MAGraphData} MASimulate={MASimulate} MADropdownData={MADropdownData} MAState={MAState} isMAOpen={isMAOpen} setMAState={(state) => handleMAStateChange(state)} MAGraphLoading={MAGraphLoading} setMAGraphLoading={setMAGraphLoading}/>
          <ConfirmPopup isOpen={isOpen} onClose={onClose} onConfirm={handleConfirm} onSave={onSave} />
          <div className='save-as'>
          {saveNamePopup && (
          <Modal
            width="300px"
            borderRadius="10px"
            boxShadow="lg"
            bgColor="white"
            isOpen={saveNamePopup}
            onClose={toggleSaveNamePopup}

          >
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <Flex
                bgColor="white"
                p={2}
                justifyContent="space-between"
                alignItems="center"
                borderBottom='1px solid #eee'
                mb="2"
              >
                  <Heading size="sm">Save As</Heading>
                  
                <CloseButton onClick={() => {
                  toggleSaveNamePopup()
                }}/>
              </Flex>

              <Flex justifyContent={"space-between"} mt={3} mb={3} mr={3} ml={3}>
                Select Group
                  <Select placeholder="Select group" width="50%" value={selectedGroup} onChange={handleGroupChange}>
                    {groups.map((group) => (
                      <option key={group.uuid} value={group.uuid}>{group.name}</option>
                    ))}
                  </Select>
              </Flex>
              <Flex justifyContent={"space-between"} mr={3} ml={3}>
                Enter New Name
                <Input  width="50%" type="text" value={saveNameBox} onChange={handleSaveNameBoxChange}/>
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Button  colorScheme="blue" size="sm"  onClick={(e) => handleSaveAs(e,saveNameBox)} isDisabled={saveNameBox == undefined || saveNameBox == ""}>Save</Button>
            </ModalFooter>
          </ModalContent>
          </Modal>
        )}
          </div>
          <div className="graph">
            <CapexGraphPopup
              ref={capexGraphPopupRef}
              getEconArrays={getEconArrays}
              onClose={handleCapexGraphClose}
              isOpen={capexGraphIsOpen}
            />
            <NPVGraphPopup
              ref={npvGraphPopupRef}
              econVars={rappid.econVars}
              onClose={handleCloseNPVGraph}
              isOpen={npvGraphIsOpen}
            />
            <OpexGraphPopUp
              ref={opexGraphPopupRef}
              getEconArrays={getEconArrays}
              onClose={handleOpexGraphClose}
              isOpen={opexGraphIsOpen}
            />
          </div>
          <div className="info-popup">
            <InfoPopup
              ref={infoPopupRef}
              simulateFunction={sim}
              selectedMassConversion={selectedMassConversion}
              setSelectedMassConversion={() => setSelectedMassConversion(
                (selectedMassConversion + 1 > Object.keys(defaultMassConversionOptions).length - 1)
                  ? 0
                  : selectedMassConversion + 1
              )}
              massConversionOptions={defaultMassConversionOptions}
              selectedFractionConversion = {selectedFractionConversion}
              setSelectedFractionConversion = {() => setSelectedFractionConversion(
                (selectedFractionConversion +1 > Object.keys(defaultFractionConversionOptions).length - 1)
                  ? 0
                  : selectedFractionConversion +1
              )}
              fractionConversionOptions = {defaultFractionConversionOptions}
              econVars={rappid.econVars}
            />
            <FinancialPopup isAuthenticated={isAuthenticated} getIdTokenClaims={getIdTokenClaims} ref={financialPopupRef} updateGraphs={updateGraphs} reCalculate={reCalculate} />
            {displayOverallFinancialPopup && (
              <OverallEconomicsPopup
                ref={overallFinancialPopupRef}
                calculateFeedEP={rappid.calculateFeedEP}
                isAuthenticated={isAuthenticated}
                getIdTokenClaims={getIdTokenClaims}
                setGetEconArraysF={setGetEconArraysF}
                openNPVGraph={openNPVGraph}
                openCapexGraph={openCapexGraph}
                openOpexGraph={openOpexGraph}
                solved_Dictionary={solved_Dictionary}
                closeFunction={closeOverallFinancialStreamPropertyPopup}
                graph={overallFinancialPopupGraph}
                econVars={rappid.econVars}
                simulateEconVars={rappid.simulateEconVars}
              />
            )}
          </div>
        </div></>)}
        <div className="toolbar-container" />

        <div className="app-body">
          <div className="paper-container" />
          <div id="loading-page" className="disrupter">
            <div className="loading" />
          </div>
          <div className="inspector-container" />
        </div>
      </div>
    </Box>
  );
}

export default Diagram;
