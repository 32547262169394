import { Box, Heading, Center, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const Error = () => {
    const navigate = useNavigate()
  return (
    <Center h="100vh">
      <Box textAlign="center">
        <Heading size="xl" mb={4}>
          Error loading simulation.
        </Heading>
        <Button colorScheme="blue" onClick={() => navigate("/")}>
          Go Home
        </Button>
      </Box>
    </Center>
  );
};

export default Error;
